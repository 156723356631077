import React, { Component } from "react";
import { connect } from "react-redux";

import { SelectItem } from "app/NativeComponents/snippets";

import {
  bulkUpdateLeads,
  setModal,
  updateModal,
  toggleModal,
  numberWithCommas
} from "app/NativeActions";
import { Copy, Wrapper } from "app/NativeComponents/common";
class DeleteButton extends Component {
  determineDescription = selected_leads => {
    const singleLead = selected_leads.length === 1;
    const containsSmartListLead = selected_leads.some(lead =>
      lead?.deal?.lists?.some(list => list?.list_type === "build_list")
    );

    if (singleLead && containsSmartListLead) {
      const listName =
        selected_leads[0].deal.list_titles.length > 0 ? (
          <>
            <Copy inline={true} style={{ fontWeight: "bold" }}>
              {
                selected_leads[0].deal.lists.find(
                  list => list.list_type == "build_list"
                ).title
              }
            </Copy>{" "}
            <Copy inline={true}>list. </Copy>
          </>
        ) : (
          <Copy inline={true}>a smart list.</Copy>
        );
      return (
        <Copy>
          <Copy inline={true}>This lead is a part of </Copy>
          {listName}{" "}
          <Copy inline={true}>
            When deleted, this lead will be added back on the next Smart List
            update. To avoid this, please move the lead to Trash.
          </Copy>
        </Copy>
      );
    } else if (containsSmartListLead) {
      return "Some of the leads you are deleting are part of a Smart List and will be added back on the next Smart List update. To avoid this, please move the leads to Trash.";
    } else {
      return 'Are you sure you want to permanently delete these leads? This will completely remove them from your account. If you don\'t want to do this, try the "In Trash" status.  You cannot undo this.';
    }
  };

  render() {
    const { button_search, title, selected_all, selected_leads } = this.props;
    const { token, user, filtered_count } = this.props;
    const deleteConfirmationDescription =
      this.determineDescription(selected_leads);

    if (
      !button_search ||
      title.toLowerCase().includes(button_search.toLowerCase())
    ) {
      return (
        <SelectItem
          select_type="none"
          icon="keyboard-arrow-right"
          onPress={() => {
            this.props.setModal({
              title: "Permanently Delete Leads",
              description: deleteConfirmationDescription,
              icon: "delete",
              submit: "Permanently Delete",
              buttonType: "destroy",
              require_complete_function: true,
              onPress: complete => {
                this.props.bulkUpdateLeads({
                  token: token,
                  type: "permanently_delete",
                  select_all: selected_all ? 1 : 0,
                  total_count: selected_all
                    ? filtered_count
                    : selected_leads.length,
                  filters:
                    this.props.applied_filter &&
                    this.props.applied_filter.filters
                      ? this.props.applied_filter?.filters
                      : null,
                  property_flags: this.props.lead_filters?.property_flags
                    .map(({ value }) => value)
                    .join(","),
                  property_flags_and_or:
                    this.props.lead_filters?.property_flags_and_or,
                  search: this.props.search,
                  list_id: this.props.active_list_tab?.id
                    ? this.props.active_list_tab?.id
                    : this.props.active_list_tab,
                  list_history_id: this.props.list_history_id || "",
                  lead_ids: selected_all
                    ? ""
                    : selected_leads
                        .map(property => {
                          return property.deal.id;
                        })
                        .join(),
                  onLoading: () => {
                    this.props.updateModal({
                      submit_loading: true
                    });
                  },
                  onError: () => {
                    this.props.updateModal({
                      submit_loading: false
                    });
                  },
                  onSuccess: () => {
                    this.props.updateModal({
                      submit_loading: false
                    });
                    this.props.clearAll();
                    complete();
                  }
                });
              },
              cancel: "Cancel",
              onCancel: () => {}
            });
            this.props.toggleModal({ show: true, type: "normal" });
            this.props.toggleMenu(false);
          }}
        >
          {title}
        </SelectItem>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, lead }) => {
  const { token, user } = auth;
  return {
    token,
    user
  };
};

export default connect(mapStateToProps, {
  bulkUpdateLeads,
  setModal,
  updateModal,
  toggleModal
})(DeleteButton);
