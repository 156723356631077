import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  Card,
  Bold,
  Row,
  Title,
  ProgressBar,
  Scroll,
  Icon
} from "app/NativeComponents/common";
import {
  SmallLabel,
  SelectItem,
  InformationItem,
  Carousel,
  InlineButton
} from "app/NativeComponents/snippets";
import { getHelperDescription, numberWithCommas } from "app/NativeActions";
import MoreInfoButton from "app/DealMachineCore/reuseable/MoreInfoButton";

class Mortgage extends Component {
  constructor(props) {
    super(props);

    this.state = { more_info: false, refresh: false, offset: 0 };

    this.renderMortgage = this.renderMortgage.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.expanded !== prevProps.expanded) {
      this.setState({ refresh: true }, () => {
        this.setState({ refresh: false });
      });
    }
  }

  renderMortgage(mortgage) {
    const { search } = this.props;
    return (
      <Card label={mortgage?.label} style={{ paddingTop: 10, flex: 1 }}>
        <Row
          style={
            this.props.expanded
              ? {
                  flexDirection: "row",
                  alignItems: "stretch",
                  flexWrap: "wrap"
                }
              : { flexDirection: "column", alignItems: "stretch" }
          }
        >
          <InformationItem
            style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
            search={search}
            item={mortgage?.original_loan_amount}
            label={"Original loan amount:"}
            format={"money"}
            hiddenFromNonSubs={true}
            helper={"original_loan_amount"}
          />

          <InformationItem
            style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
            search={search}
            item={mortgage?.est_loan_payment}
            label={"Estimated loan payment:"}
            format={"money"}
            hiddenFromNonSubs={true}
            helper={"estimated_loan_payment"}
          />
          <InformationItem
            style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
            search={search}
            item={
              !!mortgage?.est_interest_rate
                ? parseFloat(mortgage?.est_interest_rate).toFixed(2) + "%"
                : null
            }
            label={"Estimated interest rate:"}
            hiddenFromNonSubs={true}
            helper={"estimated_interest_rate"}
          />
          <InformationItem
            style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
            search={search}
            item={mortgage?.last_recording_date}
            label={"Last recording date:"}
            format={"date"}
            hiddenFromNonSubs={true}
            helper={"last_recording_date"}
          />

          {this.state.more_info || !!search ? (
            <>
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={mortgage?.est_loan_balance}
                label={"Estimated loan balance:"}
                format={"money"}
                hiddenFromNonSubs={true}
                helper={"estimated_loan_balance"}
              />

              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={mortgage?.loan_type}
                label={"Loan type:"}
                hiddenFromNonSubs={true}
                helper={"loan_type"}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={mortgage?.loan_term + " months"}
                label={"Loan term:"}
                format={"months"}
                hiddenFromNonSubs={true}
                helper={"loan_term"}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={mortgage?.interest_rate_type}
                label={"Financing type:"}
                hiddenFromNonSubs={true}
                helper={"interest_rate_type"}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={mortgage?.loan_maturity_date}
                label={"Loan maturity date:"}
                format={"date"}
                hiddenFromNonSubs={true}
                helper={"loan_maturity_date"}
              />

              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={mortgage?.lender_name}
                label={"Lender name:"}
                format={"name"}
                hiddenFromNonSubs={true}
                helper={"lender_name"}
              />
            </>
          ) : null}
        </Row>
        {!search && this.state.more_info ? (
          <InlineButton
            onPress={() => {
              this.setState(
                {
                  more_info: false
                },
                () => {
                  if (this.props.device === "desktop") {
                    this.props.scrollToSection({
                      id: "mortgage-information",
                      offset: this.state.offset
                    });
                  } else {
                    this._interval = setTimeout(() => {
                      this.props.scrollToSection({
                        id: "mortgage-information",
                        offset: this.state.offset
                      });
                    }, 50);
                  }
                }
              );
            }}
            button_type={"full"}
            icon={"keyboard-arrow-up"}
          >
            Less Info
          </InlineButton>
        ) : !search ? (
          <InlineButton
            onPress={() => {
              this.setState(
                {
                  more_info: true
                },
                () => {
                  this.props.scrollToSection({
                    id: "mortgage-information",
                    offset: this.state.offset
                  });
                }
              );
            }}
            button_type={"full"}
            icon={"keyboard-arrow-down"}
          >
            More Info
          </InlineButton>
        ) : null}
      </Card>
    );
  }

  render() {
    const { property, search, colors } = this.props;

    let mortgages = [];

    if (!!property?.mortgage_amount && property?.mortgage_amount > 0) {
      mortgages.push({
        label: "First Mortgage",
        last_recording_date: property?.Mtg1RecordingDate,
        loan_type: property?.Mtg1LoanType,
        //deed_type: null,
        original_loan_amount: property?.Mtg1LoanAmt,
        loan_term: property?.Mtg1Term,
        est_interest_rate: property?.Mtg1EstInterestRate,
        interest_rate_type: property?.Mtg1TypeFinancing,
        est_loan_payment: property?.Mtg1EstPaymentAmount,
        est_loan_balance: property?.Mtg1EstLoanBalance,
        loan_maturity_date: property?.Mtg1LoanDueDate,
        lender_name: property?.Mtg1Lender
        //lender_type: null
      });
    }

    if (!!property?.Mtg2LoanAmt && property?.Mtg2LoanAmt > 0) {
      mortgages.push({
        label: "Second Mortgage",
        last_recording_date: property?.Mtg2RecordingDate,
        loan_type: property?.Mtg2LoanType,
        //deed_type: null,
        original_loan_amount: property?.Mtg2LoanAmt,
        loan_term: property?.Mtg2Term,
        est_interest_rate: property?.Mtg2EstInterestRate,
        interest_rate_type: property?.Mtg2TypeFinancing,
        est_loan_payment: property?.Mtg2EstPaymentAmount,
        est_loan_balance: property?.Mtg2EstLoanBalance,
        loan_maturity_date: property?.Mtg2LoanDueDate,
        lender_name: property?.Mtg2Lender
        //lender_type: null
      });
    }
    if (!!property?.Mtg3LoanAmt && property?.Mtg3LoanAmt > 0) {
      mortgages.push({
        label: "Third Mortgage",
        last_recording_date: property?.Mtg3RecordingDate,
        loan_type: property?.Mtg3LoanType,
        //deed_type: null,
        original_loan_amount: property?.Mtg3LoanAmt,
        loan_term: property?.Mtg3Term,
        est_interest_rate: property?.Mtg3EstInterestRate,
        interest_rate_type: property?.Mtg3TypeFinancing,
        est_loan_payment: property?.Mtg3EstPaymentAmount,
        est_loan_balance: property?.Mtg3EstLoanBalance,
        loan_maturity_date: property?.Mtg3LoanDueDate,
        lender_name: property?.Mtg3Lender
        //lender_type: null
      });
    }
    if (!!property?.Mtg4LoanAmt && property?.Mtg4LoanAmt > 0) {
      mortgages.push({
        label: "Forth Mortgage",
        last_recording_date: property?.Mtg4RecordingDate,
        loan_type: property?.Mtg4LoanType,
        //deed_type: null,
        original_loan_amount: property?.Mtg4LoanAmt,
        loan_term: property?.Mtg4Term,
        est_interest_rate: property?.Mtg4EstInterestRate,
        interest_rate_type: property?.Mtg4TypeFinancing,
        est_loan_payment: property?.Mtg4EstPaymentAmount,
        est_loan_balance: property?.Mtg4EstLoanBalance,
        loan_maturity_date: property?.Mtg4LoanDueDate,
        lender_name: property?.Mtg4Lender
        //lender_type: null
      });
    }
    const market_value = !!property?.EstimatedValue
      ? parseInt(property?.EstimatedValue)
      : !!property?.calculated_total_value
      ? parseInt(property?.calculated_total_value)
      : 0;
    const equity_amount = property.equity_amount || 0;
    let equity_percent = market_value ?  Math.ceil((equity_amount / market_value) * 100) : 0;
    if (equity_percent > 100) {
      equity_percent = 100;
    }

    if (!this.state.refresh) {
      return (
        <>
          {"Estimated Equity".toLowerCase().indexOf(search?.toLowerCase()) !==
            -1 ||
          "Percent Equity".toLowerCase().indexOf(search?.toLowerCase()) !==
            -1 ||
          !search ? (
            <Row>
              <Wrapper
                style={{
                  padding: 15,
                  paddingRight: 25,
                  paddingLeft: 25,
                  flex: 1
                }}
              >
                <Row>
                  <Copy
                    style={{
                      fontSize: 12
                    }}
                  >
                    <Bold>Estimated equity:</Bold>
                  </Copy>
                  {this.props.device === "mobile" ? (
                    <MoreInfoButton
                      title={"Estimated equity"}
                      content={getHelperDescription("equity_amount")}
                      style={{ alignSelf: "stretch" }}
                      innerStyle={{ alignSelf: "stretch" }}
                      renderComponent={({
                        hovering,
                        pressedIn,
                        buttonText
                      }) => {
                        return (
                          <Wrapper style={{ marginLeft: 5 }}>
                            <Icon
                              icon={"help"}
                              color={colors.light_text_color}
                              size={16}
                            />
                          </Wrapper>
                        );
                      }}
                    />
                  ) : (
                    <Wrapper
                      tooltipPlacement={"top"}
                      tooltip={getHelperDescription("equity_amount")}
                      style={{ marginLeft: 5 }}
                    >
                      <Icon
                        icon={"help"}
                        color={colors.light_text_color}
                        size={12}
                      />
                    </Wrapper>
                  )}
                </Row>
                <Title style={{ color: colors.actionable_text_color }}>
                  <Bold>{"$" + numberWithCommas(equity_amount)}</Bold>
                </Title>
              </Wrapper>

              <Wrapper
                style={{
                  padding: 15,
                  paddingRight: 25,
                  paddingLeft: 25,
                  flex: 1
                }}
              >
                <Row>
                  <Copy
                    style={{
                      fontSize: 12
                    }}
                  >
                    <Bold>Percent equity:</Bold>
                  </Copy>

                  {this.props.device === "mobile" ? (
                    <MoreInfoButton
                      title={"Percent equity"}
                      content={getHelperDescription("equity_percent")}
                      style={{ alignSelf: "stretch" }}
                      innerStyle={{ alignSelf: "stretch" }}
                      renderComponent={({
                        hovering,
                        pressedIn,
                        buttonText
                      }) => {
                        return (
                          <Wrapper style={{ marginLeft: 5 }}>
                            <Icon
                              icon={"help"}
                              color={colors.light_text_color}
                              size={16}
                            />
                          </Wrapper>
                        );
                      }}
                    />
                  ) : (
                    <Wrapper
                      tooltipPlacement={"top"}
                      tooltip={getHelperDescription("equity_percent")}
                      style={{ marginLeft: 5 }}
                    >
                      <Icon
                        icon={"help"}
                        color={colors.light_text_color}
                        size={12}
                      />
                    </Wrapper>
                  )}
                </Row>
                <Title style={{ color: colors.actionable_text_color }}>
                  <Bold>
                    {equity_percent !== null ? equity_percent + "%" : "100%"}
                  </Bold>
                </Title>
              </Wrapper>
            </Row>
          ) : null}

          {"Mortgage".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Original Loan Amount"
            .toLowerCase()
            .indexOf(search?.toLowerCase()) !== -1 ||
          "Loan Type".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Loan Term".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Interest Rate".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Interest Rate Type".toLowerCase().indexOf(search?.toLowerCase()) !==
            -1 ||
          "Estimated Loan Payment"
            .toLowerCase()
            .indexOf(search?.toLowerCase()) !== -1 ||
          "Estimated Loan Balance"
            .toLowerCase()
            .indexOf(search?.toLowerCase()) !== -1 ||
          "Loan Maturity Date".toLowerCase().indexOf(search?.toLowerCase()) !==
            -1 ||
          "Lender Name".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Lender Type".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Last recording Date".toLowerCase().indexOf(search?.toLowerCase()) !==
            -1 ||
          "Deed Type".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          !search ? (
            <Wrapper
              id={"mortgage-information"}
              onLayout={event => {
                const { x, y, width, height } = event?.nativeEvent?.layout;
                this.setState({
                  offset: y
                });
              }}
            >
              {mortgages.length == 1 ? (
                <>{this.renderMortgage(mortgages[0])}</>
              ) : mortgages.length > 0 ? (
                <>
                  {this.props.expanded ? (
                    <>
                      <Scroll
                        horizontal={true}
                        style={{ width: "calc(90vw - 450px)", maxWidth: 750 }}
                      >
                        {mortgages.map((mortgage, index) => {
                          return (
                            <Wrapper
                              key={"mort_" + 1}
                              style={{
                                minWidth: 450,
                                maxWidth: 450,
                                width: "100%"
                              }}
                            >
                              {this.renderMortgage(mortgage)}
                            </Wrapper>
                          );
                        })}
                      </Scroll>
                    </>
                  ) : (
                    <Wrapper
                      className={"morgages-carousel"}
                      style={
                        this.props.device === "mobile"
                          ? { marginTop: 10 }
                          : { marginBottom: 10, marginTop: 10 }
                      }
                    >
                      <Carousel
                        items={mortgages}
                        centerMode={true}
                        infinite={true}
                        dots={this.props.device === "mobile"}
                        renderItem={obj => {
                          const mortgage = obj?.item;
                          return (
                            <Wrapper
                              style={{ marginRight: 10, marginLeft: 10 }}
                            >
                              {this.renderMortgage(mortgage)}
                            </Wrapper>
                          );
                        }}
                        include_pagination={true}
                        dotColor={colors.text_color}
                      />
                    </Wrapper>
                  )}
                </>
              ) : null}
            </Wrapper>
          ) : null}
        </>
      );
    }
    return null;
  }
}

export default Mortgage;
