import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Draggable,
  Animation,
  Row,
  Spin,
  Icon,
  Copy,
  Bold
} from "app/NativeComponents/common";
import {
  IconButton,
  InlineButton,
  PullUpSheet
} from "app/NativeComponents/snippets";

import {
  addListToListTabs,
  removeProgressBar,
  tabNavigation,
  numberWithCommas,
  openUrl
} from "app/NativeActions";

class ProgressBarsInner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 80,
      width: 650,
      x: props.window_width / 2 - 225,
      y: props.window_height - 105
    };

    this.renderInner = this.renderInner.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  renderInner() {
    const { colors } = this.props;

    return (
      <>
        {this.props.progress_bars.map((progress_bar, i) => {
          let action_word = "Updating";
          let complete_word = "Update complete";
          let default_title = "Updating Leads...";
          let complete_title = "Update Complete";

          switch (progress_bar?.type) {
            case "import":
              action_word = "Importing";
              complete_word = "Import complete";
              complete_title = "Import Complete";
              default_title = "Importing List...";
              break;
            case "permanently_delete":
              action_word = "Deleting";
              complete_word = "Delete complete";
              default_title = "Deleting Leads...";
              complete_title = "Delete Complete";
              break;

            case "export_leads":
              action_word = "Exporting";
              complete_word = "Export complete";
              complete_title = "Export Complete";
              if (progress_bar?.data?.export_type === "contacts") {
                default_title = "Exporting Contacts...";
              } else {
                default_title = "Exporting Properties...";
              }
              break;
          }

          switch (progress_bar.type) {
            case "import":
            case "edit_status_for_lead":
            case "assign_lead_to_member":
            case "start_mailers":
            case "pause_mailers":
            case "complete_campaign":
            case "create_list_with_leads":
            case "add_leads_to_lists":
            case "remove_leads_from_this_list":
            case "remove_leads_from_list":
            case "add_tags_to_leads":
            case "remove_tags_from_leads":
            case "permanently_delete":
            case "export_leads":
              let progress = Math.ceil(
                (parseInt(progress_bar.progress) /
                  parseInt(progress_bar.total_count)) *
                  100
              );
              if (progress > 100) {
                progress = 100;
              }

              return (
                <>
                  <Wrapper
                    style={{
                      backgroundColor: colors.card_color,
                      width: "100%"
                    }}
                  >
                    <Wrapper
                      className={"list-builder-progress-bar"}
                      style={{
                        backgroundColor: colors.success_color_muted,
                        width: !progress_bar?.completed
                          ? progress + "%"
                          : "100%",
                        height:
                          this.props.device === "mobile"
                            ? "100%"
                            : this.state.height
                      }}
                    />
                  </Wrapper>

                  <Wrapper
                    style={
                      this.props.device === "mobile"
                        ? {
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            width: "100%",
                            height: this.state.height,
                            marginTop: this.props.device === "mobile" ? 40 : 0
                          }
                        : {
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            width: this.state.width,
                            height: this.state.height,
                            justifyContent: "center"
                          }
                    }
                  >
                    <Row>
                      {!progress_bar?.completed ? (
                        <Wrapper style={{ paddingLeft: 25 }}>
                          <Spin size={"small"} />
                        </Wrapper>
                      ) : (
                        <Wrapper style={{ paddingLeft: 25 }}>
                          <Icon
                            icon={"check-circle"}
                            color={colors.success_color}
                          />
                        </Wrapper>
                      )}
                      <Wrapper
                        style={{
                          paddingRight: 25,
                          paddingLeft: 25,
                          flex: 1
                        }}
                      >
                        <Copy style={{ color: colors.actionable_text_color }}>
                          <Bold>
                            {!!progress_bar?.data?.list?.title
                              ? progress_bar?.data?.list?.title
                              : progress_bar?.completed
                              ? complete_title
                              : default_title}
                          </Bold>
                        </Copy>
                        <Copy style={{ fontSize: 12 }}>
                          {progress_bar?.completed ? (
                            <>
                              {complete_word + " for "}
                              <Bold>
                                {numberWithCommas(progress_bar.total_count)}
                              </Bold>
                              {progress_bar?.total_count == 1
                                ? "lead"
                                : " leads"}
                            </>
                          ) : !progress_bar?.completed &&
                            parseInt(progress_bar.progress) >=
                              parseInt(progress_bar.total_count) &&
                            progress_bar?.type === "export_leads" ? (
                            <>
                              {"Preparing your file of "}
                              <Bold>
                                {numberWithCommas(progress_bar.total_count)}
                              </Bold>
                              {progress_bar?.total_count == 1
                                ? "lead. "
                                : " leads. "}
                              {"Hang tight!"}
                            </>
                          ) : (
                            <>
                              {action_word +
                                " " +
                                numberWithCommas(progress_bar.progress) +
                                " of "}
                              <Bold>
                                {numberWithCommas(progress_bar.total_count)}
                              </Bold>
                              {progress_bar?.total_count == 1
                                ? "lead"
                                : " leads"}
                            </>
                          )}
                        </Copy>
                      </Wrapper>
                      {!!progress_bar?.data?.list &&
                      progress_bar?.data?.list?.id &&
                      progress_bar?.type === "import" ? (
                        <InlineButton
                          onPress={() => {
                            this.props.addListToListTabs(
                              progress_bar?.data?.list
                            );
                            this.props.tabNavigation({ slug: "leads" });
                            this.props.removeProgressBar(progress_bar.id);
                          }}
                          disabled={!progress_bar?.completed}
                        >
                          View List
                        </InlineButton>
                      ) : !!progress_bar?.data?.file &&
                        progress_bar?.type === "export_leads" &&
                        progress_bar?.completed ? (
                        <InlineButton
                          onPress={() => {
                            openUrl(progress_bar?.data?.file);
                            this.props.removeProgressBar(progress_bar.id);
                          }}
                          disabled={!progress_bar?.completed}
                        >
                          Download File
                        </InlineButton>
                      ) : null}

                      <IconButton
                        onPress={() => {
                          this.props.removeProgressBar(progress_bar.id);
                        }}
                        tooltipPlacement={"top"}
                        tooltip={"Dismiss"}
                        icon={"close"}
                      />
                    </Row>
                  </Wrapper>
                </>
              );

            default:
              return null;
          }
        })}
      </>
    );
  }

  render() {
    const { colors, window_width, window_height } = this.props;

    if (this.props.device === "mobile") {
      return (
        <PullUpSheet
          sheet_ref={this._bottomSheetRef}
          show={true}
          start_in_middle={false}
          onShow={index => {}}
          onHide={index => {}}
          increase_height_on_keyboard={false}
          scroll_to_hide={false}
          bottom={130}
          top={130}
          renderMobileHandle={() => {
            return (
              <>
                <Wrapper
                  style={{
                    borderWidth: 1,
                    backgroundColor: colors.popover_color,
                    borderColor: colors.border_color,
                    borderStyle: "solid",
                    borderTopLeftRadius: 30,
                    borderTopRightRadius: 30,
                    borderBottomWidth: 0,
                    paddingBottom: 0,

                    shadowColor: "rgba(0,0,0,0.05)",
                    shadowOffset: { width: 0, height: -5 }, // Negative value for top shadow
                    shadowOpacity: 1,
                    shadowRadius: 4,
                    position: "relative",
                    width: "100%",
                    height: 130
                  }}
                >
                  {this.renderInner()}

                  <Wrapper
                    style={{
                      padding: 15,

                      height: 40,
                      alignItems: "center",
                      justifyContent: "flex-start",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      zIndex: 1000
                    }}
                  >
                    <Wrapper
                      style={{
                        height: 8,
                        width: 60,
                        borderRadius: 4,
                        backgroundColor:
                          this.props.dark_mode === "dark_mode"
                            ? colors.success_color
                            : colors.success_color
                      }}
                    />
                  </Wrapper>
                </Wrapper>
              </>
            );
          }}
          enablePanDownToClose={false}
          renderHandle={() => {
            return <></>;
          }}
        ></PullUpSheet>
      );
    }

    return (
      <Draggable
        style={{ zIndex: 1 }}
        container_width={window_width}
        container_height={window_height}
        element={{
          x: this.state.x,
          y: this.state.y,
          width: this.state.width,
          height: this.state.height
        }}
        onDrag={({ x, y }) => {
          this.setState({ x, y });
        }}
      >
        <Animation type={"fadeInUp"} duration={150}>
          <Wrapper
            className={"progress-bars"}
            style={{
              backgroundColor: colors.card_color,
              borderRadius: 5,
              overlay: "hidden"
            }}
          >
            <Wrapper
              style={{
                position: "relative",
                overflow: "hidden",
                borderRadius: 5
              }}
            >
              <Wrapper
                style={{
                  position: "relative",
                  overflow: "hidden",
                  width: this.state.width,
                  height: this.state.height
                }}
              >
                {this.renderInner()}
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Animation>
      </Draggable>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, modal }) => {
  const { token, user } = auth;

  const { dark_mode, colors } = settings;
  const { device, window_width, window_height } = native;
  const { progress_bars } = modal;
  return {
    token,
    user,
    dark_mode,
    colors,
    device,
    window_width,
    window_height,
    progress_bars
  };
};

export default connect(mapStateToProps, {
  addListToListTabs,
  removeProgressBar,
  tabNavigation
})(ProgressBarsInner);
