import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  InternalImage,
  Spinner,
  Copy,
  Title,
  Row,
  Bold,
  Wrapper,
  Card,
  StatusBarStyle,
  Header1,
  Header3
} from "app/NativeComponents/common";
import {
  InlineButton,
  SelectItem,
  OnboardingNavbar
} from "app/NativeComponents/snippets";
import DisplayPlans from "app/DealMachineCore/MarketingOnboardingAndFunnel/GlobalSnippets/DisplayPlans";

import {
  loadOnboardingText,
  loadSpecialPlanSelect,
  openUrl,
  renderPrice
} from "app/NativeActions";
class PayloadBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plan_interval:
        this.props.visitor?.marketing_experiments?.new_annual_offer !==
          "new_annual_offer" &&
        props.qualified_lead &&
        (this.props.signup_funnel !== "use_iap" ||
          this.props.platform !== "ios")
          ? "year"
          : "month",
      hide_annual_toggle: false,
      contentful_content: null,
      contentful_loading: true,
      selected_list_builder: false,
      view_normal: false,
      selected_stripe_id: null
    };

    this.loadData = this.loadData.bind(this);
    this.getPricingPage = this.getPricingPage.bind(this);
  }

  componentDidMount() {
    if (!!this.props.user_info?.special) {
      this.getSpecialPlan();
    } else {
      this.getPricingPage();
    }

    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/select-a-plan");
    }
  }

  getSpecialPlan() {
    loadSpecialPlanSelect(this.props.user_info?.special)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            const contentful_content = data.items[0]
              ? data.items[0].fields
                ? data.items[0].fields
                : null
              : null;
            if (!!contentful_content.select_a_plan_slug) {
              this.loadData(contentful_content.select_a_plan_slug);
            } else {
              this.getPricingPage();
            }
          } else {
            this.getPricingPage();
          }
        } else {
          this.getPricingPage();
        }
      })
      .catch(err => {
        this.getPricingPage();
      });
  }

  getPricingPage() {
    if (!!this.props.user?.promo_pricing_contentful_slug) {
      this.loadData(this.props.user?.promo_pricing_contentful_slug);
    } else {
      if (
        this.props.visitor?.marketing_experiments?.new_annual_offer ===
        "new_annual_offer"
      ) {
        this.loadData(
          !!this.props.contentful_slug
            ? this.props.contentful_slug
            : "select_a_plan_new_annual_offer"
        );
      } else {
        this.loadData(
          !!this.props.contentful_slug
            ? this.props.contentful_slug
            : "select_a_plan"
        );
      }
    }
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState(
              {
                contentful_content: data.items[0]
                  ? data.items[0].fields
                    ? data.items[0].fields
                    : null
                  : null,
                contentful_loading: false
              },
              () => {
                if (!!this.state.contentful_content?.annual_toggle) {
                  this.setState({
                    plan_interval:
                      this.state.contentful_content?.annual_toggle ==
                        "year_only" ||
                      this.state.contentful_content?.annual_toggle == "year"
                        ? "year"
                        : this.state.contentful_content?.annual_toggle ==
                            "month_only" ||
                          this.state.contentful_content?.annual_toggle ==
                            "month"
                        ? "month"
                        : "month" || this.state.plan_interval,
                    hide_annual_toggle:
                      this.state.contentful_content?.annual_toggle ==
                        "year_only" ||
                      this.state.contentful_content?.annual_toggle ==
                        "month_only"
                        ? true
                        : false
                  });
                }
              }
            );
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  render() {
    const { contentful_content, contentful_loading } = this.state;
    const { colors, desktopMobile } = this.props;

    const annual_toggle_text = !!contentful_content?.annual_toggle_text
      ? contentful_content?.annual_toggle_text
      : "Save 17% with annual";

    return (
      <Container>
        <StatusBarStyle statusBarStyle={"dark-content"} />

        <KeyboardView style={{ flex: 1 }}>
          <OnboardingNavbar />
          {this.state.contentful_content ? (
            <>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 25
                }}
              >
                <Header1
                  style={{
                    textAlign: "center"
                  }}
                >
                  <Bold>{contentful_content?.title}</Bold>
                </Header1>
                <Header3
                  style={{
                    textAlign: "center"
                  }}
                >
                  {contentful_content?.subtitle}
                </Header3>
              </Wrapper>

              <Wrapper
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <Row>
                  {(this.props.platform !== "ios" ||
                    this.props.signup_funnel !== "use_iap") &&
                  (!this.state.hide_annual_toggle || this.state.view_normal) &&
                  !this.state.selected_stripe_id ? (
                    <Wrapper
                      className={
                        this.state.plan_interval == "year"
                          ? "colorful-sparkle-border"
                          : "sparkle-effect"
                      }
                      style={{ borderRadius: 30, margin: 5 }}
                    >
                      <InlineButton
                        iconColor={
                          this.state.plan_interval == "year"
                            ? colors.success_color
                            : null
                        }
                        icon={
                          this.state.plan_interval == "year"
                            ? "toggle-on"
                            : "toggle-off"
                        }
                        style={{
                          margin: 0,
                          backgroundColor:
                            this.state.plan_interval == "month"
                              ? colors.active_color_muted
                              : colors.success_color_muted
                        }}
                        onPress={() => {
                          this.setState({
                            plan_interval:
                              this.state.plan_interval == "month"
                                ? "year"
                                : "month"
                          });
                        }}
                      >
                        <Bold>{annual_toggle_text}</Bold>
                      </InlineButton>
                    </Wrapper>
                  ) : null}
                  {!!this.props.user_info?.special &&
                  !this.state.selected_stripe_id ? (
                    <InlineButton
                      onPress={() => {
                        if (!this.state.view_normal) {
                          this.setState(
                            {
                              view_normal: true,
                              plan_interval: this.props.qualified_lead
                                ? "year"
                                : "month"
                            },
                            () => {
                              this.getPricingPage();
                            }
                          );
                        } else {
                          this.setState(
                            {
                              view_normal: false,
                              plan_interval:
                                this.state.contentful_content?.annual_toggle ==
                                  "year_only" ||
                                this.state.contentful_content?.annual_toggle ==
                                  "year"
                                  ? "year"
                                  : "month"
                            },
                            () => {
                              this.getSpecialPlan();
                            }
                          );
                        }
                      }}
                    >
                      {!this.state.view_normal
                        ? "😐 View our normal plans."
                        : "🎁 View our special plans."}
                    </InlineButton>
                  ) : null}
                </Row>
              </Wrapper>

              <DisplayPlans
                platform={this.props.platform}
                contentful_content={this.state.contentful_content}
                selected_list_builder={this.state.selected_list_builder}
                needs_lists={this.props.needs_lists}
                needs_dialer={this.props.needs_dialer}
                plan_interval={this.state.plan_interval}
                signup_funnel={this.props.signup_funnel}
                onSelectListBuilder={selected_list_builder => {
                  this.setState({
                    selected_list_builder
                  });
                }}
                onPurchase={this.props.onPurchase}
                selected_stripe_id={this.state.selected_stripe_id}
                onPlanSelection={stripe_id => {
                  this.setState({
                    selected_stripe_id: stripe_id
                  });
                }}
              />

              {this.props.platform == "ios" &&
              this.props.signup_funnel == "use_iap" ? (
                <Wrapper
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 25
                  }}
                >
                  <InlineButton
                    button_type={"full"}
                    onPress={this.props.restorePurchases}
                    //loading={this.props.restore_loading}
                  >
                    Restore Purchases
                  </InlineButton>
                </Wrapper>
              ) : !!contentful_content?.description ? (
                <Wrapper
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 25
                  }}
                >
                  <Copy style={{ textAlign: "center" }}>
                    {contentful_content.description}
                  </Copy>
                </Wrapper>
              ) : null}
            </>
          ) : null}
          <Wrapper style={{ height: 40 }} />
        </KeyboardView>
      </Container>
    );
  }
}

export default PayloadBody;
