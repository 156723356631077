import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  InnerCopy,
  Row,
  Bold,
  Button,
  Icon,
  KeyboardView
} from "app/NativeComponents/common";

import {
  PopoverMenu,
  SelectItem,
  IconButton,
  CircleButton
} from "app/NativeComponents/snippets";
import {
  triggerFollowUpQueueRefresh,
  pushSidePanel,
  updateCallSession,
  formatAddress,
  formatUsPhone,
  setActiveDialerContact
} from "app/NativeActions";

import moment from "moment";

class FollowUpQueueItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_note: false
    };
  }

  render() {
    const { colors } = this.props;
    const { item } = this.props;

    return (
      <SelectItem
        noPress={true}
        select_type={"icon"}
        s_icon_color={colors.success_color}
        select_icon={item.ready_to_call ? "check-circle" : "schedule"}
        innerStyle={
          this.state.show_note
            ? {
                paddingBottom: 15
              }
            : null
        }
        renderBottom={
          !!item?.note && this.state.show_note
            ? () => {
                return (
                  <Wrapper
                    style={{
                      marginLeft: 55,
                      padding: 15,
                      marginRight: 10,
                      backgroundColor: colors.background_color
                    }}
                  >
                    <Copy style={{ fontSize: 12 }}>
                      <Bold>Last Call Summary: </Bold>
                    </Copy>
                    <Copy style={{ fontSize: 12 }}>{item?.note}</Copy>
                  </Wrapper>
                );
              }
            : null
        }
        renderRight={() => {
          return (
            <Row>
              {(this.props.user?.team_owner == 1 ||
                this.props.user?.can_send_message == 1) &&
              this.props.device !== "mobile" ? (
                <CircleButton
                  icon={"phone"}
                  tooltip={"Call Now"}
                  tooltipPlacement={"top"}
                  onPress={() => {
                    this.props.setActiveDialerContact({
                      contact: {
                        ...item.contact,
                        selected_phone: {
                          number: item.to_phone_number,
                          type: item.phone_type,
                          do_not_call_flag: item.do_not_call_flag,
                          owner: item.phone_owner
                        },
                        associated_lead: item.property,
                        follow_up_queue: true
                      }
                    });

                    //remove from queue...
                    this.props.removeItemFromFollowUpQueue(item);
                  }}
                />
              ) : null}

              <PopoverMenu
                show={false}
                no_swipe={true}
                no_cancel={true}
                onShow={s => {}}
                popover_width={350}
                popoverPlacement={"top"}
                includeCloseButton={true}
                renderComponent={options => {
                  return (
                    <IconButton
                      icon={"more-vert"}
                      noPress={true}
                      pressedIn={
                        options.hovering ||
                        options.pressedIn ||
                        this.state.show_team_filter
                      }
                    />
                  );
                }}
                menu_items={[
                  {
                    title: "View Property",
                    onPress: () => {
                      this.props.pushSidePanel({
                        slug: "property",
                        id: item?.property?.property_id,
                        focus_side_panel: true,
                        data: {
                          property: item?.property
                        }
                      });
                    },
                    select_type: "none",
                    icon: "keyboard-arrow-right"
                  },
                  {
                    title: "View Contact",
                    onPress: () => {
                      this.props.pushSidePanel({
                        slug: "contact",
                        id: item?.contact?.individual_key,
                        data: {
                          person: item?.contact
                        }
                      });
                    },
                    select_type: "none",
                    icon: "keyboard-arrow-right"
                  },

                  {
                    title: "Remove from Follow-up Queue",
                    onPress: () => {
                      this.props.updateCallSession({
                        token: this.props.token,
                        call_id: item.call_id,
                        type: "remove_from_follow_up_queue",
                        onLoading: () => {
                          this.props.removeItemFromFollowUpQueue(item);
                        },
                        onError: error => {},
                        onSuccess: () => {
                          this.props.triggerFollowUpQueueRefresh(true);
                        }
                      });
                    },
                    select_type: "none",
                    icon: "delete"
                  }
                ]}
              />
            </Row>
          );
        }}
        renderContent={() => {
          const property = item?.property;
          const formatted_address = formatAddress({
            address: {
              address: property?.property_address,
              address2: property?.property_address2,
              address_city: property?.property_address_city,
              address_state: property?.property_address_state,
              address_zip: property?.property_address_zip,
              latitude: property?.location?.latitude,
              longitude: property?.location?.longitude
            }
          });
          return (
            <Wrapper style={{ flex: 1 }}>
              <Row>
                <Wrapper style={{ marginRight: 10, marginBottom: 5 }}>
                  <Row>
                    <Copy style={{ marginRight: 10 }}>
                      <Bold>{item?.contact?.full_name}</Bold>
                    </Copy>

                    <Copy
                      style={{
                        color: colors.active_color,
                        fontSize: 12
                      }}
                    >
                      {item?.call_results_label}
                    </Copy>
                  </Row>

                  <Row>
                    <Copy>{formatUsPhone(item?.to_phone_number)}</Copy>
                  </Row>
                </Wrapper>
              </Row>

              {item?.property ? (
                <Wrapper>
                  <Copy
                    style={{
                      fontSize: 12
                    }}
                  >
                    Calling about: <Bold>{formatted_address?.line1}</Bold>
                  </Copy>
                </Wrapper>
              ) : null}

              <Row>
                <Wrapper>
                  {!item.ready_to_call ? (
                    <Copy
                      style={{
                        fontSize: 12,
                        color: colors.light_text_color
                      }}
                    >
                      {"Scheduled for: "}
                      {moment(item?.next_call_date).format("MMM Do, YYYY")}
                    </Copy>
                  ) : (
                    <Copy
                      style={{
                        fontSize: 12,
                        color: colors.light_text_color
                      }}
                    >
                      {"Last called: "}
                      {moment(item?.last_called_date).format("MMM Do, YYYY")}
                    </Copy>
                  )}
                </Wrapper>
                {!!item?.note && !this.state.show_note ? (
                  <Button
                    onPress={() => {
                      this.setState({
                        show_note: !this.state.show_note
                      });
                    }}
                    style={{ marginLeft: 10 }}
                  >
                    <Row>
                      <Copy
                        style={{
                          fontSize: 12
                        }}
                      >
                        View Note
                      </Copy>
                      <Icon
                        icon={"keyboard-arrow-down"}
                        size={14}
                        style={{ marginLeft: 5 }}
                      />
                    </Row>
                  </Button>
                ) : null}
              </Row>
            </Wrapper>
          );
        }}
      />
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  updateCallSession,
  setActiveDialerContact,
  triggerFollowUpQueueRefresh
})(FollowUpQueueItem);
