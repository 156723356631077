import { isPointInPolygon, getDistance  } from "geolib";

export const checkIfCoordinatesAreInProperty = (coordinates, properties) => {
  let selected_properties = [];

  if (properties) {
    for (let i = 0; i < properties.length; i++) {
      if (properties[i].coordinates) {
        for (let j = 0; j < properties[i].coordinates.length; j++) {
          if (isPointInPolygon(coordinates, properties[i].coordinates[j])) {
            selected_properties.push(properties[i]);
          }
        }
      }
    }
  }

  if (selected_properties.length > 0) {
    return selected_properties;
  }

  return false;
};


export const findClosestProperty = (selectedCoordinate, properties) => {
  if (!properties || properties.length === 0) {
    return null;
  }

  let closestProperty = null;
  let minDistance = Infinity;

  properties.forEach(property => {
    if (
        property.location
    ) {
      const distance = getDistance(
          { latitude: selectedCoordinate.latitude, longitude: selectedCoordinate.longitude },
          { latitude: property.location.latitude, longitude: property.location.longitude }
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestProperty = property;
      }
    } else {
      console.error('Invalid property location:', property.location);
    }
  });

  return closestProperty;
};
