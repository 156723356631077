import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Scroll,
  Wrapper,
  Spin,
  CenterCenter,
  MultiLineInput,
  Row,
  InputDatePicker,
  Copy,
  KeyboardView,
  Card,
  Input,
  Icon,
  ModalOverlay,
  Bold,
  ExternalImage
} from "app/NativeComponents/common";
import {
  Header,
  Select,
  DateSelector,
  NewHeader,
  SpinWrapper,
  SmallLabel,
  InputBox,
  SelectBox,
  DateBox,
  ConfirmLabel
} from "app/NativeComponents/snippets";
import {
  appRedirect,
  setModal,
  toggleModal,
  getTeam,
  getTasks,
  focusNextField,
  createTask,
  setActiveTaskCount,
  popSidePanel,
  clearTaskList
} from "app/NativeActions";
import moment from "moment";
import {
  dismissMobileKeyboard,
  determineDisplayProperty,
  formatAddress
} from "app/NativeActions";
class TaskCreate extends Component {
  constructor(props) {
    super(props);
    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = {
      panel_data,
      is_loading: true,
      loading: false,
      task: {
        task_due_date: "",
        task_assignee: props.user?.id,
        task_title: "",
        task_description: "",
        house: panel_data?.property?.deal?.id || ""
      },
      formatted_items: [],
      task_assignee_text: "Myself",
      assign_to_focus: false
    };
    this.loadTasks = this.loadTasks.bind(this);
    this.saveTask = this.saveTask.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.setUserSelectText = this.setUserSelectText.bind(this);
    this.formatArray = this.formatArray.bind(this);
    this.formatTaskCompletionTime = this.formatTaskCompletionTime.bind(this);

    this._title_input = React.createRef();
    this._assign_to_select = React.createRef();
    this._due_date_select = React.createRef();
    this._details_input = React.createRef();
  }

  componentDidMount() {
    if (this.props.my_team.length === 0 && this.props.user) {
      if (!this.props.team_loading && !this.props.team_members_loading) {
        this.props.getTeam({ token: this.props.token, type: "load" });
      }
    }
    if (this.props.task_list.travel_time) {
    }
    if (this.props.task_list) {
      if (this.props.task_list.title) {
        this.editTaskField("task_title", "Drive " + this.props.task_list.title);
      }
    }
    if (this.props.my_team && this.props.my_team.length > 0) {
      this.formatArray();

      this.setState({ is_loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.my_team.length === 0 &&
      this.props.token &&
      this.props.token !== prevProps.token
    ) {
      if (!this.props.team_loading && !this.props.team_members_loading) {
        this.props.getTeam({ token: this.props.token, type: "load" });
      }
    }

    if (
      this.props.task_panel === "new_task" &&
      prevProps.task_panel !== "new_task"
    ) {
      this.setState({
        task: {
          ...this.state.task,
          task_due_date: "",
          task_assignee: this.props.user?.id,
          task_title: "",
          task_description: ""
        },
        task_assignee_text: "Myself"
      });
    }

    if (
      (prevProps.my_team !== this.props.my_team || this.state.is_loading) &&
      this.props.my_team.length !== 0
    ) {
      this.formatArray();
      this.setState({ is_loading: false });
    }

    if (prevProps.task_list !== this.props.task_list) {
      this.editTaskField("task_title", "Drive " + this.props.task_list.title);
    }
  }
  loadTasks() {
    this.props.getTasks({
      token: this.props.token,
      begin: this.props.tasks_begin,
      type: "get",
      limit: this.props.tasks_limit,
      sort_by: this.props.sort_by,
      filter_by: this.props.filter_by,
      filter_user: this.props.filter_user
    });
  }

  saveTask() {
    this.setState({
      loading: true
    });

    this.props.createTask({
      token: this.props.token,
      type: "add",
      title: this.state.task.task_title,
      assigned_user: this.state.task.task_assignee,
      date_due: this.state.task.task_due_date,
      house: this.state.task.house,
      notes: this.state.task.task_description,
      list_id: this.props.task_list ? this.props.task_list?.id : 0,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false
        });
        if (this.state.panel_data?.onSuccess) {
          this.state.panel_data.onSuccess(results);
        }
        this.props.popSidePanel();
      }
    });
  }

  formatArray = () => {
    if (!this.props.user) return;
    let formatted_array = [];
    formatted_array.push({
      value: this.props.user.id,
      label: "Myself"
    });
    if (this.props.user.team_clearance_level >= 2) {
      for (let i = 0; i < this.props.my_team.length; i++) {
        if (this.props.my_team[i].id != this.props.user.id) {
          formatted_array.push({
            value: this.props.my_team[i].id,
            label:
              this.props.my_team[i].firstname && this.props.my_team[i].lastname
                ? this.props.my_team[i].firstname +
                  " " +
                  this.props.my_team[i].lastname
                : this.props.my_team[i].email
          });
        }
      }
    }

    this.setState({
      formatted_items: formatted_array
    });
  };

  editTaskField(prop, value) {
    this.setState({
      task: {
        ...this.state.task,
        [prop]: value
      }
    });
  }

  setUserSelectText(key) {
    let $this = this;
    this.state.formatted_items.forEach(function (val) {
      if (key === $this.props.user.id || !key) {
        $this.setState({ task_assignee_text: "Myself" });
      } else if (val.value === key) {
        $this.setState({ task_assignee_text: val.label });
      }
    });
  }
  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  formatTaskCompletionTime(task_time) {
    if (task_time) {
      let split_time = task_time.split(":");
      let hours = split_time[0];
      let minutes = split_time[1];
      let formatted_time = "";

      if (minutes > 0) {
        minutes = minutes + " minutes";
      } else {
        minutes = "";
      }
      if (hours > 1) {
        hours = hours + " hours";
      } else if (hours < 1) {
        hours = "";
      } else {
        hours = hours + " hour";
      }
      formatted_time = hours + " " + minutes;

      return formatted_time;
    }
  }
  renderBody() {
    const { colors } = this.props;
    let formatted_property_address = "";
    if (this.state.panel_data?.property) {
      const display_property = determineDisplayProperty(
        this.state.panel_data?.property
      );
      formatted_property_address = formatAddress({
        address: {
          address: display_property.property_address,
          address2: display_property.property_address2,
          address_city: display_property.property_address_city,
          address_state: display_property.property_address_state,
          address_zip: display_property.property_address_zip,
          latitude: this.state.panel_data?.property?.location?.latitude,
          longitude: this.state.panel_data?.property?.location?.longitude
        }
      });
    }

    return (
      <>
        <NewHeader
          title={"Add New Task"}
          subtitle={
            !!formatted_property_address
              ? "Add a new task for " + formatted_property_address.line1
              : ""
          }
          leftButton={{
            icon:
              this.props.isMobile || this.props.side_panel_views?.length > 0
                ? "arrow-back"
                : "close",
            onPress: this.handleBack
          }}
        />
        {this.state.is_loading || this.props.active_task_loading ? (
          <SpinWrapper text="Loading task creation..." />
        ) : (
          <>
            {!!this.state.task.task_title ? (
              <ConfirmLabel
                style={{
                  alignSelf: "stretch"
                }}
                icon="add-task"
                label={"Create a task for " + this.state.task_assignee_text}
                confirmButton={"Create Task"}
                loading={this.state.loading}
                primary={true}
                disabled={this.state.loading}
                onPress={() => {
                  this.saveTask();
                }}
              />
            ) : null}
            <KeyboardView
              style={{
                flex: 1
              }}
            >
              {this.props.task_list.title ? (
                <>
                  <Wrapper
                    style={{
                      padding: 25
                    }}
                  >
                    <SmallLabel
                      style={{ padding: 0, paddingTop: 0, paddingBottom: 0 }}
                    >
                      Associated List:
                    </SmallLabel>
                    <Copy>{this.props.task_list.title}</Copy>
                  </Wrapper>
                  <Wrapper
                    style={{ margin: 5, height: 150, position: "relative" }}
                  >
                    <ExternalImage
                      style={{
                        width: "100%",
                        height: 150,
                        borderRadius: 5
                      }}
                      image={this.props.task_list.google_map_url}
                    />

                    <Wrapper
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        padding: 0
                      }}
                    >
                      <Row>
                        <Wrapper
                          style={{
                            padding: 5,
                            margin: 5,
                            borderRadius: 5,
                            backgroundColor: "rgba(0,0,0,0.5)"
                          }}
                        >
                          <Row>
                            <Icon
                              style={{ marginRight: 5 }}
                              color={colors.white_text_color}
                              icon={"house"}
                              size={18}
                            />
                            <Copy style={{ color: colors.white_text_color }}>
                              {this.props.task_list.lead_count}
                            </Copy>
                          </Row>
                        </Wrapper>
                        <Wrapper
                          style={{
                            padding: 5,
                            margin: 5,
                            borderRadius: 5,
                            backgroundColor: "rgba(0,0,0,0.5)"
                          }}
                        >
                          <Row>
                            <Icon
                              style={{ marginRight: 5 }}
                              color={colors.white_text_color}
                              icon={"alarm"}
                              size={18}
                            />
                            <Copy style={{ color: colors.white_text_color }}>
                              {this.props.task_list.travel_time
                                ? this.formatTaskCompletionTime(
                                    this.props.task_list.travel_time
                                  )
                                : "???"}
                            </Copy>
                          </Row>
                        </Wrapper>
                      </Row>
                    </Wrapper>
                  </Wrapper>
                </>
              ) : null}
              <>
                <InputBox
                  input_ref={this._title_input}
                  name="title"
                  disabled={this.state.loading}
                  returnKeyType="next"
                  placeholder="Task Description"
                  onFocus={() => {}}
                  onChange={value => {
                    this.editTaskField("task_title", value);
                  }}
                  onSubmitEditing={() => {
                    this.setState({ assign_to_focus: true });
                  }}
                  blurOnSubmit={true}
                  value={this.state.task.task_title}
                  type="text"
                />

                <SelectBox
                  select_ref={this._assign_to_select}
                  items={this.state.formatted_items}
                  disabled={this.state.loading}
                  value={this.state.task.task_assignee}
                  placeholder="Assign to:"
                  show={this.state.assign_to_focus}
                  onFocus={() => {
                    this.setState({ assign_to_focus: true });
                  }}
                  onBlur={() => {
                    this.setState({ assign_to_focus: false });
                  }}
                  onSelect={item => {
                    this.setUserSelectText(item);
                    this.editTaskField("task_assignee", item);
                  }}
                  onSubmitEditing={() => {}}
                />

                <InputBox
                  numberOfLines={2}
                  input_ref={this._details_input}
                  name="details"
                  disabled={this.state.loading}
                  returnKeyType="done"
                  placeholder="Task Details"
                  onFocus={() => {}}
                  onChange={value => {
                    this.editTaskField("task_description", value);
                  }}
                  onSubmitEditing={() => {}}
                  blurOnSubmit={true}
                  value={this.state.task.task_description}
                  type="multiline"
                />
              </>
              <DateBox
                input_ref={this._due_date_select}
                name="due_date"
                disabled={this.state.loading}
                placeholder="Due date:"
                onFocus={() => {}}
                onChange={value => this.editTaskField("task_due_date", value)}
                value={
                  !!this.state.task?.task_due_date
                    ? moment(this.state.task?.task_due_date).format(
                        "MMM Do, YYYY"
                      )
                    : "--"
                }
                date_value={
                  !!this.state.task?.task_due_date
                    ? this.state.task?.task_due_date
                    : moment().format("YYYY-MM-DD")
                }
              />
            </KeyboardView>
          </>
        )}
      </>
    );
  }

  render() {
    const { task_panel, colors } = this.props;
    return (
      <Container
        style={{
          position: "relative",

          display: "flex",
          alignSelf: "stretch",
          backgroundColor: this.props.colors.card_color
        }}
      >
        {this.renderBody()}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, team, tasks, modal }) => {
  const { token, user } = auth;
  const {
    filter_by,
    filter_user,
    sort_by,
    task_limit,
    tasks_begin,
    active_task_count,
    task_panel,
    task_list,
    active_task_loading
  } = tasks;
  const { colors } = settings;
  const { device, platform, isMobile } = native;

  const { team_members_loading, my_team } = team;
  const { side_panel_views } = modal;
  return {
    token,
    team_members_loading,
    my_team,
    user,
    colors,
    device,
    task_list,
    platform,
    isMobile,
    filter_by,
    filter_user,
    sort_by,
    task_limit,
    tasks_begin,
    active_task_count,
    task_panel,
    active_task_loading,
    side_panel_views
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  setModal,
  toggleModal,
  getTeam,
  createTask,
  getTasks,
  setActiveTaskCount,
  popSidePanel,
  clearTaskList
})(TaskCreate);
