import React, { Component } from "react";
import { store } from "app/store";
import {
  Row,
  Wrapper,
  Copy,
  Bold,
  Button,
  Icon
} from "app/NativeComponents/common";
import { numberWithCommas } from "app/NativeActions";
class SelectAllText extends Component {
  render() {
    const {
      filtered_count,
      filtered_count_loading,
      selected_leads,
      selected_all
    } = this.props;

    let total_leads_remaining = filtered_count - selected_leads.length;

    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    const isMobile = store.getState().native.isMobile;

    if (filtered_count_loading) {
      return (
        <Row
          style={{
            padding: isMobile ? 25 : 20,
            paddingBottom: isMobile ? 25 : 10,
            paddingTop: isMobile ? 25 : 0
          }}
        >
          <Wrapper
            style={
              isMobile ? { alignItems: "center", justifyContent: "center" } : {}
            }
          >
            <Copy>Loading filtered count...</Copy>
          </Wrapper>
        </Row>
      );
    }

    return (
      <Row
        style={{
          padding: isMobile ? 25 : 20,
          paddingBottom: isMobile ? 25 : 10,
          paddingTop: isMobile ? 25 : 0
        }}
      >
        <Wrapper
          style={{ flex: 1,  flexWrap: 'wrap', width:"100%",  alignItems: isMobile ? "center" : "flex-start" }}
        >
          <Row style={{ flexWrap: 'wrap'}}>
            {(selected_all || total_leads_remaining == 0) &&
            filtered_count > 0 ? (
              <>
                {!filtered_count_loading ? (
                  <Copy>
                    {numberWithCommas(filtered_count)}{" "}
                    {filtered_count == 1 ? "lead " : "leads "}
                    selected.
                  </Copy>
                ) : (
                  <Copy>All leads selected.</Copy>
                )}
                {(selected_leads && selected_leads.length > 0) ||
                selected_all ? (
                  <Button
                    onPress={() => this.props.clearAll()}
                    style={{ marginLeft: 5 }}
                  >
                    <Copy
                      style={{
                        color: colors.actionable_text_color
                      }}
                    >
                      <Bold>Clear Selection</Bold>
                    </Copy>
                  </Button>
                ) : null}
              </>
            ) : (
              <>
                {filtered_count == 0 ? (
                  <Copy>0 Leads</Copy>
                ) : (
                  <>
                    {selected_leads && selected_leads.length == 1 ? (
                      <Copy style={{ marginRight: 5 }}>1 lead selected.</Copy>
                    ) : selected_leads.length > 0 ? (
                      <Copy style={{ marginRight: 5 }}>
                        {selected_leads.length} leads selected.
                      </Copy>
                    ) : null}

                    {!filtered_count_loading && total_leads_remaining != 0 ? (
                      <Copy>
                        {numberWithCommas(total_leads_remaining)}{" "}
                        {total_leads_remaining == 1 ? "lead" : "leads"}{" "}
                        currently unselected.
                      </Copy>
                    ) : null}
                    {total_leads_remaining > 0 ? (
                      <Button
                        onPress={() => this.props.checkAllLeads()}
                        style={{ marginLeft: 5 }}
                      >
                        <Copy
                          style={{
                            color: colors.actionable_text_color
                          }}
                        >
                          <Bold>Select All</Bold>
                        </Copy>
                      </Button>
                    ) : null}
                  </>
                )}
              </>
            )}
          </Row>
        </Wrapper>
      </Row>
    );
  }
}

export default SelectAllText;
