import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Wrapper,
  Title,
  Copy,
  Bold,
  Scroll,
  Row,
  Spin,
  Card
} from "app/NativeComponents/common";
import {
  NewHeader,
  SpinWrapper,
  SelectItem,
  GhostButton,
  CircleButton,
  IconButton,
  InlineButton,
  InlineTabs,
  SearchBar,
  PopoverMenu,
  BottomNavBar,
  ConfirmInlineButton,
  UpsellButton,
  ConfirmLabel,
  SmallLabel
} from "app/NativeComponents/snippets";
import {
  getPropertyNew,
  popSidePanel,
  pushSidePanel,
  replaceSidePanel,
  updateSidePanel,
  updateLead,
  formatAddress,
  updateOwnerInfo,
  reloadLeads,
  clearTaskList,
  setStartMailerCampaignPage,
  determineDisplayProperty,
  determineMainOwnerInfo,
  showSuccess,
  copyToClipBoard,
  nextOnboardingStep,
  AppConfig,
  setOnboardingLayout,
  setModal,
  toggleModal,
  checkIfUserHasSubscription,
  DeepLink,
  openMaps,
  numberWithCommas,
  getComps,
  renderPrice,
  getDefaultCompsArray,
  callPhoneNumber,
  openEmail,
  toTitleCase,
  updateUserSettings,
  setUpdatedLeads
} from "app/NativeActions";
import LeadMainPhoto from "app/DealMachineCore/reuseable/LeadMainPhoto";

import LeadPhotoManager from "app/DealMachineCore/reuseable/LeadPhotoManager";
import HeaderButton from "app/DealMachineCore/native/GlobalSnippets/NewHeader/HeaderButton";
import TopPropertyOptions from "./TopPropertyOptions";
import PropertyInfoNew from "./PropertyInfoNew";
import Activity from "./Activity";
import Comps from "./Comps";
import Mail from "./Mail";
import DispoTab from "./DispoTab";

import NavigationService from "app/Router/NavigationService";
import Alma from "./Alma";

import OwnerInfo from "./OwnerInfo";
import EstimatedValue from "./EstimatedValue";
class Property extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    const panel_index =
      props.device == "mobile"
        ? props?.route?.params?.panel_index
        : props.panel_index;
    this.state = {
      comps_loading: false,
      comps_value: null,
      panel_index,
      tab: "info",
      info_search: "",
      info_focus: false,
      info_tab: "info",
      property_loading: false,
      property: panel_data?.property || null,
      scroll_header_opacity: 0,
      scrollOffset: 0,
      expanded_scroll_header_opacity: 0,
      expandedScrollOffset: 0,
      top_view_height: 0,
      property_view_tabs_height: 0,
      skip_trace_loading: false,
      started_skip_trace: false,
      show_more_owner_info: false,
      mail_loading: false,
      map_dragging: false,
      activity_search: "",
      activity_focus: false,
      lead_activity_loading: false,
      lead_activity_loading_id: null,
      activity: [],
      expanded: props.expanded
        ? props.expanded === "expanded"
          ? true
          : false
        : props.device !== "desktop"
        ? false
        : panel_data?.expanded
        ? panel_data?.expanded
        : props.user?.user_settings?.property_expanded == "not_expanded"
        ? false
        : true,
      show_comps_panel: false,
      show_alma_panel:
        props.dismissed_alma || props.user?.user_settings?.hide_alma
          ? "hide"
          : "hide",
      pressed_alma: false,
      deleting: false
    };

    this._scroll_view = React.createRef();
    this._expanded_scroll_view = React.createRef();

    this.updateMainState = this.updateMainState.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.propertyUpdated = this.propertyUpdated.bind(this);
    this.scrollToTabs = this.scrollToTabs.bind(this);
    this.scrollToSection = this.scrollToSection.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.renderInner = this.renderInner.bind(this);
    this.renderTop = this.renderTop.bind(this);
    this.setMapDragging = this.setMapDragging.bind(this);
  }

  componentDidMount() {
    if (this.props.device == "mobile") {
      NavigationService.setTopLevelNavigator(this.props.navigation);
    }
    if (this.state.property) {
      console.log("one");
      this.getMoreData(this.state.property);
    } else {
      const panel_id =
        this.props.device == "mobile"
          ? this.props?.route?.params?.panel_id
          : this.props.panel_id;
      console.log("two");

      this.getMoreData(null, panel_id);
    }

    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    if (panel_data?.tab == "comps") {
      this.scrollToTabs();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    const prev_panel_data =
      prevProps.device == "mobile"
        ? prevProps?.route?.params?.panel_data
        : prevProps.panel_data;

    const panel_id =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_id
        : this.props.panel_id;

    const prev_panel_id =
      prevProps.device == "mobile"
        ? prevProps?.route?.params?.panel_id
        : prevProps.panel_id;

    if (
      (panel_data?.property &&
        prev_panel_data?.property &&
        panel_data?.property?.id != prev_panel_data?.property?.id) ||
      panel_id != prev_panel_id
    ) {
      this.setState({
        skip_trace_loading: false,
        started_skip_trace: false,
        mail_loading: false,
        info_tab: "info"
      });
      console.log("three");

      this.getMoreData(panel_data?.property, panel_id);
    }

    if (
      this.props.updated_leads &&
      JSON.stringify(this.props.updated_leads) !==
        JSON.stringify(prevProps.updated_leads)
    ) {
      for (let i = 0; i < this.props.updated_leads.length; i++) {
        if (this.props.updated_leads[i] == this.state.property?.deal?.id) {
          this.props.setUpdatedLeads(
            this.props.updated_leads.filter(
              lead_id => lead_id != this.state.property?.deal?.id
            )
          );
          this.props.reloadLeads({
            token: this.props.token,
            type: "websocket",
            updating_type: "",
            lead_ids: this.state.property?.deal?.id,
            onSuccess: results => {
              if (
                results.properties &&
                results.properties.length == 1 &&
                results.properties[0].id == this.state.property?.id
              ) {
                this.propertyUpdated(results.properties[0]);
              }
            }
          });
        }
      }
    }

    if (
      this.props.updated_properties &&
      JSON.stringify(this.props.updated_properties) !==
        JSON.stringify(prevProps.updated_properties)
    ) {
      for (let i = 0; i < this.props.updated_properties.length; i++) {
        if (
          this.props.updated_properties[i] == this.state.property?.property_id
        ) {
          this.getMoreData(this.state.property, null, true);
        }
      }
    }

    //this check gets updated owner information from a skip trace...I'd love to consolidate this later.
    const property = this.state.property;
    if (
      JSON.stringify(this.props.updated_owner) !==
        JSON.stringify(prevProps.updated_owner) &&
      this.props.updated_owner?.owners?.length > 0 &&
      this.props.updated_owner?.lead_id == property?.deal?.id
    ) {
      const owners = this.props.updated_owner?.owners;

      let did_skip_trace = property?.deal?.did_skip_trace;
      let skip_trace_successful = property?.deal?.skip_trace_successful;
      let skip_trace_mailing_addresses_count = 0;
      let skip_trace_phone_numbers_count = 0;
      let skip_trace_email_addresses_count = 0;

      if (owners?.length > 0) {
        for (let i = 0; i < owners[0].mailing_addresses?.length; i++) {
          if (owners[0].mailing_addresses[i].manual == 0) {
            did_skip_trace = true;
            skip_trace_successful = true;
            skip_trace_mailing_addresses_count++;
          }
        }
        for (let i = 0; i < owners[0].phone_numbers?.length; i++) {
          if (owners[0].phone_numbers[i].manual == 0) {
            did_skip_trace = true;
            skip_trace_successful = true;
            skip_trace_phone_numbers_count++;
          }
        }
        for (let i = 0; i < owners[0].email_addresses?.length; i++) {
          if (owners[0].email_addresses[i].manual == 0) {
            did_skip_trace = true;
            skip_trace_successful = true;
            skip_trace_email_addresses_count++;
          }
        }
      }

      this.propertyUpdated({
        ...property,
        deal: {
          ...property?.deal,
          did_skip_trace,
          skip_trace_successful,
          skip_trace_mailing_addresses_count,
          skip_trace_phone_numbers_count,
          skip_trace_email_addresses_count,
          additional_mailing_addresses: owners[0].mailing_addresses,
          phone_numbers: owners[0].phone_numbers,
          email_addresses: owners[0].email_addresses,
          updating: {
            updating_type: ""
          },
          additional_mailing_addresses_count: owners[0].mailing_addresses
            ? owners[0].mailing_addresses.length
            : null,
          phone_numbers_count: owners[0].phone_numbers
            ? owners[0].phone_numbers.length
            : null,
          email_addresses_count: owners[0].email_addresses
            ? owners[0].email_addresses.length
            : null,

          owner: owners[0]
        }
      });
    }

    if (panel_data?.expanded !== prev_panel_data?.expanded) {
      this.setState({
        expanded: panel_data?.expanded
      });
    } else if (this.props.expanded !== prevProps.expanded) {
      this.setState({
        expanded: this.props.expanded === "expanded" ? true : false
      });
    }

    if (
      panel_data?.property &&
      panel_data?.property?.id !== prev_panel_data?.property?.id &&
      !panel_data?.property?.deal &&
      this.state.tab != "info" &&
      this.state.tab != "comps"
    ) {
      this.changeTab("info");
    }
  }

  setMapDragging(bool) {
    this.setState({
      map_dragging: bool
    });
  }

  updateMainState(updated_state) {
    this.setState(updated_state);
  }

  propertyUpdated(property) {
    this.setState(
      {
        skip_trace_loading: false, //used for the front end loading on the skip trace button
        property: property
          ? {
              ...this.state.property,
              ...property
            }
          : this.state.property
      },
      () => {
        this.props.updateSidePanel({
          index: this.props.panel_index,
          data: {
            property: property
              ? {
                  ...this.state.property,
                  ...property
                }
              : this.state.property
          }
        });
      }
    );
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    if (panel_data.propertyUpdated) {
      panel_data.propertyUpdated(property);
    }
    if (this.props.propertyUpdated) {
      this.props.propertyUpdated(property);
    }
  }

  getMoreData(property = null, property_id = null, no_comps = false) {
    if (property) {
      this.setState({
        property: property
      });
    }

    if (property || property_id) {
      this.props.getPropertyNew({
        token: this.props.token,
        property_id: property ? property?.property_id : property_id,
        lead_id: property ? property?.deal?.id : null,
        onLoading: () => {
          this.setState({
            property_loading: true
          });
        },
        onError: () => {
          this.setState({
            property_loading: false
          });
        },
        onSuccess: results => {
          this.setState(
            {
              property_loading: false,
              property:
                results.property?.id == this.state?.property?.id ||
                results.property?.deal?.id == this.state?.property?.deal?.id ||
                !this.state?.property
                  ? {
                      ...this.state.property,
                      ...results.property
                    }
                  : this.state.property
            },
            () => {
              this.props.updateSidePanel({
                index: this.props.panel_index,
                data: {
                  property:
                    results.property?.id == this.state?.property?.id ||
                    results.property?.deal?.id ==
                      this.state?.property?.deal?.id ||
                    !this.state?.property
                      ? {
                          ...this.state.property,
                          ...results.property
                        }
                      : this.state.property
                }
              });
            }
          );

          if (this.props.onPropertyLoad) {
            this.props.onPropertyLoad(results.property);
          }
        }
      });
    }
  }

  componentWillUnmount() {}

  handleBack() {
    this.props.popSidePanel();
  }

  changeTab(tab) {
    this.setState(
      {
        tab,
        show_comps_panel: tab == "comps" ? this.state.show_comps_panel : false
      },
      () => {
        this.props.updateSidePanel({
          index: this.props.panel_index,
          data: {
            tab
          },
          onSuccess: () => {}
        });
        this.scrollToTabs();

        if (
          (this.isShowingCompsFilters() || this.isShowingExpandedComps()) &&
          tab !== "comps"
        ) {
          this.props.popSidePanel();
        }
      }
    );
  }

  scrollToTabs() {
    const { device } = this.props;
    if (!this.state.expanded) {
      if (
        device == "desktop" &&
        this._scroll_view &&
        this._scroll_view.current
      ) {
        const elmnt = document.getElementById("property_view_top_view");
        this._scroll_view.current.scroll({
          top: elmnt.clientHeight,
          behavior: "smooth"
        });
      } else if (
        device == "mobile" &&
        this._scroll_view &&
        this._scroll_view.current
      ) {
        if (this.state.scrollOffset < this.state.top_view_height) {
          this._scroll_view.current.scrollTo({
            x: 0,
            y: this.state.top_view_height,
            animated: true
          });
        } else {
          setTimeout(() => {
            this._scroll_view.current.scrollTo({
              x: 0,
              y: this.state.top_view_height,
              animated: false
            });
          }, 100);
        }
      }
    }
  }

  scrollToSection(section) {
    const { device } = this.props;

    if (
      device === "desktop" &&
      this._scroll_view &&
      this._scroll_view.current &&
      section?.id &&
      (section?.id !== "owner-information" || !this.state.expanded)
    ) {
      const elmnt = document.getElementById(section?.id);
      let top = this.state.expanded
        ? elmnt.offsetTop - 125
        : elmnt.offsetTop - 200;

      if (section?.id === "owner-information") {
        top = elmnt.offsetTop - 90;
      }
      this._scroll_view.current.scroll({
        top,
        behavior: "smooth"
      });
    } else if (
      device === "mobile" &&
      this._scroll_view &&
      this._scroll_view.current &&
      section?.offset
    ) {
      let y = section.offset + this.state.top_view_height - 7;
      if (section?.id === "owner-information") {
        y = section.offset + this.state.above_owner_height - 15;
      }
      this._scroll_view.current.scrollTo({
        x: 0,
        y: y,
        animated: true
      });
    }
  }

  renderTop() {
    const { colors, user } = this.props;
    const { property, tab } = this.state;

    return (
      <Wrapper
        id="property_view_top_view"
        onLayout={event => {
          const { x, y, width, height } = event?.nativeEvent?.layout;
          this.setState({
            top_view_height: height
          });
        }}
      >
        <Wrapper
          onLayout={event => {
            const { x, y, width, height } = event?.nativeEvent?.layout;
            this.setState({
              above_owner_height: height
            });
          }}
        >
          <Wrapper style={{ position: "relative" }}>
            <Wrapper
              style={{
                height: this.props.isIphoneX
                  ? 95
                  : this.props.platform == "ios"
                  ? 115
                  : 125,
                backgroundColor: "transparent",
                marginBottom: 30
              }}
            />
            <Wrapper
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                left: 0,
                padding: 5,
                opacity: this.state.expanded
                  ? 1 - this.state.expanded_scroll_header_opacity > 0
                    ? 1 - this.state.expanded_scroll_header_opacity
                    : 0
                  : 1 - this.state.scroll_header_opacity > 0
                  ? 1 - this.state.scroll_header_opacity
                  : 0
              }}
            >
              <TopPropertyOptions
                property={property}
                propertyUpdated={this.propertyUpdated}
                handleBack={this.handleBack}
                {...this.props}
              />
            </Wrapper>
          </Wrapper>

          <Wrapper
            style={{
              backgroundColor: colors.card_color
            }}
          >
            <EstimatedValue
              property={property}
              expanded={this.state.expanded}
              property_loading={this.state.property_loading}
            />
          </Wrapper>
        </Wrapper>
        <Wrapper
          style={{
            backgroundColor: colors.card_color
          }}
        >
          <OwnerInfo
            {...this.props}
            property={property}
            updateMainState={this.updateMainState}
            skip_trace_loading={this.state.skip_trace_loading}
            started_skip_trace={this.state.started_skip_trace}
            mail_loading={this.state.mail_loading}
            propertyUpdated={this.propertyUpdated}
            changeTab={this.changeTab}
            expanded={this.state.expanded}
            scrollToSection={this.scrollToSection}
            property_loading={this.state.property_loading}
          />
        </Wrapper>
      </Wrapper>
    );
  }

  renderNav() {
    const { colors, isMobile } = this.props;
    const { property, tab } = this.state;

    const display_property = determineDisplayProperty(property);
    const formatted_property_address = formatAddress({
      address: {
        address: display_property.property_address,
        address2: display_property.property_address2,
        address_city: display_property.property_address_city,
        address_state: display_property.property_address_state,
        address_zip: display_property.property_address_zip,
        latitude: property?.location?.latitude,
        longitude: property?.location?.longitude
      }
    });

    const showing_comps_filters = this.isShowingCompsFilters();
    const showing_expanded_comps = this.isShowingExpandedComps();

    return (
      <NewHeader
        title={formatted_property_address.line1}
        subtitle={formatted_property_address.line2}
        renderTitleComponent={() => {
          return (
            <PopoverMenu
              show={this.state.show_more_property_address}
              no_swipe={true}
              no_cancel={true}
              onShow={s => {
                this.setState({
                  show_more_property_address: s
                });
              }}
              longPress={this.props.device == "mobile" ? true : false}
              popover_width={350}
              style={{ alignItems: "flex-start" }}
              includeCloseButton={true}
              renderComponent={({ hovering, pressed_in }) => {
                return (
                  <>
                    <Title
                      style={{
                        color: colors.white_text_color,
                        textAlign: "left"
                      }}
                    >
                      {formatted_property_address.line1}
                    </Title>
                    <Copy
                      style={{
                        color: colors.white_text_color,
                        fontSize: 12,
                        textAlign: "left"
                      }}
                    >
                      {formatted_property_address.line2}
                    </Copy>
                  </>
                );
              }}
              menu_items={[
                {
                  title: "Share Property Link",
                  type: "normal",
                  select_type: "none",
                  icon: "share",
                  onPress: () => {
                    copyToClipBoard(
                      AppConfig().app_url +
                        "leads#property(id)=" +
                        property.property_id
                    );
                    this.props.showSuccess(
                      formatted_property_address.line1 +
                        ", " +
                        formatted_property_address.line2 +
                        " was copied to your clipboard",
                      "Copied"
                    );
                  }
                },
                {
                  title: "Copy Property Address",
                  type: "normal",
                  select_type: "none",
                  icon: "content-copy",
                  onPress: () => {
                    copyToClipBoard(
                      formatted_property_address.line1 +
                        ", " +
                        formatted_property_address.line2
                    );
                    this.props.showSuccess(
                      formatted_property_address.line1 +
                        ", " +
                        formatted_property_address.line2 +
                        " was copied to your clipboard",
                      "Copied"
                    );
                  }
                },
                {
                  title:
                    this.props.device === "desktop"
                      ? "Open Address In Google Map"
                      : "Open Address In Maps App",
                  type: "normal",
                  select_type: "none",
                  icon: "keyboard-arrow-right",
                  onPress: () => {
                    if (this.props.device === "desktop") {
                      if (!!display_property.property_address) {
                        window.open(
                          `https://www.google.com/maps/place/${display_property.property_address_full}/@=${property?.location?.latitude},${property?.location?.longitude},18z`,
                          "_blank"
                        );
                      } else {
                        window.open(
                          `https://www.google.com/maps?q=${property?.location?.latitude},${property?.location?.longitude}`,
                          "_blank"
                        );
                      }
                    } else {
                      openMaps({
                        latitude: parseFloat(property?.location?.latitude),
                        longitude: parseFloat(property?.location?.longitude),
                        qry: display_property.property_address_full
                          ? display_property.property_address_full
                          : ""
                      });
                    }
                  }
                }
              ]}
            />
          );
        }}
        text_color={colors.white_text_color}
        leftButton={
          !showing_comps_filters &&
          !showing_expanded_comps &&
          !this.props.sharable
            ? {
                icon: this.props.device == "mobile" ? "arrow-back" : "close",
                onPress: this.handleBack
              }
            : null
        }
        renderRight={() => {
          return (
            <Row>
              {property?.deal &&
              !property?.deal?.removed &&
              ((this.state.scroll_header_opacity < 1 && !this.state.expanded) ||
                (this.state.expanded_scroll_header_opacity < 1 &&
                  this.state.expanded)) ? (
                <Wrapper
                  style={{
                    opacity: this.state.expanded
                      ? 1 - this.state.expanded_scroll_header_opacity > 0
                        ? 1 - this.state.expanded_scroll_header_opacity
                        : 0
                      : 1 - this.state.scroll_header_opacity > 0
                      ? 1 - this.state.scroll_header_opacity
                      : 0
                  }}
                >
                  <LeadPhotoManager
                    property={property}
                    propertyUpdated={this.propertyUpdated}
                    renderComponent={({ pressedIn, hovering }) => {
                      return (
                        <HeaderButton
                          buttonContents={{
                            noPress: true,
                            icon: "add-a-photo",
                            pressedIn: pressedIn,
                            hovering: hovering
                          }}
                        />
                      );
                    }}
                  />
                </Wrapper>
              ) : null}

              {!isMobile &&
              !showing_comps_filters &&
              !showing_expanded_comps &&
              !this.props.sharable ? (
                <HeaderButton
                  buttonContents={
                    this.state.expanded
                      ? {
                          icon: "close-fullscreen",
                          onPress: () => {
                            this.setState({
                              scroll_header_opacity: false
                            });
                            this.props.updateUserSettings({
                              token: this.props.token,
                              type: "property_expanded",
                              value: "not_expanded"
                            });
                            this.props.replaceSidePanel({
                              slug: "property",
                              id: property?.property_id,

                              overlay: true,
                              data: {
                                property,
                                expanded: false,
                                tab
                              }
                            });
                          }
                        }
                      : {
                          icon: "open-in-full",
                          onPress: () => {
                            this.setState({
                              scroll_header_opacity: false
                            });

                            this.props.updateUserSettings({
                              token: this.props.token,
                              type: "property_expanded",
                              value: "expanded"
                            });

                            this.props.replaceSidePanel({
                              slug: "property",
                              id: property?.property_id,
                              focus_side_panel: true,
                              overlay: true,
                              data: {
                                property,
                                expanded: true,
                                tab
                              }
                            });
                          }
                        }
                  }
                />
              ) : null}
            </Row>
          );
        }}
        backgroundColor={
          this.state.expanded
            ? this.props.dark_mode == "dark_mode"
              ? "rgba(25, 33, 41, " +
                this.state.expanded_scroll_header_opacity +
                ")"
              : "rgba(49, 204, 229, " +
                this.state.expanded_scroll_header_opacity +
                ")"
            : this.props.dark_mode == "dark_mode"
            ? "rgba(25, 33, 41, " + this.state.scroll_header_opacity + ")"
            : "rgba(49, 204, 229, " + this.state.scroll_header_opacity + ")"
        }
        backgroundColor2={
          this.state.expanded
            ? this.props.dark_mode == "dark_mode"
              ? "rgba(25, 33, 41, " +
                this.state.expanded_scroll_header_opacity +
                ")"
              : "rgba(29, 219, 217, " +
                this.state.expanded_scroll_header_opacity +
                ")"
            : this.props.dark_mode == "dark_mode"
            ? "rgba(25, 33, 41, " + this.state.scroll_header_opacity + ")"
            : "rgba(29, 219, 217, " + this.state.scroll_header_opacity + ")"
        }
      />
    );
  }

  isShowingCompsFilters() {
    let showing_comps_filters = false;
    if (
      this.props.side_panel_views?.length > this.props.panel_index + 1 &&
      this.props.side_panel_views[this.props.panel_index + 1]
    ) {
      if (
        this.props.side_panel_views[this.props.panel_index + 1].slug ==
        "filter_comps"
      ) {
        showing_comps_filters = true;
      }
    }
    return showing_comps_filters;
  }

  isShowingExpandedComps() {
    let showing_expanded_comps = false;
    if (
      this.props.side_panel_views?.length > this.props.panel_index + 1 &&
      this.props.side_panel_views[this.props.panel_index + 1]
    ) {
      if (
        this.props.side_panel_views[this.props.panel_index + 1].slug ==
        "show_comps"
      ) {
        showing_expanded_comps = true;
      }
    }
    return showing_expanded_comps;
  }

  renderInner() {
    const { colors, device, window_height, user, source_of_truth } = this.props;
    const { property, tab } = this.state;

    let minHeight = 0;
    if (device == "desktop") {
      const tabs = document.getElementById("property_view_tabs");
      if (tabs) {
        if (this.state.expanded) {
          minHeight = window_height;
        } else {
          minHeight = window_height - tabs.clientHeight - 75;
        }
      }
    } else {
      minHeight = window_height - this.state.property_view_tabs_height;
      minHeight = this.props.isIphoneX
        ? minHeight - 105
        : this.props.platform == "ios"
        ? minHeight - 85
        : minHeight - 105;
    }

    if (property) {
      return (
        <>
          {!this.state.expanded ? this.renderNav() : null}

          <Scroll
            scroll_ref={this._scroll_view}
            stickyHeaderIndices={[1]}
            scrollEnabled={
              (!this.state.show_comps_panel && !this.state.map_dragging) ||
              this.props.device == "desktop"
            }
            onScroll={e => {
              if (this.props.device == "desktop") {
                this.setState({
                  scroll_header_opacity: this.state.expanded
                    ? 0
                    : this._scroll_view?.current?.scrollTop / 100 > 1
                    ? 1
                    : this._scroll_view?.current?.scrollTop / 100,
                  scrollOffset: this._scroll_view?.current?.scrollTop,
                  show_alma_panel: !this.state.expanded
                    ? this._scroll_view?.current?.scrollTop === 0 &&
                      !this.state.pressed_alma &&
                      !this.props.user?.user_settings?.hide_alma
                      ? "default"
                      : "hide"
                    : this.state.show_alma_panel
                });
              } else {
                this.setState({
                  scroll_header_opacity: this.state.expanded
                    ? 0
                    : e?.nativeEvent?.contentOffset?.y / 100 > 1
                    ? 1
                    : e?.nativeEvent?.contentOffset?.y / 100,
                  scrollOffset: e?.nativeEvent?.contentOffset?.y
                });
              }
            }}
            style={{
              flex: 1
            }}
          >
            {!this.state.expanded ? this.renderTop() : null}
            <Wrapper
              id="property_view_tabs"
              style={
                this.props.device == "desktop"
                  ? {
                      position: "sticky",
                      top: 0,
                      backgroundColor: colors.card_color,
                      zIndex: 1
                    }
                  : {
                      backgroundColor: colors.card_color
                    }
              }
              onLayout={event => {
                const { x, y, width, height } = event?.nativeEvent?.layout;

                this.setState({
                  property_view_tabs_height: height
                });
              }}
            >
              <InlineTabs
                tab_type="underline"
                selected_tab={tab}
                tabs={[
                  {
                    onPress: () => {
                      this.changeTab("info");
                    },
                    slug: "info",
                    title: "Info"
                  },

                  !user || this.props.user?.team_clearance_level > 0
                    ? {
                        onPress: () => {
                          this.changeTab("comps");
                        },

                        slug: "comps",
                        title: "Comps",

                        disabled: property?.property_id
                          ?.toString()
                          .startsWith("1"),
                        renderComponent: ({ key, tab, selected }) => {
                          return (
                            <UpsellButton
                              key={key}
                              slug="comps"
                              type="billing_addon"
                              contentful_slug="unlock_comps"
                              meta_slug="comps"
                              onPress={tab.onPress}
                              disabled={property?.property_id
                                ?.toString()
                                .startsWith("1")}
                              force_unlock={
                                !checkIfUserHasSubscription(
                                  user,
                                  source_of_truth
                                )
                              }
                              renderLockedChildren={({ hovering }) => {
                                return (
                                  <InlineButton
                                    icon={"lock"}
                                    selected={selected}
                                    noPress={true}
                                    pressedIn={selected || hovering}
                                    disabled={tab?.disabled}
                                    pressed_in_background_color={
                                      selected
                                        ? "transparent"
                                        : colors.hover_color
                                    }
                                    tooltip={tab?.tooltip}
                                    tooltipPlacement={tab?.tooltipPlacement}
                                    style={
                                      selected
                                        ? {
                                            borderBottomWidth: 2,
                                            borderBottomColor:
                                              colors.actionable_text_color,
                                            borderBottomStyle: "solid",
                                            borderRadius: 0,
                                            margin: 0
                                          }
                                        : {
                                            borderBottomWidth: 2,
                                            borderBottomColor: "transparent",
                                            borderBottomStyle: "solid",
                                            borderRadius: 0,
                                            margin: 0
                                          }
                                    }
                                  >
                                    {tab?.title}
                                  </InlineButton>
                                );
                              }}
                              renderChildren={({ hovering }) => {
                                return (
                                  <InlineButton
                                    icon={""}
                                    selected={selected}
                                    noPress={true}
                                    pressedIn={selected || hovering}
                                    disabled={tab?.disabled}
                                    pressed_in_background_color={
                                      selected
                                        ? "transparent"
                                        : colors.hover_color
                                    }
                                    tooltip={tab?.tooltip}
                                    tooltipPlacement={tab?.tooltipPlacement}
                                    style={
                                      selected
                                        ? {
                                            borderBottomWidth: 2,
                                            borderBottomColor:
                                              colors.actionable_text_color,
                                            borderBottomStyle: "solid",
                                            borderRadius: 0,
                                            margin: 0
                                          }
                                        : {
                                            borderBottomWidth: 2,
                                            borderBottomColor: "transparent",
                                            borderBottomStyle: "solid",
                                            borderRadius: 0,
                                            margin: 0
                                          }
                                    }
                                  >
                                    {tab?.title}
                                  </InlineButton>
                                );
                              }}
                            />
                          );
                        }
                      }
                    : null,
                  /*!user || this.props.user?.team_clearance_level > 0
                    ? {
                        onPress: () => {
                          this.changeTab("calculators");
                        },
                        slug: "calculators",
                        title: "Calculators",
                        disabled: property?.property_id
                          ?.toString()
                          .startsWith("1"),
                        renderComponent: ({ key, tab, selected }) => {
                          return (
                            <UpsellButton
                              key={key}
                              slug="comps"
                              type="billing_addon"
                              contentful_slug="unlock_comps"
                              meta_slug="comps"
                              onPress={tab.onPress}
                              force_unlock={
                                !checkIfUserHasSubscription(
                                  user,
                                  source_of_truth
                                )
                              }
                              renderLockedChildren={({ hovering }) => {
                                return (
                                  <InlineButton
                                    icon={"lock"}
                                    selected={selected}
                                    noPress={true}
                                    pressedIn={selected || hovering}
                                    disabled={tab?.disabled}
                                    pressed_in_background_color={
                                      selected
                                        ? "transparent"
                                        : colors.hover_color
                                    }
                                    tooltip={tab?.tooltip}
                                    tooltipPlacement={tab?.tooltipPlacement}
                                    style={
                                      selected
                                        ? {
                                            borderBottomWidth: 2,
                                            borderBottomColor:
                                              colors.actionable_text_color,
                                            borderBottomStyle: "solid",
                                            borderRadius: 0,
                                            margin: 0
                                          }
                                        : {
                                            borderBottomWidth: 2,
                                            borderBottomColor: "transparent",
                                            borderBottomStyle: "solid",
                                            borderRadius: 0,
                                            margin: 0
                                          }
                                    }
                                  >
                                    {tab?.title}
                                  </InlineButton>
                                );
                              }}
                              renderChildren={({ hovering }) => {
                                return (
                                  <InlineButton
                                    icon={""}
                                    selected={selected}
                                    noPress={true}
                                    pressedIn={selected || hovering}
                                    disabled={tab?.disabled}
                                    pressed_in_background_color={
                                      selected
                                        ? "transparent"
                                        : colors.hover_color
                                    }
                                    tooltip={tab?.tooltip}
                                    tooltipPlacement={tab?.tooltipPlacement}
                                    style={
                                      selected
                                        ? {
                                            borderBottomWidth: 2,
                                            borderBottomColor:
                                              colors.actionable_text_color,
                                            borderBottomStyle: "solid",
                                            borderRadius: 0,
                                            margin: 0
                                          }
                                        : {
                                            borderBottomWidth: 2,
                                            borderBottomColor: "transparent",
                                            borderBottomStyle: "solid",
                                            borderRadius: 0,
                                            margin: 0
                                          }
                                    }
                                  >
                                    {tab?.title}
                                  </InlineButton>
                                );
                              }}
                            />
                          );
                        }
                      }
                    : null,*/
                  /*
                  this.props.user?.team_clearance_level > 0
                    ? {
                        onPress: () => {
                          this.changeTab("dispo");
                        },

                        slug: "dispo",
                        title: "Disposition",
                        disabled: property?.property_id
                          ?.toString()
                          .startsWith("1"),
                        renderComponent: ({ key, tab, selected }) => {
                          return (
                            <UpsellButton
                              key={key}
                              slug="comps"
                              type="billing_addon"
                              contentful_slug="unlock_comps"
                              meta_slug="comps"
                              onPress={tab.onPress}
                              force_unlock={
                                !checkIfUserHasSubscription(
                                  user,
                                  source_of_truth
                                )
                              }
                              renderLockedChildren={({ hovering }) => {
                                return (
                                  <InlineButton
                                    icon={"lock"}
                                    selected={selected}
                                    noPress={true}
                                    pressedIn={selected || hovering}
                                    disabled={tab?.disabled}
                                    pressed_in_background_color={
                                      selected
                                        ? "transparent"
                                        : colors.hover_color
                                    }
                                    tooltip={tab?.tooltip}
                                    tooltipPlacement={tab?.tooltipPlacement}
                                    style={
                                      selected
                                        ? {
                                            borderBottomWidth: 2,
                                            borderBottomColor:
                                              colors.actionable_text_color,
                                            borderBottomStyle: "solid",
                                            borderRadius: 0,
                                            margin: 0
                                          }
                                        : {
                                            borderBottomWidth: 2,
                                            borderBottomColor: "transparent",
                                            borderBottomStyle: "solid",
                                            borderRadius: 0,
                                            margin: 0
                                          }
                                    }
                                  >
                                    {tab?.title}
                                  </InlineButton>
                                );
                              }}
                              renderChildren={({ hovering }) => {
                                return (
                                  <InlineButton
                                    icon={""}
                                    selected={selected}
                                    noPress={true}
                                    pressedIn={selected || hovering}
                                    disabled={tab?.disabled}
                                    pressed_in_background_color={
                                      selected
                                        ? "transparent"
                                        : colors.hover_color
                                    }
                                    tooltip={tab?.tooltip}
                                    tooltipPlacement={tab?.tooltipPlacement}
                                    style={
                                      selected
                                        ? {
                                            borderBottomWidth: 2,
                                            borderBottomColor:
                                              colors.actionable_text_color,
                                            borderBottomStyle: "solid",
                                            borderRadius: 0,
                                            margin: 0
                                          }
                                        : {
                                            borderBottomWidth: 2,
                                            borderBottomColor: "transparent",
                                            borderBottomStyle: "solid",
                                            borderRadius: 0,
                                            margin: 0
                                          }
                                    }
                                  >
                                    {tab?.title}
                                  </InlineButton>
                                );
                              }}
                            />
                          );
                        }
                      }
                    : null,
                    */
                  this.props.user?.team_clearance_level > 0
                    ? {
                        onPress: () => {
                          this.changeTab("mail");
                        },
                        slug: "mail",
                        title: "Mail",
                        disabled: !property?.deal
                      }
                    : null,
                  {
                    onPress: () => {
                      this.changeTab("activity");
                    },

                    slug: "activity",
                    title: "Activity",
                    disabled: !property?.deal
                  }
                ]}
              />
              {tab === "info" ? (
                <Wrapper
                  style={
                    this.props.device == "desktop"
                      ? {
                          position: "sticky",
                          top: 50,
                          backgroundColor: colors.card_color,
                          zIndex: 1
                        }
                      : { backgroundColor: colors.card_color }
                  }
                >
                  <SearchBar
                    title="Search Information"
                    value={
                      !!this.state.info_search ? this.state.info_search : ""
                    }
                    onChange={value => {
                      this.setState({
                        info_search: value
                      });
                    }}
                    onFocus={() => {
                      this.setState(
                        {
                          info_focus: true
                        },
                        () => {
                          this.scrollToTabs();
                        }
                      );
                    }}
                    onBlur={() => {
                      this.setState({
                        info_focus: false
                      });
                    }}
                    style={{
                      margin: 10,
                      marginRight: !!property?.mls?.listing_number ? 0 : 10,
                      borderBottomWidth: 0,
                      borderWidth: 0,
                      backgroundColor: colors.background_color,
                      flex: 1
                    }}
                  />
                </Wrapper>
              ) : tab === "activity" ? (
                <Wrapper
                  style={
                    this.props.device == "desktop"
                      ? {
                          position: "sticky",
                          top: 50,
                          backgroundColor: colors.card_color,
                          zIndex: 1
                        }
                      : {
                          backgroundColor: colors.card_color
                        }
                  }
                >
                  <Row>
                    <Wrapper style={{ flex: 1 }}>
                      <SearchBar
                        title="Search Activity"
                        value={
                          !!this.state.activity_search
                            ? this.state.activity_search
                            : ""
                        }
                        onChange={value => {
                          this.setState({
                            activity_search: value
                          });
                        }}
                        onFocus={() => {
                          this.setState(
                            {
                              activity_focus: true
                            },
                            () => {
                              this.scrollToTabs();
                            }
                          );
                        }}
                        onBlur={() => {
                          this.setState({
                            activity_focus: false
                          });
                        }}
                        style={{
                          margin: 10,
                          marginRight: 0,
                          borderBottomWidth: 0,
                          borderWidth: 0,
                          backgroundColor: colors.background_color
                        }}
                      />
                    </Wrapper>
                    <PopoverMenu
                      show={this.state.show_add_more}
                      no_swipe={true}
                      no_cancel={true}
                      onShow={s => {
                        this.setState({
                          show_add_more: s
                        });
                      }}
                      popover_width={350}
                      popoverPlacement="bottom"
                      includeCloseButton={true}
                      renderComponent={({ hovering, pressed_in }) => {
                        return (
                          <InlineButton
                            primary={true}
                            noPress
                            pressedIn={
                              hovering || pressed_in || this.state.show_add_more
                            }
                            icon="add"
                          >
                            Add
                          </InlineButton>
                        );
                      }}
                      menu_items={[
                        {
                          title: "Add Note",
                          type: "normal",
                          select_type: "icon",
                          icon: "notes",
                          onPress: () => {
                            this.props.pushSidePanel({
                              slug: "add_note",
                              overlay: true,
                              data: {
                                property,
                                onSuccess: results => {
                                  if (results.lead_activity) {
                                    this.setState({
                                      activity: results.lead_activity
                                    });
                                  }
                                }
                              }
                            });
                          }
                        },
                        user?.team_clearance_level > 0
                          ? {
                              title: "Add Task",
                              type: "normal",
                              select_type: "icon",
                              icon: "add-task",
                              onPress: () => {
                                this.props.clearTaskList();
                                this.props.pushSidePanel({
                                  slug: "create_task",
                                  overlay: true,
                                  data: {
                                    property,
                                    onSuccess: results => {
                                      if (results.lead_activity) {
                                        this.setState({
                                          activity: results.lead_activity
                                        });
                                      }
                                    }
                                  }
                                });
                              }
                            }
                          : null
                      ]}
                    />
                  </Row>
                </Wrapper>
              ) : null}
            </Wrapper>

            {tab === "info" ? (
              <Wrapper
                style={{ minHeight, backgroundColor: colors.card_color }}
              >
                {property?.id < 0 ? (
                  <ConfirmLabel
                    label={"Unmatched Lead"}
                    description="This lead was not matched with a property in our database. This can happen when leads are manually added to your account. It will not be updated with fresh data until it is linked to a property. "
                    icon="sync-disabled"
                    confirmButton={"Match Property"}
                    primary={true}
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "link_lead_with_data",
                        overlay: true,
                        data: {
                          property,
                          propertyUpdated: this.propertyUpdated
                        }
                      });
                    }}
                  />
                ) : null}

                <PropertyInfoNew
                  {...this.props}
                  expanded={this.state.expanded}
                  property={property}
                  search={this.state.info_search}
                  scrollToSection={this.scrollToSection}
                  include_custom_fields={true}
                />
              </Wrapper>
            ) : null}

            {tab === "mail" && user?.team_clearance_level > 0 ? (
              <Wrapper
                style={{ minHeight, backgroundColor: colors.card_color }}
              >
                <Mail
                  {...this.props}
                  property={property}
                  propertyUpdated={this.propertyUpdated}
                  mail_loading={this.state.mail_loading}
                  updateMainState={this.updateMainState}
                />
              </Wrapper>
            ) : null}
            {tab === "comps" &&
            (!this.props.user || this.props.user?.team_clearance_level > 0) ? (
              <Wrapper
                style={{ minHeight, backgroundColor: colors.card_color }}
              >
                <Comps
                  {...this.props}
                  setMapDragging={this.setMapDragging}
                  panel_index={this.state.panel_index}
                  property={property}
                  height={minHeight}
                  scrollOffset={this.state.scrollOffset}
                  showing_comps_filters={this.isShowingCompsFilters()}
                  showing_expanded_comps={this.isShowingExpandedComps()}
                  expanded={this.state.expanded}
                  updateMainState={this.updateMainState}
                  show_comps_panel={this.state.show_comps_panel}
                />
              </Wrapper>
            ) : null}

            {tab === "dispo" ? (
              <Wrapper
                style={{ minHeight, backgroundColor: colors.card_color }}
              >
                <DispoTab property={property} />
              </Wrapper>
            ) : null}

            {tab === "activity" ? (
              <Wrapper
                style={{ minHeight, backgroundColor: colors.card_color }}
              >
                <Activity
                  property={property}
                  search={this.state.activity_search}
                  scrollToTabs={this.scrollToTabs}
                  activity={this.state.activity}
                  lead_activity_loading={this.state.lead_activity_loading}
                  lead_activity_loading_id={this.state.lead_activity_loading_id}
                  updateMainState={this.updateMainState}
                />
              </Wrapper>
            ) : null}

            <Wrapper
              style={{
                height: this.props.keyboard_showing
                  ? 400
                  : this.state.tab !== "comps"
                  ? 50
                  : 0
              }}
            />
          </Scroll>
        </>
      );
    } else if (this.state.property_loading) {
      return (
        <>
          <SpinWrapper text="Loading Property" />
        </>
      );
    }
    return null;
  }

  render() {
    const { colors } = this.props;
    const { property } = this.state;

    if (property && property?.property_id) {
      if (this.state.expanded) {
        return (
          <Container
            style={{
              display: "flex",
              alignSelf: "stretch",
              backgroundColor: colors.card_color,
              position: "relative"
            }}
          >
            <Row style={{ alignSelf: "stretch", flex: 1, overflow: "hidden" }}>
              <Wrapper
                style={{
                  width: 450,
                  alignSelf: "stretch",
                  position: "relative"
                }}
              >
                <Wrapper
                  style={{
                    position: "relative"
                  }}
                >
                  <LeadMainPhoto
                    property={property}
                    height={200}
                    style={{
                      margin: 0,
                      borderRadius: 0,
                      backgroundColor: colors.gray_color
                    }}
                    imageStyle={{ borderRadius: 0 }}
                  />
                  <Wrapper
                    style={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                      backgroundColor: "rgba(0,0,0,0.3)"
                    }}
                  />
                </Wrapper>
                <Wrapper
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0
                  }}
                >
                  {this.renderNav()}
                  <Scroll
                    scroll_ref={this._expanded_scroll_view}
                    scrollEnabled={true}
                    onScroll={e => {
                      this.setState({
                        expanded_scroll_header_opacity:
                          this._expanded_scroll_view?.current?.scrollTop / 100,
                        expandedScrollOffset:
                          this._expanded_scroll_view?.current?.scrollTop
                      });
                    }}
                    style={{
                      flex: 1
                    }}
                  >
                    {this.renderTop()}
                    <Wrapper style={{ height: 80 }} />
                  </Scroll>
                </Wrapper>
                {this.props.user?.team_clearance_level > 0 ? (
                  <Alma
                    updateMainState={this.updateMainState}
                    show_alma_panel={this.state.show_alma_panel}
                    property={property}
                    propertyUpdated={this.propertyUpdated}
                    expanded={this.state.expanded}
                  />
                ) : null}
              </Wrapper>
              <Wrapper
                className="shadow-expanded-tabs-area"
                style={{
                  flex: 1,
                  alignSelf: "stretch",
                  position: "relative"
                }}
              >
                {this.renderInner()}
              </Wrapper>
            </Row>
          </Container>
        );
      }
      return (
        <Container
          style={{
            display: "flex",
            alignSelf: "stretch",
            backgroundColor: this.props.colors.card_color,
            position: "relative"
          }}
        >
          <Wrapper
            style={{
              position: "relative"
            }}
          >
            <LeadMainPhoto
              property={property}
              height={200}
              style={{
                margin: 0,
                borderRadius: 0,
                backgroundColor: colors.gray_color
              }}
              imageStyle={{ borderRadius: 0 }}
            />
            <Wrapper
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                backgroundColor: "rgba(0,0,0,0.3)"
              }}
            />
          </Wrapper>
          <Wrapper
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0
            }}
          >
            {this.renderInner()}
          </Wrapper>
          {this.state.tab === "info" &&
          this.props.user?.team_clearance_level > 0 ? (
            <Alma
              updateMainState={this.updateMainState}
              show_alma_panel={this.state.show_alma_panel}
              property={property}
              propertyUpdated={this.propertyUpdated}
              expanded={this.state.expanded}
            />
          ) : null}
        </Container>
      );
    } else if (this.state.property_loading) {
      return (
        <Wrapper
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <SpinWrapper text="Loading Property" />
        </Wrapper>
      );
    }
    return null;
  }
}

const mapStateToProps = ({
  auth,
  settings,
  native,
  lead,
  modal,
  onboarding,
  billing,
  alma
}) => {
  const { token, user } = auth;
  const { colors, dark_mode } = settings;
  const {
    device,
    platform,
    isMobile,
    isIphoneX,
    window_height,
    keyboard_showing
  } = native;
  const { updated_leads, updated_properties, updated_owner, comps_settings } =
    lead;
  const { side_panel_views } = modal;
  const { source_of_truth, all_feature_metadata } = billing;
  const { dismissed_alma } = alma;
  return {
    token,
    user,
    colors,
    device,
    platform,
    isMobile,
    isIphoneX,
    dark_mode,
    window_height,
    updated_leads,
    updated_properties,
    updated_owner,
    side_panel_views,
    onboarding,
    source_of_truth,
    all_feature_metadata,
    dismissed_alma,
    keyboard_showing,
    comps_settings
  };
};

export default connect(mapStateToProps, {
  getPropertyNew,
  popSidePanel,
  pushSidePanel,
  replaceSidePanel,
  updateSidePanel,
  updateLead,
  updateOwnerInfo,
  reloadLeads,
  clearTaskList,
  setStartMailerCampaignPage,
  showSuccess,
  nextOnboardingStep,
  setOnboardingLayout,
  setModal,
  toggleModal,
  getComps,
  updateUserSettings,
  setUpdatedLeads
})(Property);
