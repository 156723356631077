import React, { Component } from "react";
import { connect } from "react-redux";
import { Copy, Bold, Row, Wrapper, Icon } from "app/NativeComponents/common";

import {
  CircleButton,
  IconButton,
  List,
  PopoverMenu,
  SelectItem,
  SpinWrapper
} from "app/NativeComponents/snippets";
import {
  formatUsPhone,
  formatAddress,
  getCallSession,
  updateCallSession,
  setActiveDialerContact,
  pushSidePanel
} from "app/NativeActions";
class QueuedItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      queued_numbers: [],
      queued_numbers_loading: false,
      queued_numbers_refreshing: false,
      queued_numbers_loaded_all: false,
      queued_numbers_limit: 25,
      queued_numbers_begin: 0
    };

    this.removeDuplicateNumbers = this.removeDuplicateNumbers.bind(this);

    this.removeItemFromQueue = this.removeItemFromQueue.bind(this);
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidMount() {
    this.getData({ load_type: "load" });
  }

  removeDuplicateNumbers(numbers) {
    const { called_numbers } = this.props;

    return numbers.filter(number => {
      return !called_numbers.find(called_number => {
        return called_number.number === number.number;
      });
    });
  }

  getData({ load_type = "load" }) {
    if (
      !this.state.queued_numbers_loading &&
      !this.state.queued_numbers_refreshing
    ) {
      this.setState(
        {
          queued_numbers_begin:
            load_type === "load" || load_type === "refresh"
              ? 0
              : this.state.queued_numbers_begin +
                this.state.queued_numbers_limit,
          queued_numbers:
            load_type === "refresh" ? [] : this.state.queued_numbers,
          queued_numbers_loading: true,
          queued_numbers_refreshing: load_type === "refresh"
        },
        () => {
          this.props.getCallSession({
            token: this.props.token,
            call_session_id: this.props.call_session?.id,
            limit: this.state.queued_numbers_limit,
            begin: this.state.queued_numbers_begin,
            type: "get_call_session_queued_numbers",
            onLoading: () => {
              this.setState({ queued_numbers_loading: true });
            },
            onError: () => {
              this.setState({
                queued_numbers_loading: false,
                queued_numbers_refreshing: false
              });
            },
            onSuccess: results => {
              if (results?.queued_numbers) {
                let queued_numbers = results?.queued_numbers;

                this.setState({
                  queued_numbers_loading: false,
                  queued_numbers_refreshing: false,
                  queued_numbers:
                    load_type === "load_more"
                      ? this.removeDuplicateNumbers([
                          ...this.state.queued_numbers,
                          ...queued_numbers
                        ])
                      : this.removeDuplicateNumbers(queued_numbers),
                  queued_numbers_loaded_all:
                    results.queued_numbers.length <
                    this.state.queued_numbers_limit
                });
              }
            }
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.current_call_session &&
      this.props.current_call_session?.id == this.props.call_session?.id &&
      JSON.stringify(this.props.called_numbers) !==
        JSON.stringify(prevProps.called_numbers)
    ) {
      this.getData({ load_type: "refresh" });
    }

    if (
      JSON.stringify(this.props.user?.user_settings?.dialer_settings) !==
      JSON.stringify(prevProps.user?.user_settings?.dialer_settings)
    ) {
      this.getData({ load_type: "refresh" });
    }
  }

  componentWillUnmount() {}

  removeItemFromQueue(number) {
    //remove the item from the this.state.follow_up_queue where the id of the item matches the item_id and update the state
    this.setState(
      {
        queued_numbers: this.state.queued_numbers.filter(
          item => item.number != number
        )
      },
      () => {}
    );
  }

  render() {
    const { colors } = this.props;
    const {
      queued_numbers,
      queued_numbers_loading,
      queue_items_refreshing,
      queued_numbers_loaded_all
    } = this.state;

    return (
      <>
        {(queued_numbers_loading && queued_numbers.length === 0) ||
        queue_items_refreshing ? (
          <SpinWrapper text={"Loading Queue"} />
        ) : queued_numbers.length === 0 ? (
          <Wrapper
            style={{
              padding: 25,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Copy>There are no numbers in your queue.</Copy>
          </Wrapper>
        ) : (
          <List
            rowNumber={1}
            items={this.removeDuplicateNumbers(queued_numbers)}
            infiniteScroll={true}
            itemStructure={({ item, index }) => {
              return (
                <SelectItem
                  noPress={true}
                  select_type={"none"}
                  key={"queued_numbers_" + index}
                  renderRight={() => {
                    return (
                      <Row>
                        {(this.props.user?.team_owner == 1 ||
                          this.props.user?.can_send_message == 1) &&
                        this.props.device !== "mobile" ? (
                          <CircleButton
                            icon={"phone"}
                            tooltip={"Call Now"}
                            onPress={() => {
                              this.props.setActiveDialerContact({
                                contact: {
                                  ...item.contact,
                                  selected_phone: {
                                    number: item.number,
                                    type: item.type,
                                    do_not_call_flag: item.do_not_call_flag,
                                    owner: item.owner
                                  },
                                  associated_lead: item.property,
                                  follow_up_queue: true
                                }
                              });

                              //remove from queue...
                              this.removeItemFromQueue(item.number);
                            }}
                          />
                        ) : null}

                        <PopoverMenu
                          show={false}
                          no_swipe={true}
                          no_cancel={true}
                          onShow={s => {}}
                          popover_width={350}
                          popoverPlacement={"top"}
                          includeCloseButton={true}
                          renderComponent={options => {
                            return (
                              <IconButton
                                icon={"more-vert"}
                                noPress={true}
                                pressedIn={
                                  options.hovering ||
                                  options.pressedIn ||
                                  this.state.show_team_filter
                                }
                              />
                            );
                          }}
                          menu_items={[
                            {
                              title: "View Property",
                              onPress: () => {
                                this.props.pushSidePanel({
                                  slug: "property",
                                  id: item?.property?.property_id,
                                  focus_side_panel: true,
                                  data: {
                                    property: item?.property
                                  }
                                });
                              },
                              select_type: "none",
                              icon: "keyboard-arrow-right"
                            },
                            {
                              title: "View Contact",
                              onPress: () => {
                                this.props.pushSidePanel({
                                  slug: "contact",
                                  id: item?.contact?.individual_key,
                                  data: {
                                    person: item?.contact
                                  }
                                });
                              },
                              select_type: "none",
                              icon: "keyboard-arrow-right"
                            },
                            {
                              title: "Remove from queue",
                              onPress: () => {
                                this.props.updateCallSession({
                                  token: this.props.token,
                                  type: "skip_call_session_item",
                                  call_session_id:
                                    this.props.current_call_session?.id,
                                  to_phone_number: item.number,
                                  onLoading: () => {
                                    this.removeItemFromQueue(item.number);
                                  }
                                });
                              },
                              select_type: "none",
                              icon: "delete"
                            }
                          ]}
                        />
                      </Row>
                    );
                  }}
                  renderContent={() => {
                    const property = item?.property;
                    const formatted_address = formatAddress({
                      address: {
                        address: property?.property_address,
                        address2: property?.property_address2,
                        address_city: property?.property_address_city,
                        address_state: property?.property_address_state,
                        address_zip: property?.property_address_zip,
                        latitude: property?.location?.latitude,
                        longitude: property?.location?.longitude
                      }
                    });
                    return (
                      <Wrapper>
                        <Row>
                          <Wrapper style={{ marginRight: 10 }}>
                            <Copy style={{ marginBottom: 5 }}>
                              <Bold>{item?.contact?.full_name}</Bold>
                            </Copy>
                            <Row>
                              <Copy>{formatUsPhone(item?.number)}</Copy>
                              {item?.type === "W" ? (
                                <Wrapper
                                  tooltip={"Wireless Phone"}
                                  tooltipPlacement={"top"}
                                  style={{ marginLeft: 5 }}
                                >
                                  <Icon icon={"mobile-friendly"} size={14} />
                                </Wrapper>
                              ) : (
                                <Wrapper
                                  tooltip={"Landline"}
                                  tooltipPlacement={"top"}
                                  style={{ marginLeft: 5 }}
                                >
                                  <Icon icon={"mobile-off"} size={14} />
                                </Wrapper>
                              )}

                              {item?.do_not_call_flag == 1 ? (
                                <Wrapper
                                  noPress={true}
                                  style={{
                                    backgroundColor:
                                      this.props.colors.orange_color_muted,
                                    borderRadius: 5,
                                    padding: 0,
                                    paddingRight: 5,
                                    paddingLeft: 5,
                                    marginLeft: 5
                                  }}
                                  tooltip={
                                    "Please note: The displayed phone number is on the National Do Not Call Registry. Calling this number for unsolicited sales or promotions may violate telemarketing rules. Proceed at your own risk and ensure compliance with all relevant regulations."
                                  }
                                  tooltipPlacement="top"
                                >
                                  <Row>
                                    <Copy style={{ fontSize: 10 }}>DNC</Copy>
                                  </Row>
                                </Wrapper>
                              ) : null}
                            </Row>
                          </Wrapper>
                        </Row>

                        {item?.property ? (
                          <Copy
                            style={{
                              fontSize: 12,
                              color: colors.light_text_color
                            }}
                          >
                            Calling about: {formatted_address?.line1}
                          </Copy>
                        ) : null}
                      </Wrapper>
                    );
                  }}
                />
              );
            }}
            canRefresh={false}
            onRefresh={() => {}}
            is_refreshing={false}
            canLoadMore={
              !queued_numbers_loaded_all &&
              !queued_numbers_loading &&
              queued_numbers.length > 0
            }
            isLoadingMore={queued_numbers_loading && queued_numbers.length > 0}
            onLoadMore={() => {
              if (
                !queued_numbers_loaded_all &&
                queued_numbers_loading !== true
              ) {
                this.getData({ load_type: "load_more" });
              }
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, dialer }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;
  const { current_call_session, queued_numbers, called_numbers } = dialer;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform,
    current_call_session,
    queued_numbers,
    called_numbers
  };
};

export default connect(mapStateToProps, {
  getCallSession,
  updateCallSession,
  setActiveDialerContact,
  pushSidePanel
})(QueuedItems);
