import React, { Component } from "react";
import { connect } from "react-redux";

import ProgressBarsInner from "app/DealMachineCore/components/ProgressBarsInner";

class ProgressBars extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    if (
      this.props.progress_bars &&
      this.props.progress_bars.length > 0 &&
      !!this.props.window_width &&
      !!this.props.window_height
    ) {
      return <ProgressBarsInner />;
    }
    return null;
  }
}

const mapStateToProps = ({ native, modal }) => {
  const { progress_bars } = modal;
  const { window_width, window_height } = native;

  return {
    progress_bars,
    window_width,
    window_height
  };
};

export default connect(mapStateToProps, {})(ProgressBars);
