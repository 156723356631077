import {
  ERROR_MESSAGE,
  IS_LOADING,
  RESET_ERROR_MESSAGE,
  RESET_SUCCESS_MESSAGE,
  SET_EDIT_MODAL,
  SET_LOADING_MESSAGE,
  SET_MODAL,
  UPDATE_MODAL,
  SET_NOTIFICATION,
  SET_PLAN_MODAL,
  SET_TRACKING_EVENT,
  SHOW_NOTIFICATION,
  START_MODAL_FADE,
  SUCCESS_MESSAGE,
  TOGGLE_DEAL_CREDIT_MODAL,
  TOGGLE_MODAL,
  TOGGLE_PLAN_MODAL,
  TOGGLE_REDIRECT,
  TRIGGER_LOGOUT,
  UNDO_ACTION,
  UNDO_ACTION_FAIL,
  UPDATE_LEAD_SUCCESS,
  SET_UPSELL_MODAL,
  TOGGLE_UPSELL_MODAL,
  SET_ACTIVE_POPOVER,
  ADD_PROGRESS_BAR,
  UPDATE_PROGRESS_BAR,
  REMOVE_PROGRESS_BAR,
  COMPLETE_PROGRESS_BAR
} from "app/DealMachineCore/types";

import APIV2 from "app/DealMachineCore/apis/DealMachineAPIV2";

const apiv2 = APIV2.create();

export const setUpsellModal = ({ type, slug, tier, contentful_slug }) => {
  return {
    type: SET_UPSELL_MODAL,
    payload: { type, slug, tier, contentful_slug }
  };
};

export const setActivePopover = popover => {
  return {
    type: SET_ACTIVE_POPOVER,
    payload: popover
  };
};

export const toggleUpsellModal = toggle => {
  return {
    type: TOGGLE_UPSELL_MODAL,
    payload: toggle
  };
};

export const toggleModal = ({ show, type }) => {
  return {
    type: TOGGLE_MODAL,
    payload: { show, type }
  };
};

export const toggleDealCreditModal = toggle => {
  return {
    type: TOGGLE_DEAL_CREDIT_MODAL,
    payload: toggle
  };
};

export const startFadeOut = () => {
  return {
    type: START_MODAL_FADE
  };
};
export const updateModal = updates => {
  return {
    type: UPDATE_MODAL,
    payload: updates
  };
};

export const setModal = ({
  title,
  description,
  icon,
  image = "",
  onPress,
  submit,
  secondary,
  secondaryPress,
  buttonType,
  buttonText,
  buttonPress,
  onCancel,
  cancel,
  require_complete_function = false
}) => {
  return {
    type: SET_MODAL,
    payload: {
      title,
      description,
      icon,
      image,
      onPress,
      submit,
      secondary,
      secondaryPress,
      buttonType,
      buttonText,
      buttonPress,
      cancel,
      onCancel,
      require_complete_function
    }
  };
};

export const showNotification = toggle => {
  return {
    type: SHOW_NOTIFICATION,
    payload: toggle
  };
};

export const setNotification = ({
  type,
  title,
  subtitle,
  text,
  onPress,
  image,
  email
}) => {
  return {
    type: SET_NOTIFICATION,
    payload: { type, title, subtitle, text, onPress, image, email }
  };
};

export const setPlanModal = ({ plan }) => {
  return {
    type: SET_PLAN_MODAL,
    payload: { plan }
  };
};

export const togglePlanModal = toggle => {
  return {
    type: TOGGLE_PLAN_MODAL,
    payload: toggle
  };
};
export const resetSuccessMessage = () => {
  return {
    type: RESET_SUCCESS_MESSAGE
  };
};

export const showSuccess = (message, title) => {
  return {
    type: SUCCESS_MESSAGE,
    payload: { message, title }
  };
};

export const resetErrorMessage = () => {
  return {
    type: RESET_ERROR_MESSAGE
  };
};

export const showErrorMessage = (message, title) => {
  return {
    type: ERROR_MESSAGE,
    payload: { message, title }
  };
};

export const setLoadingMessage = (message, title) => {
  return {
    type: SET_LOADING_MESSAGE,
    payload: { message, title }
  };
};

export const isLoading = toggle => {
  return {
    type: IS_LOADING,
    payload: toggle
  };
};

export const toggleRedirect = ({ type, data }) => {
  return {
    type: TOGGLE_REDIRECT,
    payload: { type, data }
  };
};
export const undoAction = ({ token, change_group_id }) => {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });
    dispatch({ type: UNDO_ACTION });

    apiv2.undoAction(token, change_group_id).then(response => {
      if (response.problem != null) {
        undoActionFail(dispatch, response.problem);
      } else if (response.data.error != false) {
        undoActionFail(dispatch, response.data.error);
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        undoActionSuccess(dispatch, response.data.results);
      }
    });
  };
};

const undoActionFail = (dispatch, error) => {
  dispatch({ type: UNDO_ACTION_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const undoActionSuccess = (dispatch, results) => {
  dispatch({
    type: SUCCESS_MESSAGE,
    payload: {
      message: "Undo successful.",
      title: "Success!",
      change_log: null
    }
  });

  dispatch({
    type: SET_TRACKING_EVENT,
    payload: "undoSuccess_" + results.item_type
  });

  //what type of action what it? Update redux accordingly
  if (results.item_type) {
    switch (results.item_type) {
      case "houses":
        dispatch({
          type: UPDATE_LEAD_SUCCESS,
          payload: { properties: results.properties }
        });

        break;
      default:
        break;
    }
  }
};

export const setEditModal = ({
  title,
  description,
  slug,
  type,
  fields,
  save_button_text,
  modalAction = null,
  cancelAction = null,
  fieldsUpdated = null,
  always_save = false,
  can_remove = false,
  remove_button_text,
  remove_button_description,
  removeAction = null,
  popoverTarget = null,
  popoverPlacement = null
}) => {
  return {
    type: SET_EDIT_MODAL,
    payload: {
      title,
      description,

      slug,
      type,

      fields,
      save_button_text,

      always_save,
      can_remove,
      remove_button_text,
      remove_button_description,
      removeAction,

      modalAction,
      cancelAction,
      fieldsUpdated,

      popoverTarget,
      popoverPlacement
    }
  };
};

export const searchDealMachine = ({
  token,
  search,
  type,
  address_range,
  address_predir,
  address_name,
  address_suffix,
  address_postdir,
  address_suite_name,
  address_suite_range,
  city,
  state,
  zip,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    apiv2
      .searchDealMachine({
        token,
        search,
        type,
        address_range,
        address_predir,
        address_name,
        address_suffix,
        address_postdir,
        address_suite_name,
        address_suite_range,
        city,
        state,
        zip
      })
      .then(response => {
        if (response.problem != null) {
          onError();

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response.data.error != false) {
          onError();

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.data.error, title: "Error" }
          });
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const addProgressBar = progress_bar => {
  return {
    type: ADD_PROGRESS_BAR,
    payload: progress_bar
  };
};

export const updateProgressBar = ({ id, progress }) => {
  return {
    type: UPDATE_PROGRESS_BAR,
    payload: { id, progress }
  };
};

export const completeProgressBar = ({ progress_bar_id, progress_bar_data }) => {
  return {
    type: COMPLETE_PROGRESS_BAR,
    payload: { progress_bar_id, progress_bar_data }
  };
};

export const removeProgressBar = progress_bar_id => {
  return {
    type: REMOVE_PROGRESS_BAR,
    payload: progress_bar_id
  };
};
