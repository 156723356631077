import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Copy,
  Icon,
  Row,
  Wrapper,
  Bold,
  InnerCopy
} from "app/NativeComponents/common";
import {
  CircleButton,
  IconButton,
  SelectItem
} from "app/NativeComponents/snippets";
import {
  callPhoneNumber,
  checkIfUserHasMetadata,
  copyToClipBoard,
  formatUsPhone,
  pushSidePanel,
  setActiveDialerContact,
  addDeal,
  showSuccess,
  textPhoneNumber
} from "app/NativeActions";
import { store } from "app/store";

class PhoneItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copy_success: false
    };
  }

  componentWillUnmount() {
    clearInterval(this.copy_success_timeout);
  }

  render() {
    const { phone, contact, property } = this.props;
    const { copy_success } = this.state;
    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    const do_not_call_flag = phone?.do_not_call_flag == 1;
    const known_litigator = phone?.known_litigator == 1;

    const phone_plan = checkIfUserHasMetadata("dialer") && device !== "mobile";
    return (
      <SelectItem
        select_type={"none"}
        noPress={true}
        label={""}
        subtext={phone.usage_12_months}
        renderRight={() => {
          return (
            <Row style={device === "desktop" ? { paddingRight: 15 } : {}}>
              <IconButton
                icon={copy_success ? "check" : "content-copy"}
                backgroundColor={copy_success ? colors.success_color : null}
                icon_color={copy_success ? colors.white_text_color : null}
                tooltip={
                  copy_success
                    ? "Copied: " + formatUsPhone(phone.number)
                    : "Copy: " + formatUsPhone(phone.number)
                }
                tooltipPlacement="top"
                onPress={() => {
                  if (!copy_success) {
                    copyToClipBoard(formatUsPhone(phone.number));
                    clearInterval(this.copy_success_timeout);
                    let $this = this;
                    this.copy_success_timeout = $this.setState(
                      {
                        copy_success: true
                      },
                      () => {
                        setTimeout(() => {
                          $this.setState({
                            copy_success: false
                          });
                        }, 3000);
                      }
                    );
                  }
                }}
              />
              <>
                {!known_litigator &&
                (this.props.user?.team_owner == 1 ||
                  this.props.user?.can_send_message == 1 ||
                  !phone_plan) ? (
                  <>
                    {phone.type === "W" ? (
                      <IconButton
                        icon="chat"
                        tooltip={"Text: " + formatUsPhone(phone.number)}
                        tooltipPlacement="top"
                        onPress={() => {
                          textPhoneNumber(phone.number.replace(/\D/g, ""));
                        }}
                      />
                    ) : null}
                    <IconButton
                      tooltipPlacement="top"
                      tooltip={
                        phone_plan
                          ? "Call: " + formatUsPhone(phone.number)
                          : "Call: " + formatUsPhone(phone.number)
                      }
                      onPress={() => {
                        if (phone_plan) {
                          if (
                            property &&
                            (!property.deal || property.deal?.removed == 1) &&
                            (this.props.user?.team_owner == 1 ||
                              this.props.user?.can_send_message == 1)
                          ) {
                            this.props.addDeal({
                              token: this.props.token,
                              property: property,
                              route_id: this.props.current_route
                                ? this.props.current_route?.route_id
                                : 0,
                              address: property
                                ? property.property_address
                                : null,
                              address2: property
                                ? property.property_address2
                                : null,
                              city: property
                                ? property.property_address_city
                                : null,
                              state: property
                                ? property.property_address_state
                                : null,
                              zip: property
                                ? property.property_address_zip
                                : null,
                              devicetype: this.props.isMobile
                                ? "mobile_map"
                                : "desktop_map",
                              add_type: "no_active",
                              onSuccess: results => {}
                            });
                          }

                          this.props.setActiveDialerContact({
                            contact: {
                              ...contact,
                              selected_phone: phone,
                              associated_lead: property,
                              one_off_call: true
                            },
                            queued_numbers:
                              !this.props.current_call_session ||
                              this.props.current_call_session?.completed
                                ? []
                                : null
                          });
                        } else {
                          callPhoneNumber(phone.number);
                        }

                        if (this.props.onCall) {
                          this.props.onCall();
                        }
                      }}
                      icon={phone_plan ? "phone" : "phone"}
                    />
                  </>
                ) : null}
              </>
            </Row>
          );
        }}
        innerStyle={{ paddingRight: 0 }}
        renderContent={() => {
          return (
            <Wrapper style={{ flex: 1 }}>
              <Row>
                <Wrapper
                  style={{ paddingRight: 10 }}
                  tooltip={phone?.activity_status}
                >
                  <Icon
                    color={
                      phone?.activity_status.includes("Active")
                        ? colors.success_color
                        : colors.orange_color_muted
                    }
                    size={14}
                    icon={"circle"}
                  />
                </Wrapper>
                <Copy>
                  <Bold> {formatUsPhone(phone?.number)}</Bold>
                  <InnerCopy
                    style={{
                      fontSize: 10,
                      color: colors.light_text_color,
                      marginLeft: 5
                    }}
                  >
                    {" " + phone?.usage_2_months}
                  </InnerCopy>
                </Copy>
              </Row>

              <Row style={{ marginTop: 10, flexWrap: "wrap" }}>
                {known_litigator ? (
                  <Wrapper
                    noPress={true}
                    style={{
                      backgroundColor: colors.error_color_muted,
                      borderRadius: 5,
                      padding: 5,
                      paddingRight: 10,
                      paddingLeft: 10,
                      marginRight: 5,
                      marginBottom: 5
                    }}
                    tooltip={
                      "Please note: This contact is a known litigator. We recommend not calling or texting this number. This number is unable to be added to a dialer queue. Proceed at your own risk."
                    }
                    tooltipPlacement="top"
                  >
                    <Row>
                      <Copy style={{ fontSize: 10 }}>{"Known Litigator"}</Copy>
                    </Row>
                  </Wrapper>
                ) : null}

                {do_not_call_flag ? (
                  <Wrapper
                    noPress={true}
                    style={{
                      backgroundColor: colors.orange_color_muted,
                      borderRadius: 5,
                      padding: 5,
                      paddingRight: 10,
                      paddingLeft: 10,
                      marginRight: 5,
                      marginBottom: 5
                    }}
                    tooltip={
                      "Please note: The displayed phone number is on the National Do Not Call Registry. Calling this number for unsolicited sales or promotions may violate telemarketing rules. Proceed at your own risk and ensure compliance with all relevant regulations."
                    }
                    tooltipPlacement="top"
                  >
                    <Row>
                      <Copy style={{ fontSize: 10 }}>{"Do Not Call"}</Copy>
                    </Row>
                  </Wrapper>
                ) : null}

                {!!phone?.type ? (
                  <Wrapper
                    noPress={true}
                    style={{
                      backgroundColor: colors.gray_selected_color,
                      borderRadius: 5,
                      padding: 5,
                      paddingRight: 10,
                      paddingLeft: 10,
                      marginRight: 5,
                      marginBottom: 5
                    }}
                  >
                    <Row>
                      <Copy style={{ fontSize: 10 }}>
                        {phone.type === "W" ? "Wireless" : "Landline"}
                      </Copy>
                    </Row>
                  </Wrapper>
                ) : null}

                {phone?.prepaid_indicator === "T" ? (
                  <Wrapper
                    noPress={true}
                    style={{
                      backgroundColor: colors.gray_selected_color,
                      borderRadius: 5,
                      padding: 5,
                      paddingRight: 10,
                      paddingLeft: 10,
                      marginRight: 5,
                      marginBottom: 5
                    }}
                  >
                    <Row>
                      <Copy style={{ fontSize: 10 }}>{"Prepaid Line"}</Copy>
                    </Row>
                  </Wrapper>
                ) : null}

                <Wrapper
                  noPress={true}
                  style={{
                    backgroundColor: colors.gray_selected_color,
                    borderRadius: 5,
                    padding: 5,
                    paddingRight: 10,
                    paddingLeft: 10,
                    marginRight: 5,
                    marginBottom: 5
                  }}
                >
                  <Row>
                    <Copy style={{ fontSize: 10 }}>{phone.owner}</Copy>
                  </Row>
                </Wrapper>
              </Row>
            </Wrapper>
          );
        }}
      ></SelectItem>
    );
  }
}

const mapStateToProps = ({ auth, route, native, dialer }) => {
  const { token, user } = auth;
  const { current_route } = route;
  const { isMobile } = native;
  const { current_call_session } = dialer;
  return {
    token,
    user,
    current_route,
    isMobile,
    current_call_session
  };
};

export default connect(mapStateToProps, { setActiveDialerContact, addDeal })(
  PhoneItem
);
