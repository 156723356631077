import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView, Scroll } from "app/NativeComponents/common";
import { NewHeader } from "app/NativeComponents/snippets";

import {
  addDeal,
  setStatusModal,
  appRedirect,
  toggleTeamActivityList,
  updateLead,
  selectActiveProperty,
  getActivityProperties,
  pushSidePanel,
  popSidePanel,
  getMailers,
  getProperties,
} from "app/NativeActions";

import PropertyList from "./PropertyList";
import MailerList from "./MailerList";

class NotificationPropertyList extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.activity_properties_type &&
      this.props.activity_properties_type !== prevProps.activity_properties_type
    ) {
      this.getItems({ load_type: "load" });
    }
  }

  componentDidMount() {
    if (this.props.user && this.props.activity_properties_type) {
      this.getItems({ load_type: "load" });
    }
  }

  handleBack() {
    this.props.popSidePanel();
  }

  getItems({ load_type = "load", begin = 0 }) {
    if (
      !this.props.activity_properties_loading &&
      !this.props.activity_properties_refreshing
    ) {
      let filters = {};
      let route_id = null;
      let list_id = null;
      if (this.props.activity_properties_type.slug !== "mailers_sent") {
        switch (this.props.activity_properties_type.slug) {
          case "leads_added":
            filters = {
              added_by_ids: this.props.activity_properties_type.team_member_id,
              min_date_added: this.props.activity_properties_type.date,
              max_date_added: this.props.activity_properties_type.date,
              added_type: "normal_add",
            };
            break;

          case "build_list":
              list_id = this.props.activity_properties_type.list_id
            break;

          case "skip_trace":
            filters = {
              skip_traced_by:
                this.props.activity_properties_type.team_member_id,
              min_date_skip_traced: this.props.activity_properties_type.date,
              max_date_skip_traced: this.props.activity_properties_type.date,
            };
            break;

          case "routes":
            route_id = this.props.activity_properties_type.route_id;
            break;
          default:
            break;
        }

        this.props.getActivityProperties({
          token: this.props.token,
          route_id,
          list_id,
          filters,
          begin,
          load_type,
        });
      } else {
        this.props.getMailers({
          token: this.props.token,
          min_date_mailed: this.props.activity_properties_type.date,
          max_date_mailed: this.props.activity_properties_type.date,
          begin,
          load_type,
        });
      }
    }
  }

  renderHeader() {
    return (
      <NewHeader
        style={{ flex: 1 }}
        title={this.props.activity_properties_type.title}
        leftButton={{
          onPress: () => {
            this.handleBack();
          },
          icon: this.props.isMobile ? "arrow-back" : "close",
        }}
      />
    );
  }

  renderBody() {
    const title =
      this.props.activity_properties_type?.title.length > 75
        ? this.props.activity_properties_type?.title.substr(0, 75) + "..."
        : this.props.activity_properties_type?.title;
    const subtitle =
      this.props.activity_properties_type.subtitle?.length > 75
        ? this.props.activity_properties_type.subtitle.substr(0, 75) + "..."
        : this.props.activity_properties_type?.subtitle;
    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: this.props.colors.card_color,
        }}
      >
        <NewHeader
          title={title}
          subtitle={subtitle}
          leftButton={{
            onPress: () => {
              this.handleBack();
            },
            icon: "arrow-back",
          }}
        />
        <KeyboardView style={{ height: "100%" }}>
          <PropertyList {...this.props} getItems={this.getItems.bind(this)} />
          <MailerList {...this.props} getItems={this.getItems.bind(this)} />
        </KeyboardView>
      </Container>
    );
  }

  render() {
    if (
      this.props.activity_properties_type != null &&
      (this.props.active_property == null || this.props.isMobile)
    ) {
      return this.renderBody();
    }

    return null;
  }
}

const mapStateToProps = ({
  auth,
  settings,
  property_map,
  lead,
  native,
  activity,
}) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, isMobile, isIphoneX, platform } = native;
  const { active_property, add_deal_loading } = property_map;

  const {
    activity_properties,
    activity_properties_loading,
    activity_properties_refreshing,
    activity_properties_loaded_all,
    activity_properties_begin,
    activity_properties_limit,
    activity_properties_type,
    show_team_activity_list,

    mailers,
    mailers_loading,
    mailers_refreshing,
    mailers_loaded_all,
    mailers_begin,
    mailers_limit,
  } = activity;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    isIphoneX,
    platform,

    active_property,
    add_deal_loading,

    activity_properties,
    activity_properties_loading,
    activity_properties_refreshing,
    activity_properties_loaded_all,
    activity_properties_begin,
    activity_properties_limit,
    activity_properties_type,
    show_team_activity_list,

    mailers,
    mailers_loading,
    mailers_refreshing,
    mailers_loaded_all,
    mailers_begin,
    mailers_limit,
  };
};

export default connect(mapStateToProps, {
  addDeal,
  setStatusModal,

  toggleTeamActivityList,
  appRedirect,
  updateLead,
  selectActiveProperty,

  pushSidePanel,
  popSidePanel,
  getActivityProperties,
  getMailers,
  getProperties,
})(NotificationPropertyList);
