import {
  AUTH_FIELD_CHANGED,
  AUTH_UNLOAD,
  CANCEL_PLAN_SUCCESS,
  CANCEL_TEAM_REQUEST_SUCCESS,
  COMPLETE_TUTORIAL,
  COMPLETE_TUTORIAL_SUCCESS,
  CREATE_SIGNATURE_SUCCESS,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_FAIL,
  DELETE_ACCOUNT_SUCCESS,
  EDIT_CREDITS_SUCCESS,
  EDIT_TEAM_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  GET_MAIN_PLANS,
  GET_MAIN_PLANS_FAIL,
  GET_MAIN_PLANS_SUCCESS,
  GET_STATS_SUCCESS,
  GET_TEAM_SUCCESS,
  GET_TERMS,
  GET_TERMS_FAIL,
  GET_TERMS_SUCCESS,
  GET_TUTORIAL_SUCCESS,
  GET_USER,
  INIT_APP,
  LOGIN_USER,
  LOGIN_USER_FAIL,
  LOGIN_USER_SUCCESS,
  LOGOUT,
  OTHER_OWNER_LOOKUP_SUCCESS,
  PAUSE_OR_CANCEL_PLAN_SUCCESS,
  REGISTER_USER,
  REGISTER_USER_FAIL,
  REGISTER_USER_SUCCESS,
  REQUEST_TO_JOIN_TEAM_SUCCESS,
  SAVE_CARD_SUCCESS,
  SAVE_SIGNATURE_SUCCESS,
  SAVE_TEMPLATE_SUCCESS,
  TOGGLE_ACCOUNT_ACTION_SHEET,
  TOGGLE_COPY,
  TOGGLE_ONBOARDING,
  TRIGGER_LOGOUT,
  UPDATE_BILLING_ADDON_SUCCESS,
  UPDATE_RETURN_ADDRESS_SUCCESS,
  UPDATE_TEAM_LINK,
  UPDATE_TEAM_MEMBERS_SUCCESS,
  UPDATE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_FAIL,
  SET_OAUTH_INFO,
  UPDATE_DASHBOARD_SETTINGS_SUCCESS,
  UPDATE_USER_SUCCESS,
  SET_LOGINS,
  ENTER_PROMO_SUCCESS,
  UPDATE_OWNER_INFO_SUCCESS,
  UPDATE_LEAD_SUCCESS,
  SET_DEFAULT_MAILER_CAMPAIGN_SUCCESS,
  SET_DEFAULT_MAILER_TEMPLATE_SUCCESS,
  SET_DEFAULT_MAILER_SIGNATURE_SUCCESS,
  UPDATE_CUSTOM_FIELDS_SUCCESS,
  GET_LISTS_SUCCESS,
  REMOVE_TASK,
  UNDO_COMPLETE_TASK_SUCCESS,
  COMPLETE_TASK_SUCCESS,
  CREATE_TASK_SUCCESS,
  UPDATE_TASK_SUCCESS,
  GET_SOURCE_OF_TRUTH_SUCCESS,
  OUTDATED_VERSION,
  TOGGLE_USER_REFRESH
} from "app/DealMachineCore/types";
import { AppConfig } from "app/NativeActions";

const INITIAL_STATE = {
  init: false,
  token: AppConfig().saved_token || "VS1bRao17noGx$",

  email: "",
  phone: "",
  city: "",
  password: "",
  firstname: "",
  lastname: "",
  company: "",
  oauth_token: "",
  accepted_terms: 1,
  promoText: "",
  oauth_info: {},
  user: null,
  error: "",
  loading: false,
  plans_loading: false,
  plans_error: "",
  plans: [],
  actionSheet: null,
  trigger_logout: false,
  onboarding: false,
  signup_type: "",
  signup_team_name: "",
  signup_team_company: "",
  signup_branch_link: "",
  from_campaign: "",
  from_source: "",
  branch_id: "",
  partner: "",
  partner_logo: "",
  partner_logo_white: "",
  copy_tab: "",
  logins: 0,
  terms_of_service: "",
  terms_error: "",
  terms_loading: false,
  apple_id: "",
  tutorial: null,
  user_partner_info: {
    id: 0
  },
  outdated_version: false,
  toggle_user_refresh: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
    case CANCEL_PLAN_SUCCESS:
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        trigger_logout: false,
        init: true
      };

    case INIT_APP:
      return {
        ...state,
        init: action.payload
      };

    case OUTDATED_VERSION:
      return {
        ...state,
        outdated_version: action.payload
      };
    case TOGGLE_USER_REFRESH:
      return {
        ...state,
        toggle_user_refresh: action.payload
      };
    case TOGGLE_COPY:
      return {
        ...state,
        copy_tab: action.payload
      };
    case AUTH_FIELD_CHANGED:
      //action.payload === {prop: 'name', value: 'Jane'}
      return {
        ...state,
        [action.payload.prop]: action.payload.value
      };
    case SET_OAUTH_INFO:
      return {
        ...state,
        oauth_info: action.payload
      };
    case SET_LOGINS:
      return {
        ...state,
        logins: action.payload
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        error: ""
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state
      };

    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case GET_USER:
      return {
        ...state,
        error: "",
        loading: true
      };
    case LOGIN_USER:
      return {
        ...state,
        error: ""
      };

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        init: true,
        user: action.payload.user,
        token: action.payload.token,
        user_partner_info: action.payload.partner
      };

    case GET_STATS_SUCCESS:
    case UPDATE_TEAM_LINK:
    case EDIT_CREDITS_SUCCESS:
    case GET_LISTS_SUCCESS:
    case GET_SOURCE_OF_TRUTH_SUCCESS:
      return {
        ...state,
        user:
          action.payload.user && action.payload.user?.id !== "no_user"
            ? action.payload.user
            : state.user
      };

    case LOGIN_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        password: "",
        loading: false
      };

    case REGISTER_USER:
      return {
        ...state,
        error: ""
      };

    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        init: true,
        user: action.payload.user,
        token: action.payload.token
      };

    case REGISTER_USER_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case DELETE_ACCOUNT:
      return {
        ...state,
        error: "",
        loading: true
      };
    case DELETE_ACCOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case TRIGGER_LOGOUT:
      return {
        ...state,
        trigger_logout: action.payload
      };

    case SAVE_CARD_SUCCESS:
    case UPDATE_BILLING_ADDON_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        card_info: { ...state.card_info, adding_additional: false }
      };
    case AUTH_UNLOAD:
      return {
        ...state,
        ...INITIAL_STATE,
        init: true
      };
    case TOGGLE_ACCOUNT_ACTION_SHEET:
      return {
        ...state,
        actionSheet: action.payload
      };
    case UPDATE_RETURN_ADDRESS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          address: action.payload.address,
          address2: action.payload.address2,
          address_city: action.payload.address_city,
          address_state: action.payload.address_state,
          address_zip: action.payload.address_zip,
          phone: action.payload.phone
        }
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user
      };
    case SAVE_TEMPLATE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          default_template_id: action.payload.default_template_id
            ? action.payload.default_template_id
            : state.user.default_template_id
        }
      };
    case GET_MAIN_PLANS:
      return {
        ...state,
        plans_loading: true,
        plans_error: ""
      };
    case GET_MAIN_PLANS_FAIL:
      return {
        ...state,
        plans_loading: false,
        plans_error: action.payload
      };
    case GET_MAIN_PLANS_SUCCESS:
      return {
        ...state,
        plans_loading: false,
        plans: action.payload.plans
      };

    case EDIT_TEAM_SUCCESS:
    case GET_TEAM_SUCCESS:
    case REQUEST_TO_JOIN_TEAM_SUCCESS:
    case CANCEL_TEAM_REQUEST_SUCCESS:
    case UPDATE_TEAM_MEMBERS_SUCCESS:
    case PAUSE_OR_CANCEL_PLAN_SUCCESS:
    case SET_DEFAULT_MAILER_CAMPAIGN_SUCCESS:
    case SET_DEFAULT_MAILER_TEMPLATE_SUCCESS:
    case SET_DEFAULT_MAILER_SIGNATURE_SUCCESS:
      return {
        ...state,
        user: action.payload.user ? action.payload.user : state.user
      };

    case OTHER_OWNER_LOOKUP_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user
        }
      };

    case TOGGLE_ONBOARDING:
      return {
        ...state,
        onboarding: action.payload
      };

    case SAVE_SIGNATURE_SUCCESS:
    case CREATE_SIGNATURE_SUCCESS:
      return {
        ...state,
        user: action.payload.user ? action.payload.user : state.user
      };

    case GET_TERMS:
      return {
        ...state,
        terms_of_service: "",
        terms_loading: true,
        terms_error: ""
      };

    case GET_TERMS_FAIL:
      return {
        ...state,
        terms_loading: false,
        terms_error: action.payload
      };

    case GET_TERMS_SUCCESS:
      return {
        ...state,
        terms_of_service: action.payload,
        terms_loading: false
      };

    case GET_TUTORIAL_SUCCESS:
      return {
        ...state,
        tutorial: action.payload.tutorial ? action.payload.tutorial : null
      };
    case COMPLETE_TUTORIAL:
    case COMPLETE_TUTORIAL_SUCCESS:
      return {
        ...state,
        tutorial: null
      };

    case UPDATE_USER_SETTINGS_SUCCESS:
    case UPDATE_DASHBOARD_SETTINGS_SUCCESS:
      return {
        ...state,
        user: action.payload.user
      };

    case ENTER_PROMO_SUCCESS:
      return {
        ...state,
        user_partner_info: action.payload.partner
          ? action.payload.partner
          : state.stats.user_partner_info
      };

    case UPDATE_OWNER_INFO_SUCCESS:
      //this sets has_skip_traced to 1 so the popup doesn't keep coming up on the first skip trace
      return {
        ...state,
        user: {
          ...state.user,
          has_skip_traced:
            action.payload?.type == "skip_trace_v2"
              ? 1
              : state.user.has_skip_traced
        }
      };
    case UPDATE_LEAD_SUCCESS:
    case UPDATE_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        user: action.payload.user ? action.payload.user : state.user
      };

    case REMOVE_TASK:
    case UNDO_COMPLETE_TASK_SUCCESS:
    case COMPLETE_TASK_SUCCESS:
    case CREATE_TASK_SUCCESS:
    case UPDATE_TASK_SUCCESS:
      return {
        ...state,
        user: action.payload.active_task_count
          ? {
              ...state.user,
              active_task_count: action.payload.active_task_count
            }
          : state.user
      };
    default:
      return state;
  }
};
