import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  Row,
  Bold,
  KeyboardView,
  Container
} from "app/NativeComponents/common";

import {
  AttentionBox,
  BottomNavBar,
  GhostButton,
  InlineButton,
  List,
  NewHeader,
  PopoverMenu,
  SearchBar,
  SelectItem,
  SpinWrapper
} from "app/NativeComponents/snippets";
import {
  getDialerStats,
  popSidePanel,
  pushSidePanel,
  numberWithCommas,
  updateUserSettings,
  createCallSession,
  showErrorMessage,
  setActiveDialerContact,
  getTeam,
  updateCallSession
} from "app/NativeActions";

import FollowUpQueueItem from "./FollowUpQueueItem";

class FollowUpQueue extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      filter_user: panel_data?.filter_user
        ? panel_data?.filter_user
        : props.user?.user_settings?.follow_up_queue_settings
        ? props.user?.user_settings?.follow_up_queue_settings?.filter_user
        : null,

      follow_up_queue: [],
      follow_up_queue_loading: false,
      follow_up_queue_refreshing: false,
      follow_up_queue_loaded_all: false,
      follow_up_queue_limit: 25,
      follow_up_queue_begin: 0,
      follow_up_queue_count: 0,
      follow_up_queue_count_loading: false,
      search: ""
    };

    this.handleBack = this.handleBack.bind(this);
    this.triggerAutocomplete = this.triggerAutocomplete.bind(this);

    this.removeItemFromFollowUpQueue =
      this.removeItemFromFollowUpQueue.bind(this);
  }

  componentDidMount() {
    if (!this.props.team_loading) {
      this.props.getTeam({ token: this.props.token, type: "load" });
    }

    this.getData({ load_type: "load" });
  }

  removeItemFromFollowUpQueue(remove_item) {
    //remove the item from the this.state.follow_up_queue where the id of the item matches the item_id and update the state
    this.setState(
      {
        follow_up_queue: this.state.follow_up_queue.filter(
          item => item.call_id !== remove_item.call_id
        ),
        follow_up_queue_count: remove_item.ready_to_call
          ? this.state.follow_up_queue_count - 1 > 0
            ? this.state.follow_up_queue_count - 1
            : 0
          : this.state.follow_up_queue_count
      },
      () => {}
    );
  }

  triggerAutocomplete(value) {
    clearInterval(this._search_interval);
    this._search_interval = setTimeout(() => {
      if (
        (value.length == 0 || value.length > 2) &&
        !this.state.follow_up_queue_refreshing
      ) {
        this.getData({ load_type: "refresh" });
      }
    }, 750);
  }

  getData({ load_type = "load" }) {
    if (
      !this.props.follow_up_queue_loading &&
      !this.state.follow_up_queue_refreshing
    ) {
      this.setState(
        {
          follow_up_queue_begin:
            load_type === "load" || load_type === "refresh"
              ? 0
              : this.state.follow_up_queue_begin +
                this.state.follow_up_queue_limit,
          follow_up_queue:
            load_type === "refresh" ? [] : this.state.follow_up_queue,
          follow_up_queue_loading: true,
          follow_up_queue_refreshing: load_type === "refresh"
        },
        () => {
          if (load_type === "load" || load_type === "refresh") {
            this.props.getDialerStats({
              token: this.props.token,
              filter_user: this.state.filter_user
                ? this.state.filter_user?.id
                : null,
              limit: this.state.follow_up_queue_limit,
              begin: this.state.follow_up_queue_begin,
              type: "follow_up_queue_count",
              onLoading: () => {
                this.setState({
                  follow_up_queue_count_loading: true
                });
              },
              onError: () => {
                this.setState({
                  follow_up_queue_count_loading: false
                });
              },
              onSuccess: results => {
                if (results?.follow_up_queue_count) {
                  this.setState({
                    follow_up_queue_count_loading: false,
                    follow_up_queue_count: results.follow_up_queue_count
                  });
                }
              }
            });
          }

          this.props.getDialerStats({
            token: this.props.token,
            filter_user: this.state.filter_user
              ? this.state.filter_user?.id
              : null,
            limit: this.state.follow_up_queue_limit,
            begin: this.state.follow_up_queue_begin,
            search: this.state.search,
            type: "follow_up_queue",
            onLoading: () => {
              this.setState({
                follow_up_queue_loading: true
              });
            },
            onError: () => {
              this.setState(
                {
                  follow_up_queue_loading: false,
                  follow_up_queue_refreshing: false
                },
                () => {
                  this.setState({
                    follow_up_queue_loading: false
                  });
                }
              );
            },
            onSuccess: results => {
              if (results?.follow_up_queue) {
                this.setState(
                  {
                    follow_up_queue_loading: false,
                    follow_up_queue_refreshing: false,
                    follow_up_queue:
                      load_type === "load_more"
                        ? [
                            ...this.state.follow_up_queue,
                            ...results.follow_up_queue
                          ]
                        : results.follow_up_queue,
                    follow_up_queue_loaded_all:
                      results.follow_up_queue?.length <
                      this.state.follow_up_queue_limit
                  },
                  () => {}
                );
              }
            }
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.filter_user?.id != prevState.filter_user?.id) {
      this.getData({ load_type: "refresh" });

      this.props.updateUserSettings({
        token: this.props.token,
        type: "follow_up_queue_settings",
        value: {
          filter_user: this.state.filter_user
            ? {
                id: this.state.filter_user?.id,
                firstname: this.state.filter_user?.firstname,
                lastname: this.state.filter_user?.lastname,
                email: this.state.filter_user?.email
              }
            : null
        }
      });
    }
    if (
      JSON.stringify(this.props.user?.user_settings?.dialer_settings) !==
      JSON.stringify(prevProps.user?.user_settings?.dialer_settings)
    ) {
      this.getData({ load_type: "refresh" });
    }

    if (
      JSON.stringify(this.props.called_numbers) !==
      JSON.stringify(prevProps.called_numbers)
    ) {
      this.getData({ load_type: "refresh" });
    }
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentWillUnmount() {
    clearInterval(this._search_interval);
  }

  render() {
    const { colors } = this.props;
    const {
      follow_up_queue,
      follow_up_queue_loading,
      follow_up_queue_refreshing,
      follow_up_queue_loaded_all
    } = this.state;

    return (
      <Container>
        {this.props.side_panel ? (
          <AttentionBox
            title={"Follow-Up Queue"}
            description={
              "After you call someone they will automatically be placed in your follow-up queue based on the call results. You can manage how soon they will show up in your queue in your dialer settings."
            }
          />
        ) : (
          <NewHeader
            title={"Follow-Up Queue"}
            leftButton={{
              icon: this.props.device === "desktop" ? "close" : "arrow-back",
              onPress: () => {
                this.handleBack();
              }
            }}
          />
        )}
        <Row style={{ justifyContent: "space-between" }}>
          <Wrapper style={{ flex: 1 }}>
            <SearchBar
              title={"Search Queue"}
              onChange={value => {
                this.setState(
                  {
                    search: value
                  },
                  () => {
                    this.triggerAutocomplete(value);
                  }
                );
              }}
              value={this.state.search}
              style={{
                margin: 10,
                marginRight: 0,
                borderWidth: 0,
                borderBottomWidth: 0
              }}
            />
          </Wrapper>
          <PopoverMenu
            show={this.state.show_team_filter}
            no_swipe={true}
            no_cancel={true}
            onShow={s => {
              this.setState({
                show_team_filter: s
              });
            }}
            popover_width={400}
            popoverSheetTop={"80%"}
            popoverPlacement={"bottom"}
            popover_title={"Filter by Team"}
            includeCloseButton={true}
            disabled={
              this.state.stats_loading ||
              this.props.team_loading ||
              this.state.activity_loading ||
              this.state.call_sessions_loading
            }
            renderComponent={options => {
              return (
                <InlineButton
                  icon_right={
                    this.state.show_team_filter
                      ? "keyboard-arrow-up"
                      : "keyboard-arrow-down"
                  }
                  noPress={true}
                  pressedIn={
                    options.hovering ||
                    options.pressedIn ||
                    this.state.show_team_filter
                  }
                  disabled={
                    this.state.stats_loading ||
                    this.props.team_loading ||
                    this.state.activity_loading ||
                    this.state.call_sessions_loading
                  }
                >
                  {!this.state.filter_user
                    ? "Team Follow-Up Queue"
                    : this.state.filter_user?.id == this.props.user.id
                    ? "Showing My Follow-Up"
                    : "Showing Follow-Up Queue For " +
                      (this.state.filter_user?.firstname &&
                      this.state.filter_user?.lastname
                        ? this.state.filter_user?.firstname +
                          " " +
                          this.state.filter_user?.lastname
                        : this.state.filter_user?.email)}
                </InlineButton>
              );
            }}
            renderMenu={() => {
              return (
                <Wrapper style={{ alignSelf: "stretch" }}>
                  <SearchBar
                    title={"Search Team"}
                    onChange={value => {
                      this.setState({
                        team_search: value
                      });
                    }}
                    value={this.state.team_search}
                    style={{
                      margin: 10
                    }}
                  />
                  <KeyboardView
                    style={
                      this.props.device == "desktop"
                        ? {
                            maxHeight: 300,
                            alignSelf: "stretch"
                          }
                        : {
                            alignSelf: "stretch"
                          }
                    }
                  >
                    {!this.state.team_search ||
                    "My Follow-Up Queue"
                      .toLowerCase()
                      .includes(this.state.team_search.toLowerCase()) ? (
                      <SelectItem
                        select_type="radio"
                        selected={
                          this.state.filter_user?.id == this.props.user?.id
                        }
                        onPress={() => {
                          this.setState({
                            filter_user: this.props.user,
                            show_team_filter: false
                          });
                        }}
                      >
                        My Follow-Up Queue
                      </SelectItem>
                    ) : null}
                    {!this.state.team_search ||
                    "All Team Calls"
                      .toLowerCase()
                      .includes(this.state.team_search.toLowerCase()) ? (
                      <SelectItem
                        select_type="radio"
                        selected={!this.state.filter_user}
                        onPress={() => {
                          this.setState({
                            filter_user: null,
                            show_team_filter: false
                          });
                        }}
                      >
                        Team Follow-Up Queue
                      </SelectItem>
                    ) : null}

                    {this.props.my_team &&
                      this.props.my_team.map((team_member, i) => {
                        const name =
                          team_member.firstname && team_member.lastname
                            ? team_member.firstname + " " + team_member.lastname
                            : team_member?.email;
                        if (
                          team_member.id != this.props.user.id &&
                          (!this.state.team_search ||
                            name
                              .toLowerCase()
                              .includes(this.state.team_search.toLowerCase()))
                        ) {
                          return (
                            <SelectItem
                              select_type="radio"
                              selected={
                                this.state.filter_user?.id == team_member.id
                              }
                              onPress={() => {
                                this.setState({
                                  filter_user: team_member,
                                  show_team_filter: false
                                });
                              }}
                            >
                              {name}
                            </SelectItem>
                          );
                        }

                        return null;
                      })}
                  </KeyboardView>
                </Wrapper>
              );
            }}
            menu_items={null}
          />
        </Row>

        {(follow_up_queue_loading && follow_up_queue?.length === 0) ||
        follow_up_queue_refreshing ? (
          <SpinWrapper text={"Loading Follow-Up Queue"} />
        ) : null}
        {follow_up_queue?.length === 0 &&
        !follow_up_queue_loading &&
        !follow_up_queue_refreshing ? (
          <Wrapper style={{ padding: 25 }}>
            <Copy style={{ textAlign: "center" }}>
              Follow-up queue is empty
            </Copy>
          </Wrapper>
        ) : (
          <List
            rowNumber={1}
            items={follow_up_queue}
            infiniteScroll={true}
            itemStructure={({ item, index }) => {
              return (
                <FollowUpQueueItem
                  key={"follow_up_queue_" + index}
                  item={item}
                  removeItemFromFollowUpQueue={this.removeItemFromFollowUpQueue}
                />
              );
            }}
            canRefresh={false}
            onRefresh={() => {}}
            is_refreshing={false}
            canLoadMore={
              !follow_up_queue_loaded_all &&
              !follow_up_queue_loading &&
              follow_up_queue?.length > 0
            }
            isLoadingMore={
              follow_up_queue_loading && follow_up_queue?.length > 0
            }
            onLoadMore={() => {
              if (
                !follow_up_queue_loaded_all &&
                follow_up_queue_loading !== true
              ) {
                this.getData({ load_type: "load_more" });
              }
            }}
          />
        )}
        {!this.state.follow_up_queue_count_loading &&
        this.state.follow_up_queue_count > 0 &&
        (!this.props.current_call_session ||
          this.props.current_call_session?.completed) &&
        this.state.follow_up_queue?.length > 0 &&
        (this.props.user?.team_owner == 1 ||
          this.props.user?.can_send_message == 1) &&
        this.props.device !== "mobile" ? (
          <BottomNavBar>
            <Wrapper style={{ padding: 25 }}>
              <Copy>
                <Bold>Start dialer session?</Bold>
              </Copy>
              {this.state.follow_up_queue_count == 1 ? (
                <Copy>
                  Start a new session with the 1 follow-up call in your queue
                </Copy>
              ) : (
                <Copy>
                  Start a new session with all{" "}
                  {numberWithCommas(this.state.follow_up_queue_count)} follow-up
                  calls in your queue.
                </Copy>
              )}
            </Wrapper>

            <GhostButton
              primary={true}
              loading={this.state.create_call_session_loading}
              button_type={"full"}
              disabled={
                this.props.user?.user_settings?.dialer_settings?.practice_mode
              }
              tooltip={
                this.props.user?.user_settings?.dialer_settings?.practice_mode
                  ? "Practice mode is enabled. You cannot start a follow-up session while in practice mode."
                  : null
              }
              tooltipPlacement={"top"}
              onPress={() => {
                this.props.createCallSession({
                  token: this.props.token,
                  select_all: 0,
                  total_count: this.state.follow_up_queue_count,
                  follow_up_queue: true,
                  follow_up_queue_user: this.state.filter_user
                    ? this.state.filter_user?.id
                    : null,
                  call_session_type: "follow_up_queue",
                  lead_ids: "",
                  onLoading: () => {
                    this.setState({
                      create_call_session_loading: true
                    });
                  },
                  onError: error => {
                    this.setState(
                      {
                        create_call_session_loading: false
                      },
                      () => {
                        this.props.showErrorMessage(error, "Error");
                      }
                    );
                  },
                  onSuccess: results => {
                    this.setState(
                      {
                        create_call_session_loading: false
                      },
                      () => {
                        if (
                          results.queued_numbers &&
                          results.queued_numbers.length > 0
                        ) {
                          let first_contact = results.queued_numbers[0];

                          this.props.setActiveDialerContact({
                            contact: {
                              ...first_contact.contact,
                              selected_phone: {
                                number: first_contact.number,
                                type: first_contact.type,
                                do_not_call_flag:
                                  first_contact.do_not_call_flag,
                                owner: first_contact.owner
                              },
                              associated_lead: first_contact.property
                            },
                            queued_numbers: results.queued_numbers
                          });

                          if (!this.props.side_panel) {
                            this.props.popSidePanel();
                          }
                        }
                      }
                    );
                  }
                });
              }}
            >
              Start Session
            </GhostButton>
          </BottomNavBar>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, dialer, team }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;
  const { current_call_session, called_numbers } = dialer;
  const { my_team, team_loading } = team;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform,
    current_call_session,
    called_numbers,
    my_team,
    team_loading
  };
};

export default connect(mapStateToProps, {
  getDialerStats,
  pushSidePanel,
  updateUserSettings,
  getTeam,
  createCallSession,
  showErrorMessage,
  setActiveDialerContact,
  popSidePanel,
  updateCallSession
})(FollowUpQueue);
