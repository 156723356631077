import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Row,
  Scroll,
  Gradient,
  Copy,
  Spinner,
  GoogleOAuth,
  AppleLogin
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  IconButton,
  InlineButton,
  PopoverMenu,
  SelectItem,
  SearchBar,
  GhostButton,
  SpinWrapper,
  UpsellButton
} from "app/NativeComponents/snippets";

import {
  tabNavigation,
  selectActiveProperty,
  selectActiveCoordinates,
  selectMultipleProperties,
  appRedirect,
  setModal,
  toggleModal,
  changeTab,
  pushSidePanel,
  selectActiveRoute,
  startTrackedRoute,
  toggleDrivingMenu,
  openUrl,
  displayIntercom,
  AppConfig,
  checkIfUserHasSubscription,
  showErrorMessage,
  getUserFromAppleAuth,
  registerUser,
  loginUser,
  setOAuthInfo,
  dismissMobileKeyboard,
  checkIfUserHasMetadata
} from "app/NativeActions";
import BottomTabButton from "./BottomTabButton";

import NavigationHandler from "./NavigationHandler";
import BackgroundLocation from "app/Router/BackgroundLocation";
import BottomSlider from "./BottomSlider";

class BottomTabBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_more: false,
      search: "",
      login_loading: false,
      intercom_opened: false
    };

    this.googleLogin = this.googleLogin.bind(this);
    this.appleLogin = this.appleLogin.bind(this);
  }

  googleLogin(oAuth = null, oAuthProfile = null) {
    /* mobile */
    dismissMobileKeyboard();
    const { device, initialRedirect, platform } = this.props;

    if (oAuth) {
      this.setState({ login_loading: true });

      const { email, familyName, givenName, imageUrl } = oAuth?.profileObj;
      this.props.setOAuthInfo(oAuth);
      this.props.registerUser({
        email: email,
        device,
        firstname: givenName,
        lastname: familyName,
        image: imageUrl,
        accepted_terms: 1,
        check_phone: 0,
        initialRedirect,
        oAuthToken: oAuth?.accessToken,
        platform,
        onLoading: () => {
          this.setState({ login_loading: true });
        },
        onError: () => {
          this.setState({ login_loading: false });
        },
        onSuccess: () => {
          this.setState({ login_loading: false });
        }
      });
    } else {
      this.props.showErrorMessage("Google authentication failed.", "Error");
    }
  }

  appleLogin(appleresponse = "") {
    /* mobile */
    dismissMobileKeyboard();
    const { device, initialRedirect, platform, getUserFromAppleAuth } =
      this.props;
    if (appleresponse) {
      this.setState({
        login_loading: true
      });
      //this.props.setOAuthInfo(oAuth);
      getUserFromAppleAuth({
        appleToken:
          device === "mobile"
            ? appleresponse?.identityToken
            : appleresponse?.authorization?.id_token,
        onSuccess: results => {
          if (results?.user_exists) {
            this.props.loginUser({
              device,
              initialRedirect,
              apple_id:
                device === "mobile"
                  ? appleresponse?.identityToken
                  : appleresponse?.authorization?.id_token,
              onLoading: () => {
                this.setState({
                  login_loading: true
                });
              },
              onError: () => {
                this.setState({
                  login_loading: false
                });
              },
              onSuccess: () => {
                this.setState({
                  login_loading: false
                });
              }
            });
          } else {
            this.props.registerUser({
              email:
                device === "mobile"
                  ? appleresponse.email || results?.user_email
                  : appleresponse?.user?.email || results?.user_email,
              firstname:
                device === "mobile"
                  ? appleresponse.fullName.givenName || results?.user_firstname
                  : appleresponse?.user?.name?.firstName ||
                    results?.user_firstname,
              lastname:
                device === "mobile"
                  ? appleresponse.fullName.familyName || results?.user_lastname
                  : appleresponse?.user?.name?.lastName ||
                    results?.user_lastname,
              accepted_terms: 1,
              check_phone: 0,
              initialRedirect,
              platform,
              device,
              apple_id:
                device === "mobile"
                  ? appleresponse?.identityToken
                  : appleresponse?.authorization?.id_token,
              onLoading: () => {
                this.setState({ login_loading: true });
              },
              onError: () => {
                this.setState({ login_loading: false });
              },
              onSuccess: () => {
                this.setState({ login_loading: false });
              }
            });
          }
        }
      });
    } else {
      this.props.showErrorMessage("Apple authentication failed.", "Error");
    }
  }

  componentDidMount() {
    if (
      this.props.device == "mobile" &&
      this.props.user &&
      this.props.user?.team_owner == 1 &&
      this.props.user?.finished_onboarding == 0
    ) {
      clearInterval(this._push_timeout);
      this._push_timeout = setTimeout(() => {
        this.props.pushSidePanel({
          slug: "start_trial",
          overlay: true
        });
      }, 2000);
    }
  }

  componentWillUnmount() {
    clearInterval(this._push_timeout);
  }

  render() {
    const {
      user,
      colors,
      device,
      tab,
      source_of_truth,
      loading,
      source_of_truth_loading,
      platform
    } = this.props;
    const { show_more, search } = this.state;

    const phone_plan = checkIfUserHasMetadata("dialer");

    return (
      <>
        {!this.props.select_virtual_drive_start &&
        !loading &&
        user &&
        user?.finished_onboarding == 1 &&
        user?.onboarding_stepthrough_completed == 1 ? (
          <>
            {(loading && !user) || source_of_truth_loading ? (
              <></>
            ) : !user && device == "mobile" ? (
              <>
                <Wrapper
                  className="dm-bottom-tab-bar"
                  style={{ position: "relative" }}
                >
                  <Gradient
                    color1={colors.gradient_color_1}
                    color2={colors.gradient_color_2}
                    style={{
                      height: 3
                    }}
                  ></Gradient>

                  <BottomNavBar
                    style={
                      AppConfig().mobilePhoneBrowser
                        ? {
                            alignSelf: "stretch",
                            paddingBottom: 55
                          }
                        : {
                            alignSelf: "stretch"
                          }
                    }
                  >
                    <Wrapper style={{ padding: 15 }}>
                      <Copy style={{ textAlign: "center" }}>
                        Log in or create an account to get started in real
                        estate.
                      </Copy>
                    </Wrapper>

                    <GhostButton
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "create_an_account",
                          overlay: true
                        });
                      }}
                      style={{ marginRight: 5 }}
                      primary={true}
                      button_type={"full"}
                    >
                      Create An Account
                    </GhostButton>

                    <Row>
                      <Wrapper style={{ flex: 1 }}>
                        <GoogleOAuth
                          buttonText={"Sign in with Google"}
                          onSuccess={this.googleLogin}
                          innerStyle={
                            this.props.platform !== "android"
                              ? { marginRight: 5 }
                              : {}
                          }
                        />
                      </Wrapper>
                      {this.props.platform !== "android" ? (
                        <Wrapper style={{ flex: 1 }}>
                          <AppleLogin
                            title={"Sign in with Apple"}
                            onSucc={this.appleLogin}
                            redirectPath={"sign-up"}
                            style={{
                              margin: 5,
                              marginRight: 10,
                              marginLeft: 5
                            }}
                          />
                        </Wrapper>
                      ) : null}
                    </Row>
                    <InlineButton
                      id="intercom_button"
                      button_type={"full"}
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "login",
                          overlay: true
                        });
                      }}
                    >
                      Log in with your email
                    </InlineButton>
                  </BottomNavBar>

                  {this.state.login_loading || this.props.active_property ? (
                    <Wrapper
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        backgroundColor:
                          this.props.dark_mode == "dark_mode"
                            ? "rgba(0, 0, 0, 0.8)"
                            : "rgba(255, 255, 255, 0.8)",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <SpinWrapper />
                    </Wrapper>
                  ) : null}
                </Wrapper>
              </>
            ) : user &&
              !checkIfUserHasSubscription(user, source_of_truth) &&
              device == "mobile" ? (
              <>
                <Wrapper className="dm-bottom-tab-bar">
                  <Gradient
                    color1={colors.gradient_color_1}
                    color2={colors.gradient_color_2}
                    style={{
                      height: 3
                    }}
                  ></Gradient>

                  <BottomNavBar
                    style={
                      AppConfig().mobilePhoneBrowser
                        ? {
                            alignSelf: "stretch",
                            paddingBottom: 55
                          }
                        : {
                            alignSelf: "stretch"
                          }
                    }
                  >
                    <Wrapper style={{ padding: 15 }}>
                      <Copy style={{ textAlign: "center" }}>
                        Start a trial to get started in real estate.
                      </Copy>
                    </Wrapper>

                    <GhostButton
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "start_trial",
                          overlay: true
                        });
                      }}
                      style={{ marginRight: 5 }}
                      primary={true}
                      button_type={"full"}
                    >
                      Start Free Trial
                    </GhostButton>

                    <InlineButton
                      id="intercom_button"
                      button_type={"full"}
                      onPress={displayIntercom}
                    >
                      Need help? Talk to a human.
                    </InlineButton>
                  </BottomNavBar>
                </Wrapper>
              </>
            ) : checkIfUserHasSubscription(user, source_of_truth) ? (
              <Wrapper className="dm-bottom-tab-bar">
                <Gradient
                  color1={colors.gradient_color_1}
                  color2={colors.gradient_color_2}
                  style={{
                    height: 3
                  }}
                ></Gradient>

                <BottomNavBar
                  style={
                    AppConfig().mobilePhoneBrowser
                      ? {
                          alignSelf: "stretch",
                          paddingBottom: 55
                        }
                      : {
                          alignSelf: "stretch"
                        }
                  }
                >
                  <Row
                    style={{
                      alignItems: "center",
                      justifyContent: "space-around"
                    }}
                  >
                    <>
                      <BottomTabButton
                        onPress={() => {
                          this.props.changeTab("leads", tab);
                          this.props.tabNavigation({ slug: "leads" });
                        }}
                        icon="home-work"
                        title="Leads"
                        colors={colors}
                        active={tab === "leads"}
                      />
                      <BottomTabButton
                        onPress={() => {
                          this.props.changeTab("map", tab);
                          this.props.tabNavigation({ slug: "map" });
                        }}
                        icon="map"
                        title={"Map"}
                        primary={true}
                        colors={colors}
                        active={tab === "map"}
                      />

                      {/*
                      <BottomTabButton
                        onPress={() => {
                          this.props.changeTab("dialer", tab);
                          this.props.tabNavigation({ slug: "dialer" });
                        }}
                        icon="call"
                        title={"Dialer"}
                        primary={false}
                        colors={colors}
                        active={tab === "dialer"}
                      />
                      */}
                    </>

                    {device == "mobile" ? (
                      <BottomTabButton
                        onPress={() => {
                          this.props.changeTab("more", tab);
                          this.props.tabNavigation({ slug: "more" });
                        }}
                        icon="more-horiz"
                        title="More"
                        colors={colors}
                        active={
                          tab === "more" ||
                          (tab === "dashboard" && !phone_plan) ||
                          tab === "tasks" ||
                          tab === "selling" ||
                          tab === "mail" ||
                          tab === "postcard_designer" ||
                          tab === "mail_sequences" ||
                          tab === "help"
                        }
                      />
                    ) : (
                      <PopoverMenu
                        show={show_more}
                        no_swipe={true}
                        no_cancel={true}
                        onShow={s => {
                          this.setState({
                            show_more: s,
                            search: ""
                          });
                        }}
                        popover_width={350}
                        popoverSheetTop={"75%"}
                        popover_title="More Options"
                        componentStyle={{ width: 68 }}
                        includeCloseButton={true}
                        renderComponent={({ hovering }) => {
                          return (
                            <BottomTabButton
                              noPress={true}
                              pressedIn={show_more || hovering}
                              icon="more-horiz"
                              title="More"
                              colors={colors}
                              active={
                                tab === "more" ||
                                tab === "dashboard" ||
                                tab === "tasks" ||
                                tab === "selling" ||
                                tab === "mail" ||
                                tab === "help"
                              }
                            />
                          );
                        }}
                        renderMenu={() => {
                          return (
                            <>
                              <Wrapper
                                style={{
                                  alignSelf: "stretch"
                                }}
                              >
                                <SearchBar
                                  title="Search"
                                  value={search}
                                  onChange={value => {
                                    this.setState({ search: value });
                                  }}
                                  style={{ margin: 10 }}
                                />
                              </Wrapper>
                              <Scroll
                                style={
                                  device == "desktop"
                                    ? {
                                        maxHeight: 300,
                                        alignSelf: "stretch"
                                      }
                                    : {
                                        alignSelf: "stretch"
                                      }
                                }
                              >
                                {user?.team_clearance_level > 0 &&
                                (!search ||
                                  "Dashboard"
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ? (
                                  <SelectItem
                                    select_type="none"
                                    icon="keyboard-arrow-right"
                                    selected={tab == "dashboard"}
                                    onPress={() => {
                                      this.props.changeTab("dashboard", tab);

                                      this.props.tabNavigation({
                                        slug: "dashboard"
                                      });
                                      this.setState({
                                        show_more: false
                                      });
                                    }}
                                  >
                                    Dashboard
                                  </SelectItem>
                                ) : null}

                                {!search ||
                                "Tasks"
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ? (
                                  <SelectItem
                                    select_type="none"
                                    icon="keyboard-arrow-right"
                                    selected={tab == "tasks"}
                                    onPress={() => {
                                      this.props.changeTab("tasks", tab);

                                      this.props.tabNavigation({
                                        slug: "tasks"
                                      });
                                      this.setState({
                                        show_more: false
                                      });
                                    }}
                                  >
                                    Tasks
                                  </SelectItem>
                                ) : null}

                                {user?.team_clearance_level > 0 &&
                                phone_plan &&
                                (!search ||
                                  "Dialer"
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ? (
                                  <SelectItem
                                    select_type="none"
                                    icon="keyboard-arrow-right"
                                    selected={tab == "dialer"}
                                    onPress={() => {
                                      this.props.changeTab("dialer", tab);

                                      this.props.tabNavigation({
                                        slug: "dialer"
                                      });
                                      this.setState({
                                        show_more: false
                                      });
                                    }}
                                  >
                                    Dialer
                                  </SelectItem>
                                ) : null}

                                {this.props.mailCenter ? (
                                  <>
                                    {user?.team_clearance_level > 0 &&
                                    (user?.team_clearance_level > 1 ||
                                      user?.can_edit_templates == 1) &&
                                    (!search ||
                                      "Mail Center"
                                        .toLowerCase()
                                        .includes(search.toLowerCase())) ? (
                                      <SelectItem
                                        select_type="none"
                                        icon="keyboard-arrow-right"
                                        selected={tab == "mail_center"}
                                        onPress={() => {
                                          this.props.changeTab(
                                            "mail_center",
                                            tab
                                          );
                                          this.props.tabNavigation({
                                            slug: "mail_center"
                                          });
                                          this.setState({
                                            show_more: false
                                          });
                                        }}
                                      >
                                        Mail Center
                                      </SelectItem>
                                    ) : null}

                                    {user?.team_clearance_level > 0 &&
                                    (user?.team_clearance_level > 1 ||
                                      user?.can_edit_templates == 1) &&
                                    (!search ||
                                      "Postcards"
                                        .toLowerCase()
                                        .includes(search.toLowerCase())) ? (
                                      <SelectItem
                                        select_type="none"
                                        icon="keyboard-arrow-right"
                                        selected={tab == "postcard_designer"}
                                        onPress={() => {
                                          this.props.changeTab(
                                            "postcard_designer",
                                            tab
                                          );

                                          this.props.tabNavigation({
                                            slug: "postcard_designer"
                                          });
                                        }}
                                      >
                                        Postcards
                                      </SelectItem>
                                    ) : null}

                                    {user?.team_clearance_level > 0 &&
                                    (user?.team_clearance_level > 1 ||
                                      user?.can_edit_templates == 1) &&
                                    (!search ||
                                      "Mail Sequences"
                                        .toLowerCase()
                                        .includes(search.toLowerCase())) ? (
                                      <UpsellButton
                                        meta_slug={"advanced_mail_sequences"}
                                        onPress={() => {
                                          this.props.changeTab(
                                            "mail_sequences",
                                            tab
                                          );

                                          this.props.tabNavigation({
                                            slug: "mail_sequences"
                                          });
                                        }}
                                        onLockPress={() => {}}
                                        renderLockedChildren={({
                                          hovering
                                        }) => {
                                          return (
                                            <>
                                              <SelectItem
                                                select_type="icon"
                                                select_icon="lock"
                                                icon="keyboard-arrow-right"
                                                selected={
                                                  tab == "mail_sequences"
                                                }
                                                noPress={true}
                                                pressedIn={hovering}
                                              >
                                                Mail Sequences
                                              </SelectItem>
                                            </>
                                          );
                                        }}
                                        renderChildren={({ hovering }) => {
                                          return (
                                            <>
                                              <SelectItem
                                                select_type="none"
                                                icon="keyboard-arrow-right"
                                                selected={
                                                  tab == "mail_sequences"
                                                }
                                                noPress={true}
                                                pressedIn={hovering}
                                              >
                                                Mail Sequences
                                              </SelectItem>
                                            </>
                                          );
                                        }}
                                      />
                                    ) : null}
                                  </>
                                ) : (
                                  <>
                                    {user?.team_clearance_level > 0 &&
                                    (!search ||
                                      "Mail"
                                        .toLowerCase()
                                        .includes(search.toLowerCase())) ? (
                                      <SelectItem
                                        select_type="none"
                                        icon="keyboard-arrow-right"
                                        selected={tab == "mail"}
                                        onPress={() => {
                                          this.props.changeTab("mail", tab);

                                          this.props.tabNavigation({
                                            slug: "mail"
                                          });
                                          this.setState({
                                            show_more: false
                                          });
                                        }}
                                      >
                                        Mail
                                      </SelectItem>
                                    ) : null}
                                  </>
                                )}

                                {!search ||
                                "Support"
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ? (
                                  <SelectItem
                                    select_type="none"
                                    icon="keyboard-arrow-right"
                                    selected={tab == "help"}
                                    onPress={() => {
                                      this.props.changeTab("help", tab);
                                      this.props.tabNavigation({
                                        slug: "help"
                                      });
                                      this.setState({
                                        show_more: false
                                      });
                                    }}
                                  >
                                    Help & Support
                                  </SelectItem>
                                ) : null}
                              </Scroll>
                            </>
                          );
                        }}
                      />
                    )}
                  </Row>
                </BottomNavBar>
              </Wrapper>
            ) : null}
          </>
        ) : null}

        {tab == "map" ? (
          <BottomSlider
            property={this.props.active_property}
            coordinates={this.props.active_coordinates}
            route={this.props.active_route}
            colors={this.props.colors}
            route_plan_active={this.props.route_plan_active}
            route_plan_legs={this.props.route_plan_legs}
            next_planned_property={this.props.next_planned_property}
            selectActiveProperty={this.props.selectActiveProperty}
            selectActiveCoordinates={this.props.selectActiveCoordinates}
            selectActiveRoute={this.props.selectActiveRoute}
            route_plan_completed={this.props.route_plan_completed}
            selected_active_properties={this.props.selected_active_properties}
            selectMultipleProperties={this.props.selectMultipleProperties}
          />
        ) : null}

        {this.props.device != "desktop" ? (
          <>
            <NavigationHandler {...this.props} />
            {this.props.lock_location_tracking || this.props.track_route ? (
              <BackgroundLocation {...this.props} />
            ) : null}
          </>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  route,
  drawer,
  feature_toggle,
  property_map,
  map,
  lead,
  billing
}) => {
  const { token, user, loading, init } = auth;
  const { device, isIphoneX, isMobile, platform, iosBrowser } = native;
  const { colors, dark_mode } = settings;
  const {
    track_route,
    active_route,
    route_plan_active,
    select_virtual_drive_start
  } = route;
  const { tab } = drawer;
  const { mailCenter, listBuilderMap } = feature_toggle;
  const {
    active_property,
    active_coordinates,
    selected_active_properties,
    next_planned_property,
    route_plan_completed,
    devicePermissions
  } = property_map;
  const { active_comp } = lead;
  const { lock_location_tracking } = map;
  const { source_of_truth, source_of_truth_loading } = billing;
  return {
    user,
    token,
    loading,
    source_of_truth_loading,
    init,
    device,
    isIphoneX,
    isMobile,
    platform,
    iosBrowser,
    colors,
    dark_mode,
    track_route,
    tab,
    mailCenter,
    active_property,
    active_coordinates,
    active_route,
    active_comp,
    route_plan_active,
    select_virtual_drive_start,
    selected_active_properties,
    next_planned_property,
    route_plan_completed,
    lock_location_tracking,
    devicePermissions,
    source_of_truth,
    listBuilderMap
  };
};

export default connect(mapStateToProps, {
  tabNavigation,
  appRedirect,
  setModal,
  changeTab,
  pushSidePanel,

  startTrackedRoute,
  selectActiveProperty,
  selectActiveCoordinates,
  selectActiveRoute,
  selectMultipleProperties,
  toggleModal,
  toggleDrivingMenu,
  showErrorMessage,
  getUserFromAppleAuth,
  registerUser,
  setOAuthInfo,
  loginUser
})(BottomTabBar);
