import React, { Component } from "react";

import {
  Wrapper,
  Copy, Row, Icon, Bold
} from "app/NativeComponents/common";
import { GhostButton, InlineButton } from "../../../native/GlobalSnippets";
class MappingRequirements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapping_check_array:[
        {
          title: "Full Address",
          slug: "full_address_check",
        },
        {
          title: "Lat/Long",
          slug: "lat_lng_check"
        },
        {
          title: "Property ID",
          slug: "property_id_check"
        },
        {
          title: "APN",
          slug: "apn_check"
        },
      ]
    };
  }
  render() {
    const anyFieldSelected = Object.values(this.props.fields_are_selected).some(Boolean);
    let colors = this.props.colors;

    return (
      <Wrapper
        style={{
          padding: 25,
          paddingTop: 5,
          paddingBottom: 5,
          margin: 5
        }}
      >
        <Row
          style={{
            paddingBottom: 15
          }}
        >
          <Icon
            style={{ marginRight: 15 }}
            color={colors.light_text_color}
            icon={"key"}
            size={22}
          />
          <Copy><Bold>Fields required to import properties</Bold></Copy>
          <InlineButton
            noPress={true}
            primary={false}
            tooltip={"DealMachine uses the values from your upload to find and add properties to your account. You must map one of the required fields to a column in your spreadsheet in order to import to DealMachine."}
            tooltipPlacement={"top"}
            icon={"help"}
            icon_color={colors.gray_selected_color}
            button_type={"small"}
            style={{ marginRight: 5, marginLeft: -5, margin: 0 }}
          >
          </InlineButton>
        </Row>
        <Row
          style={{
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            margin:0
          }}
        >
          <Icon
            style={{ marginRight: 10 }}
            icon={anyFieldSelected ? "check" : "warning"}
            color={anyFieldSelected ? colors.success_color : colors.error_color}
            size={22}
          />
          {this.state.mapping_check_array.map((item, i) => (
            <GhostButton
              key={i}
              style={{ margin: 0, marginRight: 5, marginBottom: 5 }}
              noPress={true}
              disabled={!this.props.fields_are_selected[item.slug]}
            >
              {item.title}
            </GhostButton>
          ))}
        </Row>
      </Wrapper>
    );
  }
}

export default MappingRequirements;
