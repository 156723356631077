import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";

import {
  Container,
  Spinner,
  Wrapper,
  InternalImage
} from "app/NativeComponents/common";
import BottomTabBar from "app/DealMachineCore/components/BottomTabBar";
import NavBar from "app/NativeComponents/components/NavBar";
import WorkspaceNavBar from "app/Workspaces/components/WorkspaceNavBar";

import { Page } from "app/NativeComponents/website/common";

import AppRoutes from "./AppRoutes";
import PausedAccount from "app/DealMachineCore/components/LockOutPages/PausedAccount";
import Canceled from "app/DealMachineCore/components/LockOutPages/Canceled";
import BadCard from "app/DealMachineCore/components/LockOutPages/BadCard";
import FixCardForMarketingCredits from "app/DealMachineCore/components/LockOutPages/FixCardForMarketingCredits";
import CreatePassword from "app/DealMachineCore/components/LockOutPages/CreatePassword";
import AcceptTerms from "app/DealMachineCore/components/LockOutPages/AcceptTerms";
import TeamRemoved from "app/DealMachineCore/components/LockOutPages/TeamRemoved";
import OnboardingTrainingVideos from "app/DealMachineCore/components/LockOutPages/OnboardingTrainingVideos";
import LoginContainer from "app/NativeComponents/components/LoginContainer";
import RedirectToApp from "app/NativeComponents/components/RedirectToApp";

import PreTrialOnboarding from "app/DealMachineCore/MarketingOnboardingAndFunnel/components/PreTrialOnboarding";
import SignUpContainer from "app/DealMachineCore/MarketingOnboardingAndFunnel/WebComponents/SignUpContainer";
import TrialSuccessOnboarding from "app/DealMachineCore/MarketingOnboardingAndFunnel/components/TrialSuccessOnboarding";
import NativeMobilePrompt from "app/DealMachineCore/MarketingOnboardingAndFunnel/WebComponents/NativeMobilePrompt";

import ValidateEmailStep from "app/DealMachineCore/MarketingOnboardingAndFunnel/components/PreTrialOnboarding/ValidateEmailStep";
import {
  initApp,
  getUser,
  getSourceOfTruth,
  getStats,
  appRedirect,
  setDarkMode,
  AppConfig,
  isMobilePhoneCheck
} from "app/NativeActions";
import MobileRedirectBanner from "./MobileRedirectBanner";
import Navbar from "./Navbar";
import ChurnBuster from "app/DealMachineCore/MarketingOnboardingAndFunnel/components/ChurnBuster";

class App extends Component {
  constructor() {
    super();

    this.state = {
      loaded_user: false
    };

    this.getName = this.getName.bind(this);
    this.renderContainer = this.renderContainer.bind(this);
  }

  /*check if the user is logged in*/
  detectUser() {
    const token = window.localStorage.getItem("token");
    if (token != null) {
      if (!this.props.user) {
        this.props.getUser({
          token,
          device: this.props.device,
          initialRedirect: this.props.initialRedirect,
          onError: () => {
            this.setState({ loaded_user: true });
          },
          onSuccess: () => {
            this.setState({ loaded_user: true });
          }
        });
        this.props.getSourceOfTruth({ token });
      }
    } else {
      if (!this.props.user) {
        this.setState({ loaded_user: true });
      }
    }
  }
  componentDidMount() {
    const token = window.localStorage.getItem("token");
    if (this.props.user && this.props.stats) {
      //onboarding
      this.props.appRedirect({
        redirect: "init",
        payload: {
          user: this.props.user,
          billing: this.props.stats?.billing,
          dealfinder_page: this.props.user_dealfinder_page,
          is_partner: this.props.is_partner,
          accepted_affiliate_latest_terms:
            this.props.accepted_affiliate_latest_terms,
          type: "load"
        }
      });
    } else {
      this.detectUser();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.user != prevProps.user &&
      !prevProps.user &&
      !this.props.source_of_truth_loading
    ) {
      const token = window.localStorage.getItem("token");
      this.props.getSourceOfTruth({ token: token ? token : this.props.token });
    }
  }

  renderContainer(children = () => {}) {
    const { user, desktopMobile } = this.props;
    const name = this.getName();
    return (
      <Page metaTitle={name + " - DealMachine for Real Estate"}>
        <div id={"onboarding-container"}>
          <Navbar />
          <div id={"onboarding-container-inner"}>
            <div style={{ maxWidth: 500, margin: "0px auto" }}>
              {children()}
            </div>
          </div>
        </div>
      </Page>
    );
  }

  getName() {
    const { user } = this.props;
    let name = "";
    if (!!user?.firstname) {
      name = user?.firstname + " " + user?.lastname;
    } else {
      name = user?.email;
    }

    return name;
  }

  render() {
    const {
      user,
      token,
      source_of_truth,
      source_of_truth_loading,
      is_partner
    } = this.props;

    if (token && user && source_of_truth && !source_of_truth_loading) {
      const name = this.getName();

      if (user?.finished_onboarding == 1 && source_of_truth) {
        if (
          !source_of_truth?.has_subscription ||
          source_of_truth?.subscription?.status == "canceled" ||
          user?.canceled == 1
        ) {
          return <Canceled />;
        } else if (
          source_of_truth?.subscription?.status == "past_due" ||
          source_of_truth?.subscription?.status == "unpaid" ||
          source_of_truth?.subscription?.status == "incomplete" ||
          source_of_truth?.subscription?.status == "incomplete_expired"
        ) {
          return (
            <>
              {this.renderContainer(() => {
                return <BadCard />;
              })}
            </>
          );
        } else if (source_of_truth?.subscription?.is_paused) {
          return <PausedAccount />;
        } else if (!user?.has_password) {
          return (
            <>
              {this.renderContainer(() => {
                return <CreatePassword />;
              })}
            </>
          );
        } else if (
          user?.marketing_credits_failed &&
          user?.pause_sending == 0 &&
          user?.team_clearance_level >= 2
        ) {
          return (
            <>
              {this.renderContainer(() => {
                return <FixCardForMarketingCredits {...this.props} />;
              })}
            </>
          );
        } else if (user?.accepted_latest_terms != 1) {
          return (
            <>
              {this.renderContainer(() => {
                return <AcceptTerms {...this.props} />;
              })}
            </>
          );
        } else if (user?.team_removed == 1 && user?.team_owner != 1) {
          return (
            <>
              {this.renderContainer(() => {
                return <TeamRemoved {...this.props} />;
              })}
            </>
          );
        } else if (
          user?.team_clearance_level == 0 &&
          user?.completed_team_training == 0 &&
          this.props.user_dealfinder_page?.require_training == 1
        ) {
          return (
            <>
              {this.renderContainer(() => {
                return <OnboardingTrainingVideos {...this.props} />;
              })}
            </>
          );
        }

        //validate phone and email if just a team member
        if (
          user?.team_owner != 1 ||
          user?.onboarding_stepthrough_completed == 1
        ) {
          if (user?.email_verified != 1) {
            return <ValidateEmailStep />;
          }
        }

        if (
          user?.team_owner == 1 &&
          user?.onboarding_stepthrough_completed != 1
        ) {
          return (
            <>
              {this.props.bypass_mobile_redirect && isMobilePhoneCheck() ? (
                <MobileRedirectBanner />
              ) : null}
              <TrialSuccessOnboarding />
            </>
          );
        }

        if (
          /*this.props.isMobile && */
          !this.props.bypass_mobile_redirect &&
          isMobilePhoneCheck()
        ) {
          return <NativeMobilePrompt />;
        }

        if (this.props.toggle_churn_buster) {
          return <ChurnBuster />;
        }

        return (
          <Page metaTitle={name + " - DealMachine for Real Estate"}>
            {isMobilePhoneCheck() ? <MobileRedirectBanner /> : null}

            <div
              className={
                !AppConfig().mobilePhoneBrowser
                  ? "vh-container-desktop"
                  : "vh-container"
              }
              style={
                this.props.desktopMobile
                  ? { overflow: "auto" }
                  : {
                      display: "flex",
                      flexDirection: "column",
                      height: "100vh",
                      minHeight: "100%"
                    }
              }
            >
              {!this.props.isMobile ? (
                <>
                  {this.props.user?.team_type === "workspace" ? (
                    <WorkspaceNavBar />
                  ) : (
                    <NavBar />
                  )}
                </>
              ) : null}
              <AppRoutes />
              {this.props.isMobile ? <BottomTabBar /> : null}
            </div>
          </Page>
        );
      } else if (source_of_truth) {
        return <PreTrialOnboarding />;
      }
    }

    if (!this.props.source_of_truth_loading && this.state.loaded_user) {
      return (
        <>
          <Route exact path="/" component={LoginContainer} />
          <Route exact path={"/login"} component={RedirectToApp} />
          <Route path="/sign-up" component={SignUpContainer} />

          <Route path="/a" component={RedirectToApp} />
          <Route path="/leads" component={RedirectToApp} />
          <Route path="/a/leads" component={RedirectToApp} />
          <Route path="/drive" component={RedirectToApp} />
          <Route path="/a/drive" component={RedirectToApp} />
          <Route path="/map" component={RedirectToApp} />
          <Route path="/mail-center" component={RedirectToApp} />
          <Route path={"/a/mail-center"} component={RedirectToApp} />
          <Route path={"/dashboard"} component={RedirectToApp} />
          <Route path={"/a/dashboard"} component={RedirectToApp} />
          <Route path={"/mail-center"} component={RedirectToApp} />
          <Route path="/a/mail-center" component={RedirectToApp} />
          <Route path={"/postcards"} component={RedirectToApp} />
          <Route path={"/a/postcards"} component={RedirectToApp} />
          <Route path={"/mail-sequences"} component={RedirectToApp} />
          <Route path={"/a/mail-sequences"} component={RedirectToApp} />
          <Route path={"/settings"} component={RedirectToApp} />
          <Route path={"/a/settings"} component={RedirectToApp} />

          <Route path={"/connect"} component={RedirectToApp} />
          <Route path={"/a/connect"} component={RedirectToApp} />

          <Route path={"/dialer"} component={RedirectToApp} />
          <Route path={"/a/dialer"} component={RedirectToApp} />
        </>
      );
    }

    return (
      <Page metaTitle={"DealMachine for Real Estate"}>
        <div className="dm-app-wrapper animated fadeIn deal-full-app">
          <Container>
            <Spinner />
          </Container>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  drawer,
  billing,
  team_link,
  feature_toggle,
  partner,
  marketing
}) => {
  const { token, user, init } = auth;
  const {
    device,
    isMobile,
    desktopMobile,
    initialRedirect,
    bypass_mobile_redirect
  } = native;
  const { stats } = drawer;
  const { is_partner, accepted_affiliate_latest_terms } = partner;
  const { source_of_truth, source_of_truth_loading } = billing;

  const { user_dealfinder_page } = team_link;

  const { toggle_churn_buster } = marketing;

  return {
    token,
    user,
    init,
    device,
    initialRedirect,
    desktopMobile,
    stats,
    source_of_truth,
    source_of_truth_loading,
    user_dealfinder_page,
    bypass_mobile_redirect,
    is_partner,
    accepted_affiliate_latest_terms,
    toggle_churn_buster
  };
};

export default withRouter(
  connect(mapStateToProps, {
    initApp,
    getUser,
    getSourceOfTruth,
    getStats,
    appRedirect,
    setDarkMode
  })(App)
);
