import React, { Component } from "react";
import { connect } from "react-redux";

import Navbar from "../MailerDesigner/Navbar";
import Sidebar from "./Sidebar";
import MainBody from "./MainBody";

import {
  setModal,
  toggleModal,
  updateMailerTemplate,
  getMailerDesignTemplates,
  popSidePanel
} from "app/NativeActions";
import { FocusModal } from "app/NativeComponents/snippets";
import Signature from "../MailerDesigner/Signature";

class LegacyMailerDesigner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      select_signature: false,
      template: {
        id: props.mailer?.id || "new_template",
        template_system_type: "legacy",
        template_type: props.mailer?.template_type,
        template_title: props.mailer?.template_title || "",

        section_a: props.mailer?.section_a,
        section_b: props.mailer?.section_b,
        section_c: props.mailer?.section_c,
        section_d: props.mailer?.section_d,

        primary_color: props.mailer?.primary_color,
        secondary_color: props.mailer?.secondary_color,

        include_custom_image: props.mailer?.include_custom_image,
        custom_image: props.mailer?.custom_image,

        html_template: props.mailer?.html_template,

        signature: props.mailer?.signature
          ? props.mailer?.signature
          : {
              id: "no_signature_id",
              title: "",
              sign_off: "Sincerely, ",
              address: props.user?.address || "",
              address2: props.user?.address2 || "",
              city: props.user?.address_city || "",
              state: props.user?.address_state || "",
              zip: props.user?.address_zip || "",
              name: props.user?.name || "",
              signature_other_name: props.user?.name || "",
              other_contact: "",
              signature_image: props.user?.team_image,
              disclaimer: "",
              signature_company: props.user?.company,
              phone: props.user?.team_phone,
              email: props.user?.team_email
            }
      },
      selected_layer: null,
      side_tab: "front",
      mobile_tab: "layers",
      saving: false
    };

    this.toggleSide = this.toggleSide.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
    this.saveMailerTemplate = this.saveMailerTemplate.bind(this);

    this.toggleSignatures = this.toggleSignatures.bind(this);
    this.toggleMobileTab = this.toggleMobileTab.bind(this);
    this.updateSignature = this.updateSignature.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
  }

  componentDidMount() {
    this.props.getMailerDesignTemplates({
      token: this.props.token,
      template_type: this.props.mailer?.template_type || "postcard"
    });
  }

  componentDidUpdate(prevProps) {
    const { mailer } = this.props;
    if (mailer && JSON.stringify(mailer) !== JSON.stringify(prevProps.mailer)) {
      this.setState({
        id: mailer?.id || "new_template",
        template_type: mailer?.template_type,
        template_title: mailer?.template_title || "",

        section_a: mailer?.section_a,
        section_b: mailer?.section_b,
        section_c: mailer?.section_c,
        section_d: mailer?.section_d,

        primary_color: mailer?.primary_color,
        secondary_color: mailer?.secondary_color,

        include_custom_image: mailer?.include_custom_image,
        custom_image: mailer?.custom_image,

        html_template: mailer?.html_template,

        signature: mailer?.signature
      });
    }
  }

  toggleSide(side_tab) {
    this.setState({ side_tab });
  }

  toggleMobileTab(mobile_tab) {
    this.setState({ mobile_tab });
  }

  toggleSignatures(select_signature) {
    this.setState({
      select_signature
    });
  }

  updateTemplate({ prop, value }) {
    const { template } = this.state;
    this.setState({
      template: {
        ...template,
        [prop]: value
      }
    });
  }

  updateSignature({ prop, value }) {
    this.setState({
      template: {
        ...this.state.template,
        signature: {
          ...this.state.template?.signature,
          [prop]: value
        }
      }
    });
  }

  saveMailerTemplate() {
    const { template } = this.state;
    this.props.updateMailerTemplate({
      token: this.props.token,
      type:
        template.id !== "new_template"
          ? "update_mailer_template"
          : "create_mailer_template",
      mailer_template_id: template.id,
      template_title: template.template_title,
      template_system_type: "legacy",
      template_type: template.template_type,
      section_a: template.section_a,
      section_b: template.section_b,
      section_c: template.section_c,
      section_d: template.section_d,
      signature_sign_off: template?.signature?.sign_off,
      return_address: template?.signature?.address,
      return_address2: template?.signature?.address2,
      return_address_city: template?.signature?.city,
      return_address_state: template?.signature?.state,
      return_address_zip: template?.signature?.zip,
      signature_name: template?.signature?.name,
      signature_phone: template?.signature?.phone,
      signature_email: template?.signature?.email,
      signature_id: "no_signature_id",

      html_template_id: template.html_template.id,

      primary_color: template.primary_color,
      secondary_color: template.secondary_color,
      custom_image: template.custom_image,
      include_custom_image: template.include_custom_image,
      onLoading: () => {
        this.setState({ saving: true });
      },
      onError: () => {
        this.setState({ saving: false });
      },
      onSuccess: results => {
        this.setState({ saving: false });
        if (this.props.onSuccess) {
          this.props.onSuccess(results);
        }
        this.props.popSidePanel();
      }
    });
  }

  checkIfNeedsToSave() {
    const { template } = this.state;
    const { mailer } = this.props;
    if (
      template.id == "new_template" ||
      template.template_title != mailer.template_title ||
      template.section_a != mailer.section_a ||
      template.section_b != mailer.section_b ||
      template.section_c != mailer.section_c ||
      template.section_d != mailer.section_d ||
      template.primary_color != mailer.primary_color ||
      template.secondary_color != mailer.secondary_color ||
      template.include_custom_image != mailer.include_custom_image ||
      template.custom_image != mailer.custom_image ||
      template.signature?.id != mailer.signature?.id ||
      JSON.stringify(template?.signature) != JSON.stringify(mailer?.signature)
    ) {
      return true;
    }

    return false;
  }

  onDeleteSuccess() {
    if (this.props.onDeleteSuccess) {
      this.props.onDeleteSuccess(this.props.mailer?.id);
    }
  }

  render() {
    const { isMobile, colors, mailer, device } = this.props;
    const {
      side_tab,
      template,
      card_width,
      card_height,
      select_signature,
      mobile_tab
    } = this.state;
    if (mailer) {
      return (
        <FocusModal
          title="Postcard Designer"
          onClose={() => {
            this.props.popSidePanel();
          }}
          show_side_bar={true}
          renderSideBar={() => {
            if (select_signature) {
              return (
                <Signature
                  signature={template?.signature}
                  toggleSignatures={this.toggleSignatures}
                  updateSignature={this.updateSignature}
                  legacyTemplate={true}
                />
              );
            }
            return (
              <>
                {isMobile ? (
                  <Navbar
                    colors={colors}
                    device={device}
                    platform={this.props.platform}
                    isIphoneX={this.props.isIphoneX}
                    mobile_tab={mobile_tab}
                    toggleMobileTab={this.toggleMobileTab}
                    template={template}
                    template_id={template?.id}
                    updateTemplate={this.updateTemplate}
                    checkIfNeedsToSave={this.checkIfNeedsToSave}
                    saveMailerTemplate={this.saveMailerTemplate}
                    toggleSignatures={this.toggleSignatures}
                    saving={this.state.saving}
                    onDeleteSuccess={this.onDeleteSuccess}
                    source_of_truth={this.props.source_of_truth}
                    {...this.props}
                  />
                ) : null}
                <Sidebar
                  {...this.props}
                  colors={colors}
                  isMobile={isMobile}
                  template={template}
                  side_tab={side_tab}
                  updateTemplate={this.updateTemplate}
                  template_type_info={this.props.template_type_info}
                  toggleSignatures={this.toggleSignatures}
                />
              </>
            );
          }}
          renderMainView={() => {
            return (
              <>
                <Navbar
                  colors={colors}
                  device={device}
                  platform={this.props.platform}
                  isIphoneX={this.props.isIphoneX}
                  mobile_tab={mobile_tab}
                  toggleMobileTab={this.toggleMobileTab}
                  template={template}
                  template_id={template?.id}
                  updateTemplate={this.updateTemplate}
                  checkIfNeedsToSave={this.checkIfNeedsToSave}
                  saveMailerTemplate={this.saveMailerTemplate}
                  toggleSignatures={this.toggleSignatures}
                  saving={this.state.saving}
                  onDeleteSuccess={this.onDeleteSuccess}
                  source_of_truth={this.props.source_of_truth}
                  {...this.props}
                />
                <MainBody
                  {...this.props}
                  isMobile={isMobile}
                  template={template}
                  side_tab={side_tab}
                  toggleSide={this.toggleSide}
                  front_width={mailer.front_width}
                  front_height={mailer.front_height}
                  back_width={mailer.back_width}
                  back_height={mailer.back_height}
                />
              </>
            );
          }}
        />
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, native, settings, mailer }) => {
  const { token, user } = auth;
  const { isMobile, platform, device, isIphoneX } = native;
  const { colors } = settings;
  const { template_type_info } = mailer;

  return {
    token,
    user,
    isMobile,
    platform,
    device,
    isIphoneX,
    colors,
    template_type_info
  };
};

export default connect(mapStateToProps, {
  setModal,
  toggleModal,

  updateMailerTemplate,
  getMailerDesignTemplates,
  popSidePanel
})(LegacyMailerDesigner);
